import React, { FC, useState, useEffect } from 'react';
import {
	IonMenu,
	IonToolbar,
	IonContent,
	IonList,
	IonItem,
	IonLabel,
	IonIcon,
	IonMenuToggle,
	IonAvatar,
	IonButton,
} from '@ionic/react';
import { person, settings, logOut, menuOutline, business, add } from 'ionicons/icons';
import GoBeWhite from '../../assets/GoBe-head.svg';
import { RouteComponentProps, withRouter } from 'react-router';
import { connect } from 'react-redux';
import { setParameter } from '../../actions/setParam';
import {
	SET_IS_EXPANDED,
	SET_SHOW_ABSOLUTE_MENU,
	SET_SHOW_MENU,
	SIGN_OUT_USER,
} from '../../actions/types';
import classes from './Menu.module.css';
import classNames from 'classnames';
import { injectIntl, FormattedMessage } from 'react-intl';
import InviteUserModal from '../InviteUser/InviteUserModal';
import { useTypedSelector } from '../../reducers';

interface Page {
	title: string;
	path: string;
	icon: string;
	active?: boolean;
	hidden: boolean;
	disable: boolean;
}

interface MenuItemProps {
	id?: string;
	title: string;
	active?: boolean;
	icon: string;
	onClick?: () => void;
	children?: React.ReactNode;
	isExpanded?: boolean;
	disable?: boolean;
}

const MenuItem: FC<MenuItemProps> = ({
	id,
	title,
	active,
	icon,
	onClick,
	children,
	isExpanded,
	disable,
}) => {
	return (
		<IonMenuToggle
			id={id}
			key={title}
			auto-hide="false"
			className={isExpanded ? classes.menuToggle : classes.menuToggleCollapse}
		>
			<div
				className={
					active
						? classNames(classes.menuItemContainer, classes.activeItem)
						: classes.menuItemContainer
				}
			>
				<div
					className={isExpanded ? classes.menuItemIdentifier : classes.noMenuIdentifier}
				/>
				<IonItem
					disabled={disable}
					button
					className={
						isExpanded && active
							? classNames(classes.menuItem, classes.activeItem)
							: !isExpanded && active
							? classNames(classes.menuItemCollapse, classes.activeItem)
							: !isExpanded
							? classes.menuItemCollapse
							: classes.menuItem
					}
					onClick={onClick}
				>
					<IonIcon
						slot="start"
						size="small"
						icon={icon}
						className={
							!isExpanded
								? classNames(classes.menuIcon, classes.menuIconCollapse)
								: classes.menuIcon
						}
					/>
					<IonLabel className={isExpanded ? classes.menuTitle : classes.menuTitleHidden}>
						{title}
					</IonLabel>
				</IonItem>
			</div>
		</IonMenuToggle>
	);
};

interface MenuProps extends RouteComponentProps {}

const Menu: FC<MenuProps> = (props: any) => {
	const [showInviteUser, setShowInviteUser] = useState(false);

	const {
		intl,
		location,
		history,
		users,
		client,
		showMenu,
		showAbsoluteMenu,
		isExpanded,
		setParameter,
		fullScreenStatus,
	} = props;
	const { inviteUserOpen } = users;
	const format = intl.formatMessage;

	const version = useTypedSelector(state => state.versionState.version);
	const spinoutType = useTypedSelector(state => state.versionState.spinoutType) as string;
	const userRoles = useTypedSelector(state => state.accountState.user.roles);
	const userFirstName = useTypedSelector(state => state.accountState.user.firstName);
	const userOrgs = useTypedSelector(state => state.accountState.user.organizations);
	const profilePictureLink = useTypedSelector(
		state => state.accountState.user.profilePictureLink
	);

	const [isOnOnboardingFlow, setIsOnOnboardingFlow] = useState<boolean>(false);

	const [pages, setPages] = useState<any>();

	useEffect(() => {
		const isUserAnAdmin = !!userRoles && !!userOrgs;
		setPages([
			...(spinoutType === 'gobe'
				? [
						{
							title: format({ id: 'Menu.gobeTitle' }),
							path: '/gobe',
							icon: GoBeWhite,
							hidden: false,
							disable: isOnOnboardingFlow,
							id: 'sidemenu-my-robots-button',
						},
				  ]
				: []),
			{
				title: format({
					id:
						spinoutType === 'beam'
							? 'Menu.OrganizationManager'
							: 'Menu.FleetManagement',
				}),
				path: '/fleetManagement',
				icon: business,
				hidden: isOnOnboardingFlow || (!isOnOnboardingFlow && !isUserAnAdmin),
				disable: false,
				id: 'sidemenu-fleet-management-button',
			},
			{
				title: format({ id: 'Menu.AccoutSetup' }),
				path: '/account-setup',
				icon: business,
				hidden: !isOnOnboardingFlow || (isOnOnboardingFlow && isUserAnAdmin),
				disable: false,
			},
			{
				title: format({ id: 'Menu.OrganizationSetup' }),
				path: '/organization-setup',
				icon: business,
				hidden: !isOnOnboardingFlow || (isOnOnboardingFlow && !isUserAnAdmin),
			},
			{
				title: format({ id: 'Menu.SettingsTitle' }),
				path: '/settings',
				icon: settings,
				hidden: isOnOnboardingFlow,
				disable: false,
			},
			{
				title: format({ id: 'Menu.LogoutTitle' }),
				path: '',
				icon: logOut,
				hidden: false,
				disable: false,
				id: 'sidemenu-logout-button',
			},
		]);
	}, [isOnOnboardingFlow, userRoles, userOrgs]);

	useEffect(() => {
		setIsOnOnboardingFlow(
			location.pathname.includes('/organization-setup') ||
				location.pathname.includes('/account-setup')
		);
	}, [location.pathname]);

	useEffect(() => {
		const { innerWidth: width } = window;
		if (width < 992) {
			setParameter('isExpanded', SET_IS_EXPANDED, false);
		}
	}, [userOrgs, userRoles]);

	useEffect(() => {
		if (inviteUserOpen) {
			setShowInviteUser(true);
		} else {
			setShowInviteUser(false);
		}
	}, [inviteUserOpen]);

	const navigateToPage = (page: Page) => {
		window.dispatchEvent(new Event('hashchange'));

		history.push(page.path);
	};

	const signOut = async () => {
		try {
			if (client.mqttClient && client.mqttClient.end) {
				await client.mqttClient.end(true);
			}
			window.localStorage.setItem('rememberMe', 'false');
			setParameter('', SIGN_OUT_USER, {});
			window.location.reload();
		} catch (err) {
			console.log(err);
			setParameter('', SIGN_OUT_USER, {});
		}
	};

	const shouldShowVersion = () => {
		const url = window.location.href.toLowerCase();
		if (process.env.NODE_ENV === 'production') {
			if (
				url.includes('nightly.blue') ||
				url.includes('staging.blue') ||
				url.includes('manufacturing.blue') ||
				!url.includes('blue-ocean-robotics-fleet')
			)
				return false;
			else return true;
		} else {
			return false;
		}
	};

	const goToSettings = () => {
		history.push('settings');
	};
	const openInviteUser = async () => {
		userRoles && props.setParameter('users', 'CHANGE_USER_INVITE_STATE', true);
	};

	return (
		<IonMenu
			side="start"
			menuId="mainMenu"
			contentId="main"
			className={
				showMenu && showAbsoluteMenu
					? classNames(classes.mainMenu, classes.contracted, classes.absolute)
					: showMenu
					? isExpanded
						? classes.mainMenu
						: classNames(classes.mainMenu, classes.contracted)
					: !showMenu && showAbsoluteMenu
					? isExpanded
						? classNames(classes.mainMenu, classes.absolute)
						: classNames(classes.mainMenu, classes.absolute, classes.contracted)
					: classes.hidden
			}
			onClick={() => window.dispatchEvent(new Event('menuEvent'))}
		>
			<InviteUserModal
				isOpen={showInviteUser}
				onDismiss={() => setParameter('users', 'CHANGE_USER_INVITE_STATE', false)}
			/>
			{/* <AccountManagementModal
				isOpen={showAccountInformation}
				onDismiss={() =>
					setParameter('showAccountInformation', SET_ACCOUNT_INFORMATION, false)
				}
			/> */}
			<div className={classes.menuBarHeader}>
				<IonToolbar
					className={
						!isExpanded
							? classNames(classes.toolBarCollapse, classes.toolBar)
							: classes.toolBar
					}
				>
					{isExpanded ? (
						<div className={classes.avatarContainer} onClick={goToSettings}>
							<IonAvatar className={classNames(classes.userAvatar)}>
								{profilePictureLink ? (
									<img
										className={classes.profilePic}
										src={profilePictureLink}
										alt="Avatar"
									/>
								) : (
									<IonIcon
										className={classes.headerIcon}
										size="large"
										icon={person}
									/>
								)}
							</IonAvatar>
							<IonLabel className={classes.userNameLb}>
								{userFirstName || <FormattedMessage id="Header.username" />}
							</IonLabel>
						</div>
					) : null}

					<IonIcon
						slot="end"
						size="large"
						icon={menuOutline}
						className={
							!isExpanded
								? classNames(classes.mainMenuIcon, classes.mainMenuIconCollapse)
								: classes.mainMenuIcon
						}
						onClick={() => {
							if (showAbsoluteMenu) {
								if (isExpanded) {
									if (fullScreenStatus) {
										setParameter('showMenu', SET_SHOW_MENU, false);
										setParameter(
											'showAbsoluteMenu',
											SET_SHOW_ABSOLUTE_MENU,
											false
										);
									} else {
										setParameter('isExpanded', SET_IS_EXPANDED, !isExpanded);
									}
								} else {
									setParameter('isExpanded', SET_IS_EXPANDED, !isExpanded);
								}
								return;
							}
							setParameter('isExpanded', SET_IS_EXPANDED, !isExpanded);
						}}
					/>
				</IonToolbar>
			</div>{' '}
			<IonContent>
				<IonList lines="none" className={classes.menuList}>
					{pages &&
						pages.map((page: any, index: any) =>
							!page.hidden ? (
								<MenuItem
									id={page.id}
									title={page.title}
									key={index}
									disable={page.disable === true}
									active={page.path === location.pathname}
									icon={page.icon}
									onClick={() =>
										page.title === format({ id: 'Menu.LogoutTitle' })
											? signOut()
											: navigateToPage(page)
									}
									isExpanded={isExpanded}
								/>
							) : (
								''
							)
						)}
					{spinoutType !== 'beam' && userRoles && (
						<IonButton
							className={
								isExpanded ? classes.inviteUserBtn : classes.inviteUserRounded
							}
							fill="outline"
							onClick={openInviteUser}
						>
							<IonIcon className={classes.addIcon} slot="icon-only" icon={add} />{' '}
							{isExpanded && format({ id: 'Menu.inviteUser' })}
						</IonButton>
					)}

					{shouldShowVersion() ? null : (
						<div className={classes.versionContainer}>
							<IonLabel className={classes.version}>v. {version}</IonLabel>
						</div>
					)}
				</IonList>
			</IonContent>
		</IonMenu>
	);
};

const mapStateToProps = (state: any) => ({
	organization: state.organizationState,
	selectedOrganization: state.selectedOrganizationState.organization,
	roles: state.rolesState,
	client: state.mqttState.client,
	users: state.usersState,
	showMenu: state.menuState.showMenu,
	showAbsoluteMenu: state.menuState.showAbsoluteMenu,
	isExpanded: state.menuState.isExpanded,
	fullScreenStatus: state.goBeState.sessionState.fullScreenStatus,
	showAccountInformation: state.menuState.showAccountInformation,
	activeRole: state.rolesState.activeRole,
});

export default injectIntl(withRouter(connect(mapStateToProps, { setParameter })(Menu)));
