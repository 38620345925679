import React from 'react';
import './index.scss';
import Checkbox from '../checkbox';
import { PropsFromParent } from './model';

const FailedInitPeerConnection: React.FC<PropsFromParent> = ({ againClick, backClick }) => {
	return (
		<div className="failedConnectionContainer">
			<div className="warningIconWrapper">
				<img alt="" src="../../assets/images/white-warning.svg" />
			</div>
			<div className="warningText">The Robot is unavailable, please try again later</div>
			<div className="backAgainButtonsContainer">
				<div className="circleTextContainer">
					<div className="greenCircle blackCircle" onClick={() => backClick()}>
						<div className="iconWrapper rotateNinety">
							<img src="../../assets/images/white-chevron.svg" alt="" />
						</div>
					</div>
					<div>Back</div>
				</div>
				<div className="circleTextContainer">
					<div className="whiteCircle blackCircle" onClick={() => againClick()}>
						<div className="iconWrapper">
							<img src="../../assets/images/refresh.svg" alt="" />
						</div>
					</div>
					<div>Try again</div>
				</div>
			</div>
		</div>
	);
};

export default FailedInitPeerConnection;
