import {
	SET_ROASTER_LARGE_ICONS,
	SET_ROASTER_SEARCH_NAME,
	SET_SORT_STATUS,
	SET_ROSTER_DIMENSIONS,
	SET_SORT_METHOD,
} from '../actions/types';

type RoasterStateModel = {
	largeIcons: boolean;
	searchName: string;
	sortStatus: boolean;
	agreementsStatus: boolean;
	goBesArray: [];
	dimensions: { width: number; height: number };
	sortMethod: string;
};

type RoasterActionsModel = {
	type: string;
	payload: RoasterStateModel;
};

const Initial_State = {
	largeIcons: true,
	searchName: '',
	sortStatus: false,
	dimensions: { width: 0, height: 0 },
	sortMethod: '',
};

export default (state = Initial_State, action: RoasterActionsModel) => {
	switch (action.type) {
		case SET_ROASTER_LARGE_ICONS:
			return { ...state, largeIcons: action.payload.largeIcons };
		case SET_ROASTER_SEARCH_NAME:
			return { ...state, searchName: action.payload.searchName };
		case SET_SORT_STATUS:
			return { ...state, sortStatus: action.payload.sortStatus };
		case SET_ROSTER_DIMENSIONS:
			return { ...state, dimensions: action.payload.dimensions };
		case SET_SORT_METHOD:
			return { ...state, sortMethod: action.payload.sortMethod };
		default:
			return state;
	}
};
