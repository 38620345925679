import { defineMessages } from 'react-intl';

export default defineMessages({
	details: {
		id: 'details',
		defaultMessage: 'Details',
		description: 'Details',
	},
	orgDetails: {
		id: 'Organization.details',
		defaultMessage: 'Organization Details',
		description: 'Organization details',
	},
	billingDetails: {
		id: 'Organization.billingDetails',
		defaultMessage: 'Billing Details',
		description: 'Billing details',
	},
	shippingDetails: {
		id: 'Organization.shippingDetails',
		defaultMessage: 'Shipping Details',
		description: 'Shipping details',
	},
	companyName: {
		id: 'Organization.companyName',
		defaultMessage: 'Company Name',
		description: 'Company Name',
	},
	createdOn: {
		id: 'Organization.createdOn',
		defaultMessage: 'Created On',
		description: 'Created On',
	},
	language: {
		id: 'Organization.language',
		defaultMessage: 'Language',
		description: 'Language',
	},
	street: {
		id: 'Address.dateAdded',
		defaultMessage: 'Street',
		description: 'Street',
	},
	street2: {
		id: 'Address.lastLogin',
		defaultMessage: 'Street2',
		description: 'Street2',
	},
	zip: {
		id: 'Address.zip',
		defaultMessage: 'Zip',
		description: 'Zip',
	},
	city: {
		id: 'Address.city',
		defaultMessage: 'City',
		description: 'City',
	},
	state: {
		id: 'Address.state',
		defaultMessage: 'State',
		description: 'State',
	},
	country: {
		id: 'Address.country',
		defaultMessage: 'Country',
		description: 'Country',
	},
	linkedTo: {
		id: 'Users.linkedTo',
		defaultMessage: 'Linked to',
		description: 'Linked to',
	},
	no: {
		id: 'no',
		defaultMessage: 'No',
		description: 'No',
	},
	yes: {
		id: 'yes',
		defaultMessage: 'Yes',
		description: 'Yes',
	},
	contactPerson: {
		id: 'Organizations.contact',
		defaultMessage: 'Contact Person',
		description: 'Contact Person',
	},
	firstName: {
		id: 'ContactPerson.firstName',
		defaultMessage: 'First Name',
		description: 'First Name',
	},
	lastName: {
		id: 'ContactPerson.lastName',
		defaultMessage: 'Last Name',
		description: 'Last Name',
	},
	jobTitle: {
		id: 'ContactPerson.jobTitle',
		defaultMessage: 'Job Title',
		description: 'Job Title',
	},
	email: {
		id: 'ContactPerson.email',
		defaultMessage: 'Email address',
		description: 'Email address',
	},
	phoneNumber: {
		id: 'ContactPerson.phoneNumber',
		defaultMessage: 'Phone Number',
		description: 'Phone Number',
	},
	agreements: {
		id: 'Organizations.agreement',
		defaultMessage: 'Agreements',
		description: 'Accepted Agreements',
	},
	acceptedOn: {
		id: 'Organizations.acceptedOn',
		defaultMessage: 'Accepted on:',
		description: 'Accepted on date',
	},
	version: {
		id: 'Organizations.version',
		defaultMessage: 'Version',
		description: 'Version',
	},
	acceptedDate: {
		id: 'Organizations.acceptedDate',
		defaultMessage: 'Accepted Date',
		description: 'Accepted Date',
	},
	acceptedTime: {
		id: 'Organizations.acceptedTime',
		defaultMessage: 'Accepted Time',
		description: 'Accepted Time',
	},
	acceptedBy: {
		id: 'Organizations.acceptedBy',
		defaultMessage: 'Accepted By',
		description: 'Accepted By',
	},

	ok: {
		id: 'Organization.ok',
		defaultMessage: 'OK',
		description: 'OK',
	},
});
