const chunkArray = (myArray: any, chunk_size: any) => {
	let index = 0;
	let arrayLength = myArray.length;
	let tempArray = [];

	for (index = 0; index < arrayLength; index += chunk_size) {
		let myChunk = myArray.slice(index, index + chunk_size);
		// Do something if you want with the group
		tempArray.push(myChunk);
	}

	return tempArray;
};

export default chunkArray;
