import React, { useRef, useEffect, useState, useCallback } from 'react';
import './index.scss';
import { connect } from 'react-redux';
import { setParameter } from '../../../actions/setParam';
import { SET_LOCAL_STREAM, SET_VOICE_HEIGHT, SET_INPUT_FOCUS_STATUS } from '../../../actions/types';
import WifiBattery from '../wifiBattery';
import { AppRootState } from '../../../../../../reducers';
import { ConnectedProps } from 'react-redux';
import { PropsFromParent } from './model';
import { settings } from 'cluster';

interface IWindow extends Window {
	webkitSpeechRecognition: any;
}

const reduxConnector = connect(
	(state: AppRootState) => ({
		localStream: state.goBeState.sessionState.localStream,
		// messageText: state.goBeState.sessionState.messageText,
		dataChannel1: state.goBeState.sessionState.dataChannel1,
		remoteStream: state.goBeState.sessionState.remoteStream,
		localVideoStatus: state.goBeState.sessionState.localVideoStatus,
		pauseSessionStatus: state.goBeState.sessionState.pauseSessionStatus,
		localVoiceVolume: state.goBeState.sessionState.localVoiceVolume,
		voiceHeight: state.goBeState.sessionState.voiceHeight,
		remoteVideoStatus: state.goBeState.sessionState.remoteVideoStatus,
		inputFocusStatus: state.goBeState.sessionState.inputFocusStatus,
		accountState: state.accountState,
		settings: state.hardwareState.settings,
	}),
	{ setParameter }
);

type PropsFromRedux = ConnectedProps<typeof reduxConnector>;
type ComponentProps = PropsFromRedux & PropsFromParent;

const LocalVideo: React.FC<ComponentProps> = ({
	localStream,
	// messageText,
	localVideoStatus,
	dataChannel1,
	setParameter,
	localVoiceVolume,
	remoteStream,
	pauseSessionStatus,
	startWideCameraStats,
	stopWideCameraStats,
	wideCameraStats,
	voiceHeight,
	remoteVideoStatus,
	accountState,
	inputFocusStatus,
	pauseMove,
	resumeMove,
	settings,
	greyVideo,
	pauseVideo,
}) => {
	const videoRef = useRef<any>(null);

	const localVideoRef = useRef<any>(null);

	// const [height, changeHeight] = useState(11);

	const [CAPTURE_OPTIONS, changeCaptureOptions] = useState<MediaStreamConstraints>({
		audio: {
			sampleSize: 16,
			sampleRate: 48000,
			channelCount: { min: 1, max: 2, ideal: 2 },
			echoCancellation: true,
			autoGainControl: false,
			noiseSuppression: false,
			deviceId: settings.microphone.id ? { exact: settings.microphone.id } : undefined,
		},
		// audio: true,
		video: {
			facingMode: 'user',
			width: 640,
			height: 480,
			frameRate: { max: 20 },
			deviceId: settings.camera.id ? { exact: settings.camera.id } : undefined,
		},
	});

	let audioContext: any;
	let javascriptNode: any;

	useEffect(() => {
		if (!localStream) return;

		audioContext = new AudioContext();
		let analyser = audioContext.createAnalyser();
		let microphone = audioContext.createMediaStreamSource(localStream);
		javascriptNode = audioContext.createScriptProcessor(2048, 1, 1);

		analyser.smoothingTimeConstant = 0.8;
		analyser.fftSize = 1024;

		microphone.connect(analyser);
		analyser.connect(javascriptNode);
		javascriptNode.connect(audioContext.destination);
		javascriptNode.onaudioprocess = function() {
			let array = new Uint8Array(analyser.frequencyBinCount);
			analyser.getByteFrequencyData(array);
			let values = 0;

			let length = array.length;
			for (let i = 0; i < length; i++) {
				values += array[i];
			}

			let average = values / length;

			if (average > 100) {
				average = 100;
			}
			// changeHeight((average / 100) * 12);
			setParameter('voiceHeight', SET_VOICE_HEIGHT, (average / 100) * 12);
		};
	}, [localStream]);

	const enableStream = useCallback(() => {
		const func = async () => {
			try {
				// const constraints = await navigator.mediaDevices.getSupportedConstraints();
				// console.log('CONSTRAINTS', constraints);
				await navigator.mediaDevices
					.getUserMedia(CAPTURE_OPTIONS)
					.then(function(stream) {
						setParameter('localStream', SET_LOCAL_STREAM, stream);
					})
					.catch(function(err) {
						/* handle the error */
						console.log(err);
					});
			} catch (err) {
				console.log(err);
			}
		};
		func()
			.then(() => {
				console.log('enableStream called');
			})
			.catch(err => console.error(err));
	}, [CAPTURE_OPTIONS, setParameter]);

	useEffect(enableStream, []);

	// Cleanup media tracks when component is un-mounting
	useEffect(() => {
		if (localStream)
			return function cleanup() {
				javascriptNode.onaudioprocess = () => {};
				javascriptNode.disconnect(audioContext.destination);
				audioContext.close().then(() => console.log('audio context suspended'));
				audioContext = null;
				localStream.getTracks().forEach((track: any) => {
					track.stop();
				});
				setParameter('localStream', SET_LOCAL_STREAM, null);
			};
	}, [audioContext, javascriptNode, localStream]);

	// useEffect(() => {
	// 	return () => {
	// 		heightRef.current = null;
	// 		if (!videoRef == null) {
	// 			videoRef.current.srcObject.getTracks().forEach((track: any) => {
	// 				track.stop();
	// 			});
	// 			// setParameter('localStream', SET_LOCAL_STREAM, null);
	// 		}
	// 	};
	// }, [videoRef]);

	useEffect(() => {
		if (localStream) {
			if (pauseSessionStatus) {
				localStream.getAudioTracks()[0].enabled = false;
				localStream.getVideoTracks()[0].enabled = false;
			} else {
				localStream.getAudioTracks()[0].enabled = true;
				localStream.getVideoTracks()[0].enabled = true;
			}
		}
	}, [localStream, pauseSessionStatus]);

	const onInputChange = (value: any) => {
		if (dataChannel1 && dataChannel1.readyState === 'open') {
			dataChannel1.send(`MSG ${value}`);
		}
	};

	if (localStream && videoRef.current && !videoRef.current.srcObject) {
		videoRef.current.srcObject = localStream;
	}

	const handleCanPlay = () => {
		videoRef.current.play();
	};

	useEffect(() => {
		if (pauseVideo === true) {
			videoRef.current.pause();
		} else {
			videoRef.current.play();
		}
	}, [pauseVideo]);

	const renderVideoLoading = () => {
		return (
			<div className={localVideoStatus ? '' : 'displayNone'}>
				<div className={remoteVideoStatus ? 'displayNone' : 'showLocalLoading '}>
					<div className="localLoading" />
					<video
						ref={videoRef}
						onCanPlay={handleCanPlay}
						autoPlay
						playsInline
						loop
						muted
						className="displayNone"
					/>
				</div>
				<video
					ref={videoRef}
					onCanPlay={handleCanPlay}
					autoPlay
					playsInline
					loop
					muted
					className={
						remoteVideoStatus
							? greyVideo
								? 'localVideo greyVideo'
								: 'localVideo'
							: 'displayNone'
					}
					id="localVideo"
				/>
				<div className={remoteVideoStatus ? 'whiteLogoWrapper' : 'displayNone'}>
					<img src="../../assets/images/white-gobe-logo.svg" alt="" />
				</div>
				<div className={remoteVideoStatus ? 'audioRecognizeContainer' : 'displayNone'}>
					<div className="audioStrength" style={{ height: `${4 + voiceHeight / 2}px` }} />
					<div className="audioStrength" style={{ height: `${4 + voiceHeight}px` }} />
					<div className="audioStrength" style={{ height: `${4 + voiceHeight / 2}px` }} />
				</div>
			</div>
		);
	};

	return (
		<div ref={localVideoRef}>
			<div
				className={
					localVideoStatus ? ' localContainer' : 'miniLocalContainer localContainer'
				}
			>
				<div>
					<WifiBattery
						startWideCameraStats={startWideCameraStats}
						stopWideCameraStats={stopWideCameraStats}
						wideCameraStats={wideCameraStats}
					/>
				</div>
				{renderVideoLoading()}
			</div>
			<div className={localVideoStatus ? 'localInputContainer' : 'displayNone'}>
				<input
					placeholder="Enter a status here"
					onChange={event => onInputChange(event.target.value)}
					id="localInputContainer"
					onFocus={() => {
						pauseMove();
						setParameter('inputFocusStatus', SET_INPUT_FOCUS_STATUS, true);
					}}
					onBlur={() => {
						resumeMove();
						setParameter('inputFocusStatus', SET_INPUT_FOCUS_STATUS, false);
					}}
				/>
			</div>
		</div>
	);
};

export default reduxConnector(LocalVideo);
