import React, { useEffect, useRef, useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import LocalVideo from './localVideo';
import RemoteVideo from './remoteVideo';
import './index.scss';
import { connect } from 'react-redux';
import { setParameter } from '../../actions/setParam';
import {
	SET_DATA_CHANNEL,
	SET_REMOTE_STREAM,
	SET_NAV_STREAM,
	SET_PAUSE_SESSION_STATUS,
	SET_END_SESSION_STATUS,
	SET_SHARE_SCREEN_STATUS,
	SET_FULL_SCREEN_STATUS,
	SET_INITIAL,
} from '../../actions/types';
import {
	SET_IS_EXPANDED,
	SET_SHOW_ABSOLUTE_MENU,
	SET_SHOW_MENU,
} from '../../../../../actions/types';
import SessionOptions from './sessionOptions';
import NavigationVideo from './navigationVideo';
import SessionEndPause from './sessionEndPause';
import ShareScreen from './shareScreen';
import { PropsFromParent } from './model';
import { ConnectedProps } from 'react-redux';
import { AppRootState } from '../../../../../reducers';
import { openFullscreen, closeFullScreen } from '../../utils/fullScreen';
import { useIonViewDidEnter } from '@ionic/react';
import FailedConnection from './failedConnection';
import useCallerPeerConnection, { ConnectionState } from './useCallerPeerConnection';
import useNavController, { NavKey } from './useKeyConverter';
import SessionRestart from './sessionRestart';
import { publish } from 'redux-mqtt';

const bannedKeys = [
	'F1',
	'F2',
	'F3',
	'F4',
	'F5',
	'F6',
	'F7',
	'F8',
	'F9',
	'F10',
	'F11',
	'F12',
	'Meta',
	'Control',
	'Alt',
];

const reduxConnector = connect(
	(state: AppRootState) => ({
		localStream: state.goBeState.sessionState.localStream,
		remoteStream: state.goBeState.sessionState.remoteStream,
		endSessionStatus: state.goBeState.sessionState.endSessionStatus,
		pauseSessionStatus: state.goBeState.sessionState.pauseSessionStatus,
		shareScreenStatus: state.goBeState.sessionState.shareScreenStatus,
		fullScreenStatus: state.goBeState.sessionState.fullScreenStatus,
		dataChannel1: state.goBeState.sessionState.dataChannel1 as RTCDataChannel | undefined,
		robotId: state.goBeState.sessionState.robotId,
		navSpeed: state.goBeState.sessionState.navSpeed,
		accountState: state.accountState,
		showMenu: state.menuState.showMenu,
		showAbsoluteMenu: state.menuState.showAbsoluteMenu,
		isExpanded: state.menuState.isExpanded,
		showAccountInformation: state.menuState.showAccountInformation,
		inputFocusStatus: state.goBeState.sessionState.inputFocusStatus,
	}),
	{ setParameter, publish }
);

type PropsFromRedux = ConnectedProps<typeof reduxConnector>;
type ComponentProps = PropsFromRedux & PropsFromParent;

type RemoteTrackKey = 'wideCamVideo' | 'navCamVideo' | 'audio';
type LocalTrackKey = 'video' | 'audio' | 'dummy';

const SessionLegacy: React.FC<PropsFromRedux> = ({
	dataChannel1,
	localStream,
	remoteStream,
	endSessionStatus,
	pauseSessionStatus,
	shareScreenStatus,
	fullScreenStatus,
	robotId,
	navSpeed,
	setParameter,
	accountState,
	showMenu,
	showAbsoluteMenu,
	isExpanded,
	showAccountInformation,
	inputFocusStatus,
	publish,
}) => {
	const history = useHistory();
	const sessionRef = useRef<any>(null);
	const remoteStreamRef = useRef<any>(null);

	// const [
	// 	keys = { ArrowUp: 0, w: 0, ArrowDown: 0, s: 0, ArrowRight: 0, d: 0, ArrowLeft: 0, a: 0 },
	// 	changeKeys,
	// ] = useState<any>();

	const [reload, changeReload] = useState(false);
	const [pauseSessionTimer, changePauseSessionTimer] = useState<any>(null);

	const navController = useNavController();
	useEffect(() => {
		navController.setNavSpeed(parseInt(navSpeed));
	}, [navController, navSpeed]);

	const onDataChannelReady = useCallback(
		(dataChannel: RTCDataChannel) => {
			if (dataChannel.label === 'nav-datachannel') {
				navController.setDataChannel(dataChannel);
			} else {
				setParameter('dataChannel1', SET_DATA_CHANNEL, dataChannel);
			}
		},
		[navController, setParameter]
	);

	// 0(pin):"dev-prince"
	// 1(pin):"4a1e9aba-e13f-11ea-9234-0123456789ab"

	const getLocalTracks = useCallback(async (): Promise<
		Record<LocalTrackKey, MediaStreamTrack | 'audio' | 'video'>
	> => {
		return navigator.mediaDevices
			.getUserMedia({
				audio: true,
				video: { facingMode: 'user', width: 640, height: 480 },
			})
			.then(stream => {
				return {
					video: stream.getVideoTracks()[0] as MediaStreamTrack,
					audio: stream.getAudioTracks()[0] as MediaStreamTrack,
					dummy: 'video',
				};
			});
	}, []);

	const [wideCamStream] = useState(new MediaStream());
	const [navCamStream] = useState(new MediaStream());
	useEffect(() => {
		setParameter('remoteStream', SET_REMOTE_STREAM, wideCamStream);
		setParameter('navStream', SET_NAV_STREAM, navCamStream);
	}, [navCamStream, setParameter, wideCamStream]);

	const onRemoteStream = useCallback(
		(track: MediaStreamTrack, key: RemoteTrackKey, transceiver: RTCRtpTransceiver) => {
			if (key === 'audio')
				// the audio is added to the wideCam stream
				wideCamStream.addTrack(track);
			else if (key === 'wideCamVideo') {
				wideCamStream.addTrack(track);
				navController.setTrackedRTCRtpReceiver('wideCam', transceiver.receiver);
			} else {
				navCamStream.addTrack(track);
				navController.setTrackedRTCRtpReceiver('navCam', transceiver.receiver);
			}
		},
		[navCamStream, navController, wideCamStream]
	);

	const { connectionState, iceRestarting, signallingUUID } = useCallerPeerConnection(
		{
			avatar: accountState.user.profilePictureLink,
			name: `${accountState.user.firstName} ${accountState.user.lastName}`,
		},
		robotId,
		onRemoteStream,
		onDataChannelReady,
		getLocalTracks
	);

	const sessionEndRedClick = useCallback(async () => {
		await localStream.getTracks().forEach((track: MediaStreamTrack) => {
			track.stop();
		});
		if (dataChannel1 && dataChannel1.readyState === 'open') {
			try {
				dataChannel1.send('SESSION STOP');
			} catch (error) {
				console.log(error);
			}
		}
		// send the STOP command over mqtt as well, in case the datachannel is closed before sending
		publish(`${robotId}/${signallingUUID}/endSession`, '');
		// setParameter('localStream', SET_LOCAL_STREAM, null);
		setParameter('navStream', SET_NAV_STREAM, null);
		setParameter('remoteStream', SET_REMOTE_STREAM, null);
		setParameter('endSessionStatus', SET_END_SESSION_STATUS, !endSessionStatus);

		history.replace('/gobe');
	}, [
		dataChannel1,
		endSessionStatus,
		history,
		localStream,
		publish,
		robotId,
		setParameter,
		signallingUUID,
	]);

	// end the session when the peer connection goes into one of these states
	useEffect(() => {
		const failedStates: Array<ConnectionState> = ['cameraAccessError', 'closed', 'failed'];
		if (failedStates.includes(connectionState)) {
			sessionEndRedClick();
		}
	}, [connectionState, sessionEndRedClick]);

	const optionsNavigationRef = useRef<any>(null);

	const optionsNavigationClickOutside = (event: any) => {
		if (optionsNavigationRef.current && !optionsNavigationRef.current.contains(event.target)) {
			setParameter('endSessionStatus', SET_END_SESSION_STATUS, true);
		}
	};

	useEffect(() => {
		let link = document.getElementById('jsd-widget');
		if (link && (link as any).style.display !== 'none') {
			(link as any).style.display = 'none';
		}
	}, []);

	useEffect(() => {
		document.addEventListener('click', optionsNavigationClickOutside, true);
		return () => {
			document.removeEventListener('click', optionsNavigationClickOutside, true);
		};
	});

	useIonViewDidEnter(() => {
		window.addEventListener('hashchange', () => {
			if (window.location.pathname !== '/gobe/session') {
				closeFullScreen();
			}
		});
	});

	useEffect(() => {
		sessionRef.current.focus();
	}, []);

	useEffect(() => {
		setParameter('showMenu', SET_SHOW_MENU, false);
		setParameter('showAbsoluteMenu', SET_IS_EXPANDED, false);
	}, [setParameter]);

	useEffect(() => {
		return () => {
			setParameter('showMenu', SET_SHOW_MENU, true);
			setParameter('showAbsoluteMenu', SET_SHOW_ABSOLUTE_MENU, false);
			setParameter('isExpanded', SET_IS_EXPANDED, true);
		};
	}, [setParameter]);

	useEffect(() => {
		return () => {
			closeFullScreen();
		};
	}, []);

	useEffect(() => {
		remoteStreamRef.current = remoteStream;
	}, [remoteStream]);

	useEffect(() => {
		let sessionReloadTimeout = setTimeout(() => {
			if (!remoteStreamRef.current) {
				changeReload(true);
			}
		}, 10000);
		return () => {
			clearTimeout(sessionReloadTimeout);
		};
	}, [reload]);

	useEffect(() => {
		return () => {
			clearTimeout(pauseSessionTimer);
		};
	}, [pauseSessionTimer]);

	const sessionPauseClick = () => {
		if (remoteStream && dataChannel1 && dataChannel1.readyState === 'open') {
			try {
				dataChannel1.send('SESSION PAUSE');
				navController.pause();
				changePauseSessionTimer(
					setTimeout(() => {
						sessionEndRedClick();
					}, 900000)
				);
				setParameter('pauseSessionStatus', SET_PAUSE_SESSION_STATUS, !pauseSessionStatus);
			} catch (error) {
				console.warn('Unable to send SESSION PAUSE command to robot', error);
			}
		}
	};
	const sessionPlayClick = () => {
		try {
			dataChannel1?.send('SESSION UNPAUSE');
			navController.resume();
		} catch (error) {
			console.warn('Unable to send SESSION UNPAUSE command to robot', error);
		}
		setParameter('endSessionStatus', SET_END_SESSION_STATUS, !endSessionStatus);
		setParameter('pauseSessionStatus', SET_PAUSE_SESSION_STATUS, !pauseSessionStatus);
	};
	const sessionCancelEndClick = () => {
		setParameter('endSessionStatus', SET_END_SESSION_STATUS, !endSessionStatus);
	};

	const sessionFailedAgainClick = () => {
		changeReload(false);
	};
	const sessionFailedBackClick = async () => {
		// localStream.getTracks().forEach((track: any) => {
		// 	track.stop();
		// });
		if (dataChannel1 && dataChannel1.readyState === 'open') {
			try {
				await dataChannel1.send('SESSION STOP');
			} catch (error) {
				console.log(error);
			}
		}
		// setParameter('localStream', SET_LOCAL_STREAM, null);
		setParameter('navStream', SET_NAV_STREAM, null);
		changeReload(false);
		history.replace('/gobe');
	};

	const stopShareClick = () => {
		setParameter('shareScreenStatus', SET_SHARE_SCREEN_STATUS, !shareScreenStatus);
	};

	const onFullScreenClick = () => {
		// setParameter('fullScreenStatus', SET_FULL_SCREEN_STATUS, !fullScreenStatus);
		if (fullScreenStatus) {
			closeFullScreen();
		} else {
			openFullscreen();
		}
		window.dispatchEvent(new Event('openFullScreen'));
	};

	// useEffect(() => {
	// 	if (dataChannel1) {
	// 		if (dataChannel1.readyState === 'open') {
	// 			try {
	// 				dataChannel1.send(`NAV ${keySubtractor(keys).x} ${keySubtractor(keys).y}`);
	// 			} catch (error) {
	// 				console.warn('Error sending NAV command to robot', error);
	// 			}
	// 		}
	// 	}
	// }, [keys, dataChannel1, navSpeed]);

	const renderShareScreen = () => {
		if (shareScreenStatus) {
			return (
				<div className={shareScreenStatus ? '' : 'displayNone'}>
					<ShareScreen stopShareClick={stopShareClick} />
				</div>
			);
		} else {
			return null;
		}
	};

	const onMuteVideoClick = () => {
		localStream.getVideoTracks()[0].enabled = false;
	};

	const onStartVideoClick = () => {
		localStream.getVideoTracks()[0].enabled = true;
	};

	useEffect(() => {
		return () => {
			setParameter('endSessionStatus', SET_INITIAL);
		};
	}, [setParameter]);

	document.onfullscreenchange = evt => {
		navController.pause();
		navController.resume();
		setParameter('fullScreenStatus', SET_FULL_SCREEN_STATUS, !fullScreenStatus);
	};

	useEffect(() => {
		if (fullScreenStatus) {
			setParameter('showMenu', SET_SHOW_MENU, false);
			setParameter('showAbsoluteMenu', SET_SHOW_ABSOLUTE_MENU, false);
		} else {
			setParameter('showMenu', SET_SHOW_MENU, false);
			setParameter('showAbsoluteMenu', SET_SHOW_ABSOLUTE_MENU, true);
		}
	}, [fullScreenStatus, setParameter]);

	// useEffect(() => {
	// 	if (showMenu || showAbsoluteMenu) {
	// 		navController.pause();
	// 	} else {
	// 		navController.resume();
	// 	}
	// }, [navController, showAbsoluteMenu, showMenu]);

	useEffect(() => {
		openFullscreen();
		return () => {
			closeFullScreen();
		};
	}, []);

	useEffect(() => {
		if (endSessionStatus) {
			navController.pause();
		} else {
			navController.resume();
		}
	}, [endSessionStatus, navController]);

	useEffect(() => {
		if (showAccountInformation) {
			navController.pause();
		} else {
			navController.resume();
		}
	}, [navController, showAccountInformation]);

	document.addEventListener('visibilitychange', function() {
		if (document.hidden) {
			navController.pause();
		} else {
			navController.resume();
		}
	});

	window.onbeforeunload = () => {
		navController.pause();
	};

	useEffect(() => {
		document.addEventListener('mouseenter', (event: any) => {
			navController.resume();
		});
	}, [navController]);

	// useEffect(() => {
	// 	document.addEventListener('mouseover', (event: any) => {
	// 		navController.resume();
	// 	});
	// }, [navController]);

	useEffect(() => {
		document.addEventListener('mouseleave', (event: any) => {
			navController.pause();
		});
	}, [navController]);

	useEffect(() => {
		window.addEventListener('menuEvent', (event: any) => {
			navController.pause();
		});
	}, [navController]);

	return (
		<div
			className="Session"
			id="Session"
			onFocus={() => {
				navController.resume();
			}}
			// onBlur={() => {
			// 	navController.pause();
			// }}
			onContextMenu={() => {
				navController.pause();
			}}
			onClick={() => {
				navController.resume();
			}}
			onKeyDown={event => {
				if (
					remoteStream &&
					dataChannel1 &&
					dataChannel1.readyState === 'open' &&
					endSessionStatus === false &&
					pauseSessionStatus === false &&
					inputFocusStatus === false
				) {
					if (bannedKeys.includes(event.key)) {
						navController.pause();
					} else {
						navController.onKeyEvent('keydown', event.key as NavKey);
					}
				}
			}}
			onKeyUp={event => {
				if (inputFocusStatus === false) {
					if (bannedKeys.includes(event.key)) {
						navController.resume();
					} else {
						navController.onKeyEvent('keyup', event.key as NavKey);
					}
				}
			}}
			tabIndex={0}
			ref={sessionRef}
		>
			<div className={endSessionStatus ? '' : 'displayNone'}>
				<SessionEndPause
					endSessionStatus={endSessionStatus}
					pauseSessionStatus={pauseSessionStatus}
					playClick={sessionPlayClick}
					endClick={sessionEndRedClick}
					cancelClick={sessionCancelEndClick}
					pauseClick={sessionPauseClick}
				/>
			</div>

			<div className={reload ? '' : 'displayNone'}>
				<FailedConnection
					backClick={sessionFailedBackClick}
					againClick={sessionFailedAgainClick}
				/>
			</div>
			{renderShareScreen()}
			<LocalVideo
				startWideCameraStats={() => console.log('Start Camera Stats')}
				stopWideCameraStats={() => console.log('Stop Camera Stats')}
				wideCameraStats=""
				pauseMove={() => navController.pause()}
				resumeMove={() => navController.resume()}
			/>

			<div
				className={
					fullScreenStatus ? 'showMenuSession' : 'showMenuSessionOut showMenuSession'
				}
				onClick={() => {
					setParameter('showMenu', SET_SHOW_MENU, false);
					setParameter('showAbsoluteMenu', SET_SHOW_ABSOLUTE_MENU, true);
					window.dispatchEvent(new Event('openMenu'));
				}}
			>
				<span>&#9776;</span>
			</div>
			<div
				className={
					!fullScreenStatus
						? isExpanded
							? 'showFullScreenSession showFullScreenSessionExpanded'
							: 'showFullScreenSession'
						: 'showFullScreenSession showFullScreenSessionOut'
				}
				onClick={() => onFullScreenClick()}
			>
				<div className="showFullScreenWrapper">
					<img alt="" src="../assets/images/black-full-screen.svg" />
				</div>
			</div>
			<RemoteVideo />
			<div>
				<SessionOptions />
				<NavigationVideo />
			</div>
		</div>
	);
};

export default React.memo(reduxConnector(SessionLegacy));
