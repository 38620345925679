import React, { useEffect, useRef, useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import LocalVideo from './localVideo';
import RemoteVideo from './remoteVideo';
import './index.scss';
import { connect } from 'react-redux';
import { setParameter } from '../../actions/setParam';
import {
	SET_DATA_CHANNEL,
	SET_REMOTE_STREAM,
	SET_NAV_STREAM,
	SET_PAUSE_SESSION_STATUS,
	SET_END_SESSION_STATUS,
	SET_SHARE_SCREEN_STATUS,
	SET_FULL_SCREEN_STATUS,
	SET_INITIAL,
	SET_RELOAD_STATUS,
	SET_FAILED_CONNECTION_STATUS,
} from '../../actions/types';
import {
	SET_IS_EXPANDED,
	SET_SHOW_ABSOLUTE_MENU,
	SET_SHOW_MENU,
} from '../../../../../actions/types';
import SessionOptions from './sessionOptions';
import NavigationVideo from './navigationVideo';
import SessionEndPause from './sessionEndPause';
import ShareScreen from './shareScreen';
import { PropsFromParent } from './model';
import { ConnectedProps } from 'react-redux';
import { AppRootState } from '../../../../../reducers';
import { openFullscreen, closeFullScreen } from '../../utils/fullScreen';
import { useIonViewDidEnter } from '@ionic/react';
import FailedInitPeerConnection from './failedInitPeerConnection';
import FailedSessionPeerConnection from './failedSessionPeerConnection';
import useCallerPeerConnection, { ConnectionState } from './useCallerPeerConnection';
import useNavController, { NavKey } from './useKeyConverter';
import { publish } from 'redux-mqtt';
import { RemoteTrackKey } from './useCallerPeerConnection/peerConnection';
import Docking from './docking';
import FailedSessionConnection from './failedSessionConnection';
import { IClientOptions, MqttClient } from 'mqtt';

const bannedKeys = [
	'F1',
	'F2',
	'F3',
	'F4',
	'F5',
	'F6',
	'F7',
	'F8',
	'F9',
	'F10',
	'F11',
	'F12',
	'Meta',
	'Control',
	'Alt',
	'NumLock',
	'Fn',
];

const reduxConnector = connect(
	(state: AppRootState) => ({
		localStream: state.goBeState.sessionState.localStream,
		remoteStream: state.goBeState.sessionState.remoteStream,
		endSessionStatus: state.goBeState.sessionState.endSessionStatus,
		pauseSessionStatus: state.goBeState.sessionState.pauseSessionStatus,
		shareScreenStatus: state.goBeState.sessionState.shareScreenStatus,
		fullScreenStatus: state.goBeState.sessionState.fullScreenStatus,
		reloadStatus: state.goBeState.sessionState.reloadStatus,
		failedConnectStatus: state.goBeState.sessionState.failedConnectStatus,
		dataChannel1: state.goBeState.sessionState.dataChannel1 as RTCDataChannel | undefined,
		robotId: state.goBeState.sessionState.robotId,
		robotName: state.goBeState.sessionState.robotName,
		navSpeed: state.goBeState.sessionState.navSpeed,
		accountState: state.accountState,
		showMenu: state.menuState.showMenu,
		showAbsoluteMenu: state.menuState.showAbsoluteMenu,
		isExpanded: state.menuState.isExpanded,
		showAccountInformation: state.menuState.showAccountInformation,
		inputFocusStatus: state.goBeState.sessionState.inputFocusStatus,
		mqttConfig: state.mqttState.mqttConfig as Partial<IClientOptions>,
		localVoiceVolume: state.goBeState.sessionState.localVoiceVolume,
		remoteVideoStatus: state.goBeState.sessionState.remoteVideoStatus,
		showLoader: state.fetchDataState.showLoader,
	}),
	{ setParameter, publish }
);

type PropsFromRedux = ConnectedProps<typeof reduxConnector>;
type ComponentProps = PropsFromRedux & PropsFromParent;
type LocalTrackKey = 'video' | 'audio' | 'dummy';

const NO_PILOT_VIDEO_TIMEOUT = 60 * 1000;
const Session: React.FC<PropsFromRedux> = ({
	dataChannel1,
	localStream,
	remoteStream,
	endSessionStatus,
	pauseSessionStatus,
	shareScreenStatus,
	fullScreenStatus,
	reloadStatus,
	failedConnectStatus,
	robotId,
	robotName,
	navSpeed,
	setParameter,
	accountState,
	showMenu,
	showAbsoluteMenu,
	isExpanded,
	showAccountInformation,
	inputFocusStatus,
	publish,
	mqttConfig,
	localVoiceVolume,
	remoteVideoStatus,
	showLoader,
}) => {
	const history = useHistory();
	const sessionRef = useRef<any>(null);
	const initialLocalVolume = useRef(Number.parseFloat(localVoiceVolume));
	const [framesTimeCounter, setFramesTimeCounter] = useState(0);
	const [failedPeerTimer, setFailedPeerTimer] = useState(0);

	const [pauseSessionTimer, changePauseSessionTimer] = useState<any>(null);
	const [failedPeerStatus, setFailedPeerStatus] = useState(false);

	const navController = useNavController();
	useEffect(() => {
		navController.setNavSpeed(parseInt(navSpeed));
	}, [navController, navSpeed]);

	const [dataChannel2, setDataChannel2] = useState<RTCDataChannel | undefined>();
	const [parkStatus, setParkStatus] = useState('');
	const [autoPark, changeAutoPark] = useState(false);
	const autoParkRef = useRef<any>(true);

	const onDataChannelReady = useCallback(
		(dataChannel: RTCDataChannel) => {
			if (dataChannel.label === 'nav-datachannel') {
				(window as any).datachannel = dataChannel;
				navController.setDataChannel(dataChannel);
				setDataChannel2(dataChannel);
			} else {
				console.log('OTHER-DATACHANNEL created');
				setParameter('dataChannel1', SET_DATA_CHANNEL, dataChannel);
				dataChannel.send(`VOL ${localVoiceVolume}`);
			}
		},
		[navController, setParameter]
	);

	const [wideCamStream] = useState(new MediaStream());
	const [navCamStream] = useState(new MediaStream());
	useEffect(() => {
		setParameter('remoteStream', SET_REMOTE_STREAM, wideCamStream);
		setParameter('navStream', SET_NAV_STREAM, navCamStream);
	}, [navCamStream, setParameter, wideCamStream]);

	const onRemoteStream = useCallback(
		(track: MediaStreamTrack, key: RemoteTrackKey, transceiver: RTCRtpTransceiver) => {
			if (key === 'robotAudio')
				// the audio is added to the wideCam stream
				wideCamStream.addTrack(track);
			else if (key === 'wideCamVideo') {
				wideCamStream.addTrack(track);
				navController.setTrackedRTCRtpReceiver('wideCam', transceiver.receiver);
			} else {
				navCamStream.addTrack(track);
				navController.setTrackedRTCRtpReceiver('navCam', transceiver.receiver);
			}
		},
		[navCamStream, navController, wideCamStream]
	);

	const onPeerConnectionStart = useCallback(() => {
		// TODO: Add some logic for this...
		console.debug('session.Component PeerConnection started');
	}, []);

	// setup the controls for the peer connection
	const {
		connectionState,
		endPeerConnection,
		startPeerConnection,
		pausePeerConnection,
		unpausePeerConnection,
		promptIceRestart,
	} = useCallerPeerConnection(
		mqttConfig,
		{
			avatar: accountState.user.profilePictureLink,
			name: `${accountState.user.firstName} ${accountState.user.lastName}`,
			id: accountState.user.username,
			username: accountState.user.username,
			password: accountState.user.password,
		},
		robotId,
		onRemoteStream,
		onDataChannelReady,
		onPeerConnectionStart
	);
	// Now start the peer connection
	useEffect(() => {
		if (!localStream) {
			console.debug(`Cannot start peerConnection yet. localStream is ${localStream}`);
			return;
		}

		// TODO: Verify that this is never called more than once, due to re-renders
		startPeerConnection(localStream, initialLocalVolume.current);
	}, [startPeerConnection, localStream]);

	const sessionEndRedClick = useCallback(async () => {
		await localStream.getTracks().forEach((track: MediaStreamTrack) => {
			track.stop();
		});
		endPeerConnection();
		// setParameter('localStream', SET_LOCAL_STREAM, null);
		setParameter('navStream', SET_NAV_STREAM, null);
		setParameter('remoteStream', SET_REMOTE_STREAM, null);
		setParameter('endSessionStatus', SET_END_SESSION_STATUS, false);

		history.replace('/gobe');
	}, [endPeerConnection, history, localStream, setParameter]);

	// end the session when the peer connection goes into one of these statesppppppppppppppppppppppppppppppppppppppppp
	useEffect(() => {
		// FIXME: This will make the hangup be called twice.
		// Especially, if the hangUp was originally initiated by the remote peer.
		// Check the logic and remove, accordingly
		if (connectionState === 'closed' && reloadStatus === false) {
			setFailedPeerStatus(true);
		}
	}, [connectionState, reloadStatus, sessionEndRedClick]);

	const optionsNavigationRef = useRef<any>(null);

	const optionsNavigationClickOutside = (event: any) => {
		if (optionsNavigationRef.current && !optionsNavigationRef.current.contains(event.target)) {
			setParameter('endSessionStatus', SET_END_SESSION_STATUS, true);
		}
	};

	const handleParking = useCallback((event: any) => {
		const msg = event.data.toString().split("'")[1];
		console.log('ParkingStatusMessage', msg);
		setParkStatus(msg);
	}, []);

	useEffect(() => {
		console.log('State Park', parkStatus);
	}, [parkStatus]);

	useEffect(() => {
		let framesInterval = setInterval(() => {
			if (navController.navFrames.current === 0 || navController.wideFrames.current === 0) {
				setFramesTimeCounter(framesTimeCounter + 1);
			} else {
				setFramesTimeCounter(0);
			}
		}, 1000);
		return () => clearInterval(framesInterval);
	}, [framesTimeCounter, navController.navFrames, navController.wideFrames]);

	useEffect(() => {
		if (framesTimeCounter > 5) {
			navController.pause();
		} else {
			navController.resume();
		}
	}, [framesTimeCounter, navController]);

	useEffect(() => {
		let failedPeerInterval = setInterval(() => {
			if (
				(navController.navFrames.current === 0 && connectionState === 'failed') ||
				(navController.wideFrames.current === 0 && connectionState === 'failed')
			) {
				setFailedPeerTimer(failedPeerTimer + 1);
			} else {
				if (failedPeerTimer >= 2) {
					setFailedPeerTimer(2);
				}
				setFailedPeerTimer(0);
			}
		}, 1000);
		if (failedPeerTimer > 10 && reloadStatus === false) {
			setFailedPeerStatus(true);
		}
		return () => clearInterval(failedPeerInterval);
	}, [
		connectionState,
		failedPeerTimer,
		navController.navFrames,
		navController.wideFrames,
		reloadStatus,
	]);

	useEffect(() => {
		let link = document.getElementById('jsd-widget');
		if (link && (link as any).style.display !== 'none') {
			(link as any).style.display = 'none';
		}
	}, []);

	useEffect(() => {
		document.addEventListener('click', optionsNavigationClickOutside, true);
		return () => {
			document.removeEventListener('click', optionsNavigationClickOutside, true);
		};
	});

	useIonViewDidEnter(() => {
		window.addEventListener('hashchange', () => {
			if (window.location.pathname !== '/gobe/session') {
				closeFullScreen();
			}
		});
	});

	useEffect(() => {
		sessionRef.current.focus();
	}, []);

	useEffect(() => {
		setParameter('showMenu', SET_SHOW_MENU, false);
		setParameter('showAbsoluteMenu', SET_IS_EXPANDED, false);
	}, [setParameter]);

	useEffect(() => {
		return () => {
			setParameter('showMenu', SET_SHOW_MENU, true);
			setParameter('showAbsoluteMenu', SET_SHOW_ABSOLUTE_MENU, false);
			setParameter('isExpanded', SET_IS_EXPANDED, true);
		};
	}, [setParameter]);

	useEffect(() => {
		return () => {
			closeFullScreen();
		};
	}, []);

	useEffect(() => {
		let sessionReloadTimeout = setTimeout(() => {
			if (reloadStatus === false && remoteVideoStatus === false) {
				setParameter('reloadStatus', SET_RELOAD_STATUS, true);
			}
		}, NO_PILOT_VIDEO_TIMEOUT);
		return () => {
			clearTimeout(sessionReloadTimeout);
		};
	}, [reloadStatus, remoteVideoStatus, setParameter]);

	useEffect(() => {
		return () => {
			clearTimeout(pauseSessionTimer);
			// FIXME: Move clearTimeout off autoParkRef to its own useEffect
			// NB: UseEffect cannot run based on changed to React.RefObject,
			// 	which has referential stability/immutability
			clearTimeout(autoParkRef.current);
		};
	}, [pauseSessionTimer]);

	const sessionPauseClick = () => {
		if (remoteStream) {
			try {
				pausePeerConnection();
				navController.pause();
				changePauseSessionTimer(
					setTimeout(() => {
						sessionEndRedClick();
					}, 900000)
				);
				setParameter('pauseSessionStatus', SET_PAUSE_SESSION_STATUS, !pauseSessionStatus);
			} catch (error) {
				console.warn('session.Pause Unable to send SESSION PAUSE command to robot', error);
			}
		} else {
			console.log('session.Pause IGNORED');
		}
	};
	const sessionPlayClick = () => {
		changePauseSessionTimer(null);
		try {
			unpausePeerConnection();
			navController.resume();
		} catch (error) {
			console.warn('Unable to send SESSION UNPAUSE command to robot', error);
		}
		setParameter('endSessionStatus', SET_END_SESSION_STATUS, !endSessionStatus);
		setParameter('pauseSessionStatus', SET_PAUSE_SESSION_STATUS, !pauseSessionStatus);
	};
	const sessionCancelEndClick = () => {
		navController.resume();
		setParameter('endSessionStatus', SET_END_SESSION_STATUS, !endSessionStatus);
	};

	const sessionFailedAgainClick = () => {
		setParameter('reloadStatus', SET_RELOAD_STATUS, false);
	};

	const sessionFailedBackClick = async () => {
		// localStream.getTracks().forEach((track: any) => {
		// 	track.stop();
		// });
		if (dataChannel1 && dataChannel1.readyState === 'open') {
			try {
				await dataChannel1.send('SESSION STOP');
			} catch (error) {
				console.log(error);
			}
		}
		// setParameter('localStream', SET_LOCAL_STREAM, null);
		setParameter('navStream', SET_NAV_STREAM, null);
		setParameter('reloadStatus', SET_RELOAD_STATUS, false);
		history.replace('/gobe');
	};

	const stopShareClick = () => {
		setParameter('shareScreenStatus', SET_SHARE_SCREEN_STATUS, !shareScreenStatus);
	};

	const onFullScreenClick = () => {
		// setParameter('fullScreenStatus', SET_FULL_SCREEN_STATUS, !fullScreenStatus);
		if (fullScreenStatus) {
			closeFullScreen();
		} else {
			openFullscreen();
		}
		window.dispatchEvent(new Event('openFullScreen'));
	};

	const renderShareScreen = () => {
		if (shareScreenStatus) {
			return (
				<div className={shareScreenStatus ? '' : 'displayNone'}>
					<ShareScreen stopShareClick={stopShareClick} />
				</div>
			);
		} else {
			return null;
		}
	};

	useEffect(() => {
		return () => {
			setParameter('endSessionStatus', SET_INITIAL);
		};
	}, [setParameter]);

	useEffect(() => {
		const fullScreenChangeHandler = () => {
			navController.pause();
			navController.resume();
			setParameter('fullScreenStatus', SET_FULL_SCREEN_STATUS, !fullScreenStatus);
		};
		document.addEventListener('fullscreenchange', fullScreenChangeHandler);
		return () => {
			document.removeEventListener('fullscreenchange', fullScreenChangeHandler);
		};
	}, [fullScreenStatus, navController, setParameter]);

	useEffect(() => {
		if (fullScreenStatus) {
			setParameter('showMenu', SET_SHOW_MENU, false);
			setParameter('showAbsoluteMenu', SET_SHOW_ABSOLUTE_MENU, false);
		} else {
			setParameter('showMenu', SET_SHOW_MENU, false);
			setParameter('showAbsoluteMenu', SET_SHOW_ABSOLUTE_MENU, true);
		}
	}, [fullScreenStatus, setParameter]);

	useEffect(() => {
		openFullscreen();
		return () => {
			closeFullScreen();
		};
	}, []);

	useEffect(() => {
		if (endSessionStatus) {
			navController.pause();
		} else {
			navController.resume();
		}
	}, [endSessionStatus, navController]);

	useEffect(() => {
		if (showAccountInformation) {
			navController.pause();
		} else {
			navController.resume();
		}
	}, [navController, showAccountInformation]);

	useEffect(() => {
		const visibilityChangeHandler = () => {
			if (showAccountInformation) {
				navController.pause();
			} else {
				navController.resume();
			}
		};
		document.addEventListener('visibilitychange', visibilityChangeHandler);
		return () => {
			document.removeEventListener('visibilitychange', visibilityChangeHandler);
		};
	}, [navController, showAccountInformation]);

	useEffect(() => {
		const beforeUnloadHandler = () => {
			navController.pause();
		};
		window.addEventListener('beforeunload', beforeUnloadHandler);
		return () => {
			window.removeEventListener('beforeunload', beforeUnloadHandler);
		};
	}, [navController]);

	useEffect(() => {
		const mouseEnterHandler = (event: any) => {
			navController.resume();
		};
		document.addEventListener('mouseenter', mouseEnterHandler);
		return () => {
			document.removeEventListener('mouseenter', mouseEnterHandler);
		};
	}, [navController]);

	useEffect(() => {
		const mouseLeaveHandler = (event: any) => {
			navController.pause();
		};
		document.addEventListener('mouseleave', mouseLeaveHandler);
		return () => {
			document.removeEventListener('mouseleave', mouseLeaveHandler);
		};
	}, [navController]);

	useEffect(() => {
		const menuEventHandler = () => {
			navController.pause();
		};
		window.addEventListener('menuEvent', menuEventHandler);
		return () => {
			window.removeEventListener('menuEvent', menuEventHandler);
		};
	}, [navController]);

	useEffect(() => {
		if (showLoader) {
			navController.pause();
		} else {
			navController.resume();
		}
	}, [showLoader, navController]);

	return (
		<div
			className="Session"
			id="Session"
			onFocus={() => {
				navController.resume();
			}}
			onContextMenu={() => {
				navController.pause();
			}}
			onClick={() => {
				navController.resume();
			}}
			onKeyDown={event => {
				if (
					remoteStream &&
					dataChannel1 &&
					dataChannel1.readyState === 'open' &&
					endSessionStatus === false &&
					pauseSessionStatus === false &&
					inputFocusStatus === false &&
					showLoader === false
				) {
					if (bannedKeys.includes(event.key)) {
						navController.pause();
					} else {
						navController.onKeyEvent('keydown', event.key as NavKey);
						if (event.key === 'p') {
							clearTimeout(autoParkRef.current);
							if (autoPark === false) {
								dataChannel2?.addEventListener('message', handleParking);
								changeAutoPark(true);
							}
						}
					}
				}
			}}
			onKeyUp={event => {
				if (inputFocusStatus === false) {
					if (bannedKeys.includes(event.key)) {
						navController.resume();
					} else {
						navController.onKeyEvent('keyup', event.key as NavKey);
						if (event.key === 'p') {
							autoParkRef.current = setTimeout(() => {
								changeAutoPark(false);
								dataChannel2?.removeEventListener('message', handleParking);
								setParkStatus('');
							}, 3000);
						}
					}
				}
			}}
			tabIndex={0}
			ref={sessionRef}
		>
			<div className={endSessionStatus ? '' : 'displayNone'}>
				<SessionEndPause
					endSessionStatus={endSessionStatus}
					pauseSessionStatus={pauseSessionStatus}
					playClick={sessionPlayClick}
					endClick={sessionEndRedClick}
					cancelClick={sessionCancelEndClick}
					pauseClick={sessionPauseClick}
				/>
			</div>

			<div className={autoPark ? '' : 'displayNone'}>
				<Docking parkStatus={parkStatus} />
			</div>

			<div className={reloadStatus ? '' : 'displayNone'}>
				<FailedInitPeerConnection
					backClick={sessionFailedBackClick}
					againClick={sessionFailedAgainClick}
				/>
			</div>
			<div className={failedPeerStatus ? '' : 'displayNone'}>
				<FailedSessionPeerConnection
					backClick={sessionEndRedClick}
					againClick={() => setFailedPeerStatus(false)}
					robotName={robotName}
				/>
			</div>
			{renderShareScreen()}
			<LocalVideo
				startWideCameraStats={() => console.log('Start Camera Stats')}
				stopWideCameraStats={() => console.log('Stop Camera Stats')}
				wideCameraStats=""
				pauseMove={() => navController.pause()}
				resumeMove={() => navController.resume()}
				greyVideo={false}
				pauseVideo={false}
			/>

			<div
				className={
					fullScreenStatus ? 'showMenuSession' : 'showMenuSessionOut showMenuSession'
				}
				onClick={() => {
					setParameter('showMenu', SET_SHOW_MENU, false);
					setParameter('showAbsoluteMenu', SET_SHOW_ABSOLUTE_MENU, true);
					window.dispatchEvent(new Event('openMenu'));
				}}
			>
				<span>&#9776;</span>
			</div>
			<div
				className={
					!fullScreenStatus
						? isExpanded
							? 'showFullScreenSession showFullScreenSessionExpanded'
							: 'showFullScreenSession'
						: 'showFullScreenSession showFullScreenSessionOut'
				}
				onClick={() => onFullScreenClick()}
			>
				<div className="showFullScreenWrapper">
					<img alt="" src="../assets/images/black-full-screen.svg" />
				</div>
			</div>
			<RemoteVideo
				robotId={robotId}
				greyVideo={framesTimeCounter > 5}
				pauseVideo={framesTimeCounter > 5}
			/>
			<div
				className={
					showAbsoluteMenu
						? isExpanded
							? 'robotNameContainer robotNameContainerMenuExpand'
							: 'robotNameContainer robotNameContainerMenu'
						: 'robotNameContainer'
				}
			>
				<div className="robotHeadWrapper">
					<img alt="" src="../assets/images/robot-head.svg" />
				</div>
				<span>{robotName}</span>
			</div>
			<FailedSessionConnection
				isExpanded={isExpanded}
				showAbsoluteMenu={showAbsoluteMenu}
				minimized={failedConnectStatus}
				setMinimized={(payload: boolean) =>
					setParameter('failedConnectStatus', SET_FAILED_CONNECTION_STATUS, payload)
				}
				showStatus={framesTimeCounter > 1 && framesTimeCounter <= 5}
			/>
			<div>
				<SessionOptions />
				<NavigationVideo
					greyVideo={framesTimeCounter > 5}
					pauseVideo={framesTimeCounter > 5}
				/>
			</div>
		</div>
	);
};

export default React.memo(reduxConnector(Session));
