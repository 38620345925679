import React, { useEffect, useRef, useState } from 'react';
import './index.scss';
import Slider from '../slider';
import SessionMoreOptions from '../sessionMoreOptions';
import { connect } from 'react-redux';
import { setParameter } from '../../../actions/setParam';
import {
	SET_REMOTE_VOICE_VOLUME,
	SET_LOCAL_VOICE_VOLUME,
	SET_REMOTE_VIDEO_ZOOM,
	SET_END_SESSION_STATUS,
	SET_PAUSE_SESSION_STATUS,
	SET_LOCAL_VOICE_STATUS,
	SET_REMOTE_VOICE_STATUS,
	SET_REMOTE_VIDEO_ZOOM_STATUS,
	SET_MORE_OPTIONS_STATUS,
	SET_DRIVING_MODE_STATUS,
	SET_NAV_VIDEO_STATUS,
	SET_SHARE_SCREEN_STATUS,
	SET_FULL_SCREEN_STATUS,
	SET_HIDE_NAV_OPTIONS_STATUS,
} from '../../../actions/types';
import { ConnectedProps } from 'react-redux';
import { AppRootState } from '../../../../../../reducers';
import { openFullscreen, closeFullScreen } from '../../../utils/fullScreen';
import { PropsFromParent } from './model';

const reduxConnector = connect(
	(state: AppRootState) => ({
		dataChannel1: state.goBeState.sessionState.dataChannel1,
		localVoiceVolume: state.goBeState.sessionState.localVoiceVolume,
		remoteVoiceVolume: state.goBeState.sessionState.remoteVoiceVolume,
		remoteVideoZoom: state.goBeState.sessionState.remoteVideoZoom,
		endSessionStatus: state.goBeState.sessionState.endSessionStatus,
		pauseSessionStatus: state.goBeState.sessionState.pauseSessionStatus,
		localVoiceStatus: state.goBeState.sessionState.localVoiceStatus,
		remoteVoiceStatus: state.goBeState.sessionState.remoteVoiceStatus,
		remoteVideoZoomStatus: state.goBeState.sessionState.remoteVideoZoomStatus,
		moreOptionsStatus: state.goBeState.sessionState.moreOptionsStatus,
		navVideoStatus: state.goBeState.sessionState.navVideoStatus,
		shareScreenStatus: state.goBeState.sessionState.shareScreenStatus,
		fullScreenStatus: state.goBeState.sessionState.fullScreenStatus,
		hideNavOptionsStatus: state.goBeState.sessionState.hideNavOptionsStatus,
		localStream: state.goBeState.sessionState.localStream,
		remoteStream: state.goBeState.sessionState.remoteStream,
		drivingMode: state.goBeState.sessionState.drivingMode,
		microphoneLevel: state.hardwareState.settings.microphone.microphoneLevel,
	}),
	{ setParameter }
);

type PropsFromRedux = ConnectedProps<typeof reduxConnector>;
type ComponentProps = PropsFromRedux & PropsFromParent;

const SessionOptions: React.FC<PropsFromRedux> = ({
	setParameter,
	dataChannel1,
	localVoiceVolume,
	remoteVoiceVolume,
	remoteVideoZoom,
	endSessionStatus,
	pauseSessionStatus,
	localVoiceStatus,
	remoteVoiceStatus,
	remoteVideoZoomStatus,
	moreOptionsStatus,
	drivingMode,
	navVideoStatus,
	shareScreenStatus,
	fullScreenStatus,
	hideNavOptionsStatus,
	localStream,
	remoteStream,
	microphoneLevel,
}) => {
	const localVoiceRef = useRef<any>(null);
	const remoteVoiceRef = useRef<any>(null);
	const remoteVideoZoomRef = useRef<any>(null);
	const sessionMoreOptionsRef = useRef<any>(null);

	const localVoiceStatusRef = useRef<any>(null);
	const remoteVoiceStatusRef = useRef<any>(null);
	const remoteVideoZoomStatusRef = useRef<any>(null);
	const sessionMoreOptionsStatusRef = useRef<any>(null);
	const drivingModeStatusRef = useRef<any>(null);


	const [localVoiceValue, changeLocalVoiceValue] = useState<any>('19');
	const [videoZoomValue, changeVideoZoomValue] = useState<any>('19');

	const onLocalVoiceChange = (value: number) => {
		setParameter('localVoiceVolume', SET_LOCAL_VOICE_VOLUME, value);
		changeLocalVoiceValue(value);
		if (dataChannel1 && dataChannel1.readyState === 'open') {
			dataChannel1.send(`VOL ${value}`);
		}
	};

	useEffect(() => {
		if (microphoneLevel) {
			setParameter('localVoiceVolume', SET_LOCAL_VOICE_VOLUME, microphoneLevel);
			changeLocalVoiceValue(microphoneLevel);
		}
	}, [microphoneLevel, setParameter]);

	const onLocalVoiceIconClick = () => {
		if (remoteStream && dataChannel1 && dataChannel1.readyState === 'open') {
			if (localVoiceVolume === '0') {
				if (localVoiceValue === '0') {
					if (dataChannel1 && dataChannel1.readyState === 'open') {
						dataChannel1.send(`VOL 19`);
					}
					setParameter('localVoiceVolume', SET_LOCAL_VOICE_VOLUME, '19');
				} else {
					if (dataChannel1 && dataChannel1.readyState === 'open') {
						dataChannel1.send(`VOL ${localVoiceValue}`);
					}
					setParameter('localVoiceVolume', SET_LOCAL_VOICE_VOLUME, localVoiceValue);
				}
			} else {
				if (dataChannel1 && dataChannel1.readyState === 'open') {
					dataChannel1.send(`VOL 0`);
				}
				setParameter('localVoiceVolume', SET_LOCAL_VOICE_VOLUME, '0');
			}
		}
	};
	const onRemoteVideoZoomIconClick = () => {
		if (remoteVideoZoom === '0') {
			setParameter('remoteVideoZoom', SET_REMOTE_VIDEO_ZOOM, videoZoomValue);
		} else {
			setParameter('remoteVideoZoom', SET_REMOTE_VIDEO_ZOOM, '0');
		}
	};
	const onRemoteVideoZoomChange = (value: number) => {
		setParameter('remoteVideoZoom', SET_REMOTE_VIDEO_ZOOM, value);
		changeVideoZoomValue(value);
	};
	const onHangUpClick = () => {
		setParameter('endSessionStatus', SET_END_SESSION_STATUS, !endSessionStatus);
	};
	const onHideNavClick = () => {
		setParameter('hideNavOptionsStatus', SET_HIDE_NAV_OPTIONS_STATUS, !hideNavOptionsStatus);
	};
	const onShareScreenClick = () => {
		setParameter('shareScreenStatus', SET_SHARE_SCREEN_STATUS, !shareScreenStatus);
	};
	const onFullScreenClick = () => {
		// setParameter('fullScreenStatus', SET_FULL_SCREEN_STATUS, !fullScreenStatus);
		if (fullScreenStatus) {
			closeFullScreen();
		} else {
			openFullscreen();
		}
		if (fullScreenStatus) {
			window.dispatchEvent(new Event('closeFullScreen'));
		} else {
			window.dispatchEvent(new Event('openFullScreen'));
		}
	};

	useEffect(() => {
		if (localStream) {
			if (parseInt(localVoiceVolume) === 0) {
				localStream.getAudioTracks()[0].enabled = false;
			} else {
				localStream.getAudioTracks()[0].enabled = true;
			}
		}
	}, [localStream, localVoiceVolume]);

	return (
		<div
			className={
				hideNavOptionsStatus
					? navVideoStatus
						? 'sessionOptionsContainer'
						: 'sessionOptionsContainer sessionOptionsHideNav'
					: 'displayNone'
			}
		>
			<div
				className={moreOptionsStatus ? 'blackCircle blackCircleOpacity' : 'blackCircle'}
				ref={sessionMoreOptionsStatusRef}
				onMouseEnter={() =>
					setParameter('moreOptionsStatus', SET_MORE_OPTIONS_STATUS, true)
				}
				onMouseLeave={() =>
					setParameter('moreOptionsStatus', SET_MORE_OPTIONS_STATUS, false)
				}
			>
				<div className="iconWrapper">
					<img src="../../assets/images/more.svg" alt="" />
				</div>
				<div
					className={moreOptionsStatus ? 'backGroundProvider' : 'displayNone'}
					onMouseEnter={() =>
						setParameter('moreOptionsStatus', SET_MORE_OPTIONS_STATUS, true)
					}
					onMouseLeave={() =>
						setParameter('moreOptionsStatus', SET_MORE_OPTIONS_STATUS, false)
					}
				/>
			</div>
			{/* <div
				className={'blackCircle blackCircleOpacity'}
				ref={drivingModeStatusRef}
				onClick={() => { setParameter('drivingMode', SET_DRIVING_MODE_STATUS, !drivingMode) }}
			>
				<div className="iconWrapper">
					{drivingMode ? <img src="../../assets/images/meeting-view.svg" alt="" /> : <img src="../../assets/images/driving-view.svg" alt="" />}
				</div>
			</div> */}
			<div
				className={moreOptionsStatus ? 'moreOptionsWrapper' : 'displayNone'}
				ref={sessionMoreOptionsRef}
				onMouseEnter={() =>
					setParameter('moreOptionsStatus', SET_MORE_OPTIONS_STATUS, true)
				}
				onMouseLeave={() =>
					setParameter('moreOptionsStatus', SET_MORE_OPTIONS_STATUS, false)
				}
			>
				<SessionMoreOptions
					onHideNavClick={onHideNavClick}
					onShareScreenClick={onShareScreenClick}
					onFullScreenClick={onFullScreenClick}
					navVideoStatus={navVideoStatus}
					shareScreenStatus={shareScreenStatus}
					fullScreenStatus={fullScreenStatus}
				/>
			</div>

			{/* <div
				className={
					remoteVideoZoomStatus
						? 'circleRectangle blackCircleOpacity '
						: 'circleRectangle'
				}
				ref={remoteVideoZoomStatusRef}
				onMouseEnter={() =>
					setParameter('remoteVideoZoomStatus', SET_REMOTE_VIDEO_ZOOM_STATUS, true)
				}
				onMouseLeave={() =>
					setParameter('remoteVideoZoomStatus', SET_REMOTE_VIDEO_ZOOM_STATUS, false)
				}
			>
				<div
					className={
						remoteVideoZoomStatus
							? 'iconWrapper zoomWrapper cursorPointer'
							: 'iconWrapper zoomWrapper'
					}
					onClick={onRemoteVideoZoomIconClick}
				>
					<img src="../../assets/images/zoom.svg" alt="" />
				</div>
				<div
					className="rectangleLocalVoice"
					onClick={() => {
						return false;
					}}
					ref={remoteVideoZoomRef}
				>
					<Slider
						onChange={onRemoteVideoZoomChange}
						value={remoteVideoZoom}
						icon="zoom-green.svg"
						id="remoteVideoZoom"
					/>
				</div>
			</div> */}

			<div
				className={
					remoteStream && dataChannel1 && dataChannel1.readyState === 'open'
						? 'circleRectangle '
						: 'circleRectangleDisabled'
				}
				ref={localVoiceStatusRef}
			>
				<div className="iconWrapper" onClick={onLocalVoiceIconClick}>
					<img
						src={`../../assets/images/${parseInt(localVoiceVolume) === 0 ? 'mic-off-red.svg' : 'mic.svg'
							}`}
						alt=""
					/>
				</div>
				<div className="localVoiceText">{localVoiceVolume}%</div>
				<div className="rectangleLocalVoice" ref={localVoiceRef}>
					<Slider
						onChange={onLocalVoiceChange}
						value={localVoiceVolume}
						icon={
							parseInt(localVoiceVolume) === 0
								? 'mic-off-red.svg'
								: 'mic-off-green.svg'
						}
						id="localVideoVolume"
					/>
				</div>
			</div>

			<div className="redCircle blackCircle" onClick={onHangUpClick}>
				<div className="iconWrapper">
					<img src="../../assets/images/white-stop.svg" alt="" />
				</div>
			</div>
		</div>
	);
};

export default reduxConnector(SessionOptions);
