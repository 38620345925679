import React, { FC, useState, useEffect } from 'react';
import {
	IonIcon,
	IonLabel,
	IonList,
	IonItem,
	IonListHeader,
	IonAvatar,
	IonSegment,
	IonSegmentButton,
	IonInput,
	IonCol,
	IonSelect,
	IonSelectOption,
	IonRow,
	IonGrid,
	IonButton,
} from '@ionic/react';
import { personCircle, camera, globe, checkmarkCircle } from 'ionicons/icons';
import FormInputListItem from '../FormInputListItem/FormInputListItem';
import { injectIntl, FormattedMessage } from 'react-intl';
import isAuthenticated from '../Authentication/Authenticated';
import isURL from 'validator/lib/isURL';
import scaleImage from '../../actions/imageScaler';
import { useForm } from 'react-hook-form';
import capitalize from '../../actions/capitalize';

import classes from './ProfileSettings.module.css';
import classNames from 'classnames';

import Messages from './ProfileSettings.messages';
import { eyeSharp, eyeOffSharp } from 'ionicons/icons';
import { useTypedSelector } from '../../reducers';
import { publish } from '../../actions/publish';
import PasswordRequirements from '../PasswordRequirements/PasswordRequirements';
import {
	checkOneLowerCase,
	checkOneNumeric,
	checkOneUpperCase,
	checkPassword,
} from '../../utils/validator';

interface ProfileSettingsSubmenuProps {
	goTo?: () => void;
}

type UserFrom = {
	firstName: string;
	lastName: string;
	password: string;
	retypedPassword: string;
};

const ProfileSettingsSubmenu: FC<ProfileSettingsSubmenuProps> = (props: any) => {
	const { intl } = props;
	const user = useTypedSelector(state => state.accountState.user);
	const { control, watch, reset } = useForm<UserFrom>({
		defaultValues: {
			firstName: user?.firstName || '',
			lastName: user?.lastName || '',
		},
	});
	const password = watch('password');
	const retypedPassword = watch('retypedPassword');
	const [passwordType, setPasswordType] = useState(false);
	const [passwordTypeConfirm, setPasswordTypeConfirm] = useState(false);
	const [showInputErrorPassword, setShowInputErrorPassword] = useState(true);
	const [passwordLengthError, setPasswordLengthError] = useState('');
	const [showInputErrorRetyped, setShowInputErrorRetyped] = useState(true);
	const [retypedLengthError, setRetypedLengthError] = useState('');
	// set this to false when the proper service is up and running
	const [expandUrl, setExpandUrl] = useState(true);
	const [editHidden, setEditHidden] = useState(true);
	const [reader] = useState(new FileReader());

	const [passwordsNotMatch, setPasswordsNotMatch] = useState(false);
	const [passwordError, setPasswordError] = useState('');

	const [defaultLanguage, setDefaultLanguage] = useState<any>();
	const [userPicture, setUserPicture] = useState<any>();

	const username = useTypedSelector(state => state.accountState.user.username);
	const profilePictureLink = useTypedSelector(
		state => state.accountState.user.profilePictureLink
	);
	let encodedUser = window.btoa(username);

	const spinoutType = useTypedSelector(state => state.versionState.spinoutType) as string;

	useEffect(() => {
		setUserPicture(profilePictureLink);
	}, [profilePictureLink]);

	const checkPasswordLength = (event: any) => {
		if (!event.detail.value) return;
		const targetName = event.target.name;
		const value = event.target.value;
		const { password, retypedPassword } = control.getValues();

		switch (targetName) {
			case 'password':
				setPasswordError('');
				if (value.length < 8) {
					setShowInputErrorPassword(true);
					setPasswordLengthError(
						intl.formatMessage({ id: 'ConfirmPage.passwordLength' })
					);

					if (retypedPassword && retypedPassword.length >= 8) {
						setShowInputErrorRetyped(false);
						setRetypedLengthError('');
					}
					setPasswordsNotMatch(true);
				} else if (!checkOneLowerCase(value)) {
					setShowInputErrorPassword(true);
					setPasswordLengthError(
						intl.formatMessage({ id: 'ConfirmPage.passwordLowerCase' })
					);
				} else if (!checkOneNumeric(value)) {
					setShowInputErrorPassword(true);
					setPasswordLengthError(
						intl.formatMessage({ id: 'ConfirmPage.passwordNumeric' })
					);
				} else if (!checkOneUpperCase(value)) {
					setShowInputErrorPassword(true);
					setPasswordLengthError(
						intl.formatMessage({ id: 'ConfirmPage.passwordUpperCase' })
					);
				} else {
					setShowInputErrorPassword(false);
					setPasswordLengthError('');
					if (password === retypedPassword) setPasswordsNotMatch(false);
				}

				break;
			case 'retypedPassword':
				setPasswordError('');
				if (value.length < 8) {
					setShowInputErrorRetyped(true);
					setRetypedLengthError(intl.formatMessage({ id: 'ConfirmPage.passwordLength' }));
					setPasswordsNotMatch(true);
				} else {
					setShowInputErrorRetyped(false);
					setRetypedLengthError('');
				}

				if (value && value.length >= 8) {
					if (password !== value) {
						setShowInputErrorRetyped(true);
						setRetypedLengthError(
							intl.formatMessage({ id: 'ConfirmPage.passwordNoMatch' })
						);
						setPasswordsNotMatch(true);
					} else if (!checkOneLowerCase(value)) {
						setShowInputErrorRetyped(true);
						setRetypedLengthError(
							intl.formatMessage({ id: 'ConfirmPage.passwordLowerCase' })
						);
					} else if (!checkOneNumeric(value)) {
						setShowInputErrorRetyped(true);
						setRetypedLengthError(
							intl.formatMessage({ id: 'ConfirmPage.passwordNumeric' })
						);
					} else if (!checkOneUpperCase(value)) {
						setShowInputErrorRetyped(true);
						setRetypedLengthError(
							intl.formatMessage({ id: 'ConfirmPage.passwordUpperCase' })
						);
					} else {
						setShowInputErrorRetyped(false);
						setRetypedLengthError('');
						if (password === retypedPassword) setPasswordsNotMatch(false);
					}
				}
				break;
			default:
				break;
		}
	};

	const setLanguage = (e: CustomEvent) => {
		setDefaultLanguage(e.detail.value);
	};

	useEffect(() => {
		setTimeout(() => {
			setPasswordType(true);
			setPasswordTypeConfirm(true);
			setDefaultLanguage(capitalize(JSON.parse(JSON.stringify(user?.language))));
		}, 250);
	}, [user, user.language]);

	const setPicture = (event: any) => {
		let f = event.target.files[0] || undefined;
		if (f === undefined) return;
		reader.onload = () => {
			scaleImage(reader.result, props, (srcObject: any) => {
				setUserPicture(srcObject);
			});
		};
		reader.readAsDataURL(f);
		setEditHidden(true);
	};

	const setUrlPicture = (e: CustomEvent) => {
		if (isURL(e.detail.value, { allow_underscores: true })) {
			setUserPicture(e.detail.value);
		} else {
			// display error
		}
	};

	const handleDisableButton = () => {
		const { firstName, lastName } = control.getValues();
		if (
			user.firstName === firstName &&
			user.lastName === lastName &&
			user.language === defaultLanguage?.toLowerCase() &&
			userPicture === profilePictureLink &&
			passwordsNotMatch
		) {
			return true;
		} else return false;
	};
	const onChangeContent = (value: string) => {
		switch (value) {
			case 'file':
				const e = document.getElementById('fileSelector') as HTMLInputElement;
				e.click();
				break;
			case 'url':
				// uncomment when the proper service is up and running
				// setExpandUrl(!expandUrl);
				break;
			default:
				break;
		}
	};

	const updateUserProfile = () => {
		const { firstName, lastName } = control.getValues();
		const dataObj = {
			language: defaultLanguage,
			firstName,
			lastName,
			profilePictureLink: userPicture === profilePictureLink ? undefined : userPicture,
		};

		if (
			password &&
			retypedPassword &&
			password === retypedPassword &&
			checkPassword(retypedPassword)
		) {
			publish(`microservice/${encodedUser}/updatePassword`, {
				data: { username: username, password: retypedPassword },
				requestId: 'updatePasswordId',
			});
			window.sessionStorage.setItem('psw', window.btoa(retypedPassword));
			setPasswordError('');
			setPasswordType(true);
			setPasswordTypeConfirm(true);
			setPasswordsNotMatch(true);

			reset();
		} else if (password !== retypedPassword) {
			setPasswordsNotMatch(true);
			setPasswordError(intl.formatMessage({ id: 'ConfirmPage.passwordNoMatch' }));
		}

		publish(`microservice/${encodedUser}/updateOwnInfo`, {
			data: dataObj,
			requestId: 'updateOwnInfoId',
		});
	};

	return (
		<IonGrid className={classes.formGrid}>
			<IonRow>
				<IonList className="ion-padding">
					<IonListHeader>
						<IonLabel>
							<b>
								<FormattedMessage {...Messages.accountSettings} />
							</b>
						</IonLabel>
					</IonListHeader>
				</IonList>
			</IonRow>
			<IonRow>
				<IonCol className={classes.firstCol}>
					<IonList>
						<FormInputListItem
							type="text"
							name="firstName"
							label={intl.formatMessage({
								id: 'InviteModal.firstname',
							})}
							placeholderText={intl.formatMessage({
								id: 'InviteModal.firstnameHint',
							})}
							control={control}
							rules={{ required: true }}
						/>
						<FormInputListItem
							type="text"
							name="lastName"
							label={intl.formatMessage({
								id: 'InviteModal.lastname',
							})}
							placeholderText={intl.formatMessage({
								id: 'InviteModal.lastnameHint',
							})}
							control={control}
							rules={{ required: false }}
						/>
						{spinoutType !== 'beam' && (
							<IonItem
								className={classNames(classes.selectContainer, 'ion-no-padding')}
							>
								<IonLabel position="floating">
									<FormattedMessage id="AccountManagementPage.language" />
								</IonLabel>
								<IonSelect
									id="language"
									interface="popover"
									value={defaultLanguage}
									onIonChange={setLanguage}
								>
									<IonSelectOption value="English">
										<FormattedMessage id="AccountManagementPage.english" />
									</IonSelectOption>
									{/* <IonSelectOption value="Arabic">
									<FormattedMessage id="AccountManagementPage.arabic" />
								</IonSelectOption>
								<IonSelectOption value="Danish">
									<FormattedMessage id="AccountManagementPage.danish" />
								</IonSelectOption> */}
								</IonSelect>
							</IonItem>
						)}

						<div>
							<IonList className={classNames(classes.forgotPswList, 'ion-padding')}>
								{password && (
									<PasswordRequirements
										checkMarkContainer={
											retypedLengthError === ''
												? classes.checkMarkContainer
												: classes.checkMarkContainerError
										}
										password={password}
									/>
								)}
								

								<FormInputListItem
									key="pass"
									type={passwordType ? 'password' : 'text'}
									name="password"
									control={control}
									rules={{ required: true }}
									errorMsg={
										showInputErrorPassword ? passwordLengthError : undefined
									}
									onChange={checkPasswordLength}
									required
									label={intl.formatMessage({
										id: 'SetPassword.password',
									})}
									placeholderText={intl.formatMessage({
										id: 'LoginPage.passwordHint',
									})}
									iconUrl={passwordType ? eyeSharp : eyeOffSharp}
									iconCallback={() => setPasswordType(prev => !prev)}
								/>
								{ !showInputErrorRetyped && <div className={classes.checkMarkConfirmationContainer}>
									<IonIcon size="small" color="primary" icon={checkmarkCircle} />
								</div>}
								<FormInputListItem
									key="confirmPass"
									type={passwordTypeConfirm ? 'password' : 'text'}
									name="retypedPassword"
									control={control}
									rules={{
										required: true,
									}}
									errorMsg={
										showInputErrorRetyped ? retypedLengthError : undefined
									}
									onChange={checkPasswordLength}
									required
									label={intl.formatMessage({
										id: 'SetPassword.retypePassword',
									})}
									placeholderText={intl.formatMessage({
										id: 'SetPassword.retypePassword',
									})}
									iconUrl={passwordTypeConfirm ? eyeSharp : eyeOffSharp}
									iconCallback={() => setPasswordTypeConfirm(prev => !prev)}
								/>
							</IonList>
							<IonLabel className={classes.errorContainer}>{passwordError}</IonLabel>
						</div>
					</IonList>
				</IonCol>
				<IonCol>
					<IonList>
						{spinoutType !== 'beam' && (
							<IonListHeader className={classes.profileListHeader} lines="none">
								<div className={classes.profileHeader}>
									<IonLabel>
										{user?.firstName} {user?.lastName}
									</IonLabel>
								</div>
							</IonListHeader>
						)}
						{spinoutType !== 'beam' && (
							<IonItem lines="none">
								<IonAvatar
									className={classNames(
										classes.userAvatar,
										'ion-margin-end ion-margin-start'
									)}
								>
									<img
										className={classNames(classes.fullImage)}
										src={userPicture || personCircle}
										alt="avatar"
									/>
								</IonAvatar>
							</IonItem>
						)}
						{spinoutType !== 'beam' && (
							<IonItem lines="none" className={classes.msgContainer}>
								<IonSegment
									className={classes.tabContainer}
									mode="ios"
									onIonChange={(e: any) => onChangeContent(e.detail.value)}
								>
									<IonSegmentButton
										className={
											expandUrl
												? classNames(classes.hidden)
												: 'ion-no-padding'
										}
										value="file"
										layout="icon-start"
									>
										<IonIcon size="small" icon={camera} />
										<IonLabel>
											<FormattedMessage {...Messages.upload} />
										</IonLabel>
									</IonSegmentButton>
									{expandUrl ? (
										<div className={classNames(classes.urlExpand)}>
											<div className={classNames(classes.iconContainer)}>
												<IonIcon
													className={classes.iconExpanded}
													size="small"
													icon={globe}
												/>
											</div>
											<IonInput
												className={classNames(classes.expandInput)}
												type="url"
												placeholder={intl.formatMessage({
													id: 'AccountManagementPage.addUrl',
												})}
												onIonChange={e => setUrlPicture(e)}
											/>
										</div>
									) : (
										<IonSegmentButton
											className="ion-no-padding"
											value="url"
											layout="icon-start"
										>
											<IonIcon size="small" icon={globe} />
											<IonLabel>
												<FormattedMessage {...Messages.web} />
											</IonLabel>
										</IonSegmentButton>
									)}
								</IonSegment>

								<input
									className={classNames(classes.hidden)}
									id="fileSelector"
									type="file"
									accept="image/*"
									onChange={setPicture}
								/>
							</IonItem>
						)}
					</IonList>
				</IonCol>
			</IonRow>
			<IonRow className={classes.btnRow}>
				<IonCol className={classes.submitCol}>
					<IonButton
						disabled={handleDisableButton()}
						expand="block"
						shape="round"
						type="submit"
						size="large"
						fill="outline"
						onClick={updateUserProfile}
					>
						<FormattedMessage {...Messages.save} />
					</IonButton>
				</IonCol>
			</IonRow>
		</IonGrid>
	);
};

export default injectIntl(isAuthenticated(ProfileSettingsSubmenu, 'ProfileSettingsSubmenu'));
