import React, { useEffect, useState } from 'react';
import { Route, BrowserRouter, Redirect } from 'react-router-dom';
import { IonApp } from '@ionic/react';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import Session from './pages/session';
import SessionLegacy from './pages/sessionLegacy';
import Roaster from './pages/roaster';
import { connect } from 'react-redux';
import './common.scss';
import { connect as connectToMqttBroker, publish, subscribe } from 'redux-mqtt';
import { AppRootState } from '../../../reducers/index';
import { setParameter } from './actions/setParam';
import { ConnectedProps } from 'react-redux';

// import rosterProps from './adminDataSample.json';

let webrtc = require('webrtc-adapter');

export class PropsFromParent {
	connectToMqttBroker: typeof connectToMqttBroker;
	publish: typeof publish;
	subscribe: typeof subscribe;
}

const reduxConnector = connect(
	(state: AppRootState) => ({
		localStream: state.goBeState.sessionState.localStream,
		accountState: state.accountState,
	}),
	{ setParameter, connectToMqttBroker, publish, subscribe }
);

type PropsFromRedux = ConnectedProps<typeof reduxConnector>;
type ComponentProps = PropsFromRedux & PropsFromParent;

const App: React.FC<ComponentProps> = ({
	publish,
	subscribe,
	connectToMqttBroker,
	accountState,
}) => {
	useEffect(() => {
		if (accountState.user.loggedIn) {
			connectToMqttBroker(accountState.user.username, accountState.user.password, {
				onConnect: () => {
					console.log('mqtt : CONNECTED');
					window.onbeforeunload = function(event: Event) {
						this.localStorage.setItem('lastVisit', new Date().getTime().toString());
						return;
					};
				},
				onError: (error: Error) => {
					console.log('mqtt is not connected', error);
				},
				onOffline: () => {
					console.log('broker is offline');
				},
			});
		}
	}, [
		connectToMqttBroker,
		accountState.user.password,
		accountState.user.username,
		accountState.user.loggedIn,
	]);

	return (
		<IonApp style={{ top: '70px' }}>
			<BrowserRouter>
				{/* <Route
					render={() => {
						console.log('going here app');
						return <Redirect to="/gobe" />;
					}}
				/> */}
				<Route
					path="/gobe"
					render={() => {
						window.dispatchEvent(new Event('hashchange'));
						return <Roaster />;
					}}
					exact
				/>
				<Route
					path="/gobe/session"
					render={() => {
						window.dispatchEvent(new Event('hashchange'));
						return <Session />;
					}}
				/>
				<Route
					path="/gobe/session-legacy"
					render={() => {
						window.dispatchEvent(new Event('hashchange'));
						return <SessionLegacy />;
					}}
				/>
			</BrowserRouter>
		</IonApp>
	);
};

export default reduxConnector(App);
