import React, { useState, useCallback, useEffect } from 'react';
import {
	IonList,
	IonButton,
	IonIcon,
	IonLabel,
	IonSegment,
	IonSegmentButton,
	IonItem,
	IonContent,
	IonCheckbox,
} from '@ionic/react';
import { chevronBackOutline } from 'ionicons/icons';
import LoginTemplate from '../../components/LoginTemplate/LoginTemplate';
import { injectIntl, FormattedMessage } from 'react-intl';
import JWTDecode from 'jwt-decode';
import isAuthenticated from './../../components/Authentication/Authenticated';

import { useParams } from 'react-router';

import classNames from 'classnames';
import classes from './Agreements.module.css';

import LanguageOptions from '../../config/LanguageOptions';
import Messages from './Agreements.messages';
import { publish } from '../../actions/publish';
import AgreementsFrame from './AgreementsFrame';
import { isAgreementsAccepted, isAgreementsAcceptedCombined } from '../../actions/accountActions';
import { useTypedSelector } from '../../reducers';
import { Account } from '../../reducers/accountReducers';
import { b64EncodeUnicode } from '../../utils/encoding';
import ErrorModal from '../../components/ErrorModal/ErrorModal';

const keys: string[] = [
	'businessTermsAndConditions',
	'termsOfUse',
	'subProcessingAgreement',
	'dataProcessingAgreement',
	'privacyPolicy',
];
interface Heights {
	businessTermsAndConditions: number;
	termsOfUse: number;
	subProcessingAgreement: number;
	dataProcessingAgreement: number;
	privacyPolicy: number;
}
interface tabElement {
	[key: string]: any;
	value: string;
	message: object;
	description: string;
}
let tabs: Array<tabElement> = [];
let agreementIds: string[] = [];

const Agreements: React.FC = (props: any) => {
	const [selectedSegment, setSelectedSegment] = useState<string>('');
	const [acceptAll, setAcceptAll] = useState(false);
	const [accepted, setAccepted] = useState<boolean[]>([]);
	const [languageValue, setLanguageValue] = useState('');
	const [businessTermsAndConditions, setBusinessTermsAndConditions] = useState(1000);
	const [termsOfUse, setTermsOfUse] = useState(1000);
	const [subProcessingAgreement, setSubProcessingAgreement] = useState(1000);
	const [dataProcessingAgreement, setDataProcessingAgreement] = useState(1000);
	const [privacyPolicy, setPrivacyPolicy] = useState(1000);
	const [userType, setUserType] = useState('user');
	const [tokenError, setTokenError] = useState('');
	const [basicInfoRequested, setBasicInfoRequested] = useState(false);

	const user = useTypedSelector(state => state.accountState.user) as Account;
	const organization = useTypedSelector(state => state.organizationState.organizations);
	const selectedOrg = useTypedSelector(state => state.selectedOrganizationState.organization);
	const spinoutType = useTypedSelector(state => state.versionState.spinoutType);

	let { token, language } = useParams<Record<any, any>>();

	if (!basicInfoRequested) {
		publish(
			`microservice/${b64EncodeUnicode(user.username)}/getOwnAccountInfo`,
			JSON.stringify({ requestId: 'someId' })
		);
		setBasicInfoRequested(true);
	}

	const setHeightOnMessage = (e: MessageEvent) => {
		const agreementKey: keyof Heights = Object.keys(e.data)[0] as keyof Heights;
		if (e.data && e.origin === 'null' && keys.find(key => key === agreementKey)) {
			const frame = document.getElementById(agreementKey);
			if (frame != null) {
				setHeight(agreementKey, parseInt(`${e.data[agreementKey]}`) + 10);
			}
		}
		window.removeEventListener('message', setHeightOnMessage);
	};

	const setHeight = (agreementKey: string, height: number) => {
		switch (agreementKey) {
			case 'businessTermsAndConditions':
				setBusinessTermsAndConditions(height);
				break;
			case 'termsOfUse':
				setTermsOfUse(height);
				break;
			case 'subProcessingAgreement':
				setSubProcessingAgreement(height);
				break;
			case 'dataProcessingAgreement':
				setDataProcessingAgreement(height);
				break;
			case 'privacyPolicy':
				setPrivacyPolicy(height);
				break;
			default:
				break;
		}
	};

	const getHeight = (tab: { value: string }) => {
		switch (tab.value) {
			case 'businessTermsAndConditions':
				return businessTermsAndConditions;
			case 'termsOfUse':
				return termsOfUse;
			case 'subProcessingAgreement':
				return subProcessingAgreement;
			case 'dataProcessingAgreement':
				return dataProcessingAgreement;
			case 'privacyPolicy':
				return privacyPolicy;
			default:
				return 1000;
		}
	};

	useEffect(() => {
		if(selectedOrg.orgId && token){
			const obj: {
				exp: number;
				org: { name: string; org_id: string; org_type: string; language: string };
				type: string;
				username: string;
			} = JWTDecode(token);
			if(selectedOrg.acceptedAgreementsLoaded === false){
				publish(`microservice/${obj.org.org_id}/${b64EncodeUnicode(user.username)}/getOrgAcceptedAgreements`, {
					requestId: 'getOrgAcceptedAgreementsId',
					data: {
						spinoutType: spinoutType ? spinoutType : '',
					},
				});
			}
		}
	}, [selectedOrg]);

	useEffect(() => {
		window.onmessage = setHeightOnMessage;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [businessTermsAndConditions, termsOfUse, subProcessingAgreement, dataProcessingAgreement]);

	useEffect(() => {
		if (token && token !== 'noToken') {
			try {
				const obj: {
					exp: number;
					org: { name: string; org_id: string; org_type: string; language: string };
					type: string;
					username: string;
				} = JWTDecode(token);
				let userTypeInToken;

				if (obj.exp < new Date().getTime() / 1000) {
					setTokenError(props.intl.formatMessage({ id: 'ConfirmPage.tokenExpired' }));
				} else {
					if (obj.type) {
						const type = obj.type === 'orgCreated' ? obj.org.org_type : 'user';
						userTypeInToken = type;
						setUserType(type);
					}

					publish(
						`microservice/${b64EncodeUnicode(obj.username)}/getAcceptedAgreements`,
						{
							requestId: 'getAcceptedAgreementsId',
							data: {
								spinoutType: spinoutType ? spinoutType : '',
							},
						}
					);

					publish(
						`microservice/${obj.org.org_id}/${b64EncodeUnicode(
							obj.username
						)}/getAgreementsContent/${userTypeInToken}`,
						{
							requestId: 'someId',
							data: {
								lang: language,
								orgId: obj.org.org_id,
								spinoutType: spinoutType ? spinoutType : '',
							},
						}
					);
				}
			} catch (error) {
				setTokenError(props.intl.formatMessage({ id: 'ConfirmPage.tokenExpired' }));
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [token]);

	useEffect(() => {
		if (
			user.agreements &&
			user.agreementsLoaded &&
			user.acceptedAgreements &&
			user.acceptedAgreementsLoaded &&
			selectedOrg.orgId &&
			selectedOrg.acceptedAgreementsLoaded
		) {			
			if (isAgreementsAcceptedCombined(user,selectedOrg)) {
				props.history.push(`/confirm-user/${token}`);
			} else {
				for (let agreement of user.agreements) {
					if (agreement.type === 'safety-agreement') continue;
					if (
						user.acceptedAgreements.find(
							a => a.orgId === agreement.orgId && a.agreementId === agreement.id
						)
					) {
						continue;
					}
					agreementIds.push(agreement.id);
					accepted.push(false);

					if (agreement.id.includes('data')) {
						tabs.push({
							value: 'dataProcessingAgreement',
							message: Messages.dataProcessingAgreement,
							description: agreement.content,
						});
					} else if (agreement.id.includes('sub')) {
						tabs.push({
							value: 'subProcessingAgreement',
							message: Messages.subProcessingAgreement,
							description: agreement.content,
						});
					} else if (agreement.id.includes('business')) {
						tabs.push({
							value: 'businessTermsAndConditions',
							message: Messages.businessTermsAndConditions,
							description: agreement.content,
						});
					} else if (agreement.id.includes('use')) {
						tabs.push({
							value: 'termsOfUse',
							message: Messages.termsOfUse,
							description: agreement.content,
						});
					} else if (agreement.id.includes('privacy')) {
						tabs.push({
							value: 'privacyPolicy',
							message: Messages.privacy,
							description: agreement.content,
						});
					}
				}
			}

			if (tabs && tabs.length > 0) setSelectedSegment(tabs[0].value);
		}
		return function cleanup() {
			tabs = [];
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user.acceptedAgreements, user.agreements, selectedOrg.acceptedAgreements]);

	useEffect(() => {
		if (language) {
			setLanguageValue(language);
		}
	}, [language]);

	useEffect(() => {
		if (accepted.length === 0) return;
		for (let i = 0; i < accepted.length; i++) {
			if (!accepted[i]) {
				setAcceptAll(false);
				return;
			}
		}

		setAcceptAll(true);
	}, [accepted]);

	const onSegmentChange = (value: any) => {
		setSelectedSegment(value);

		let y = document.getElementById('row-' + value)?.offsetTop;
		let content = document.querySelectorAll('ion-content');
		if (content) {
			content[1].scrollToPoint(0, y, 500);
		}
	};

	const onLanguage = () => {
		props.history.push(`/confirm-organization/${token}`);
	};

	const onAcceptAll = useCallback((checked: boolean) => {
		setAcceptAll(checked);
		setAccepted(prev => prev.map((value: boolean) => (value = checked)));
	}, []);

	const onAcceptContinue = () => {
		if (token !== 'noToken') {
			const tokenData: any = JWTDecode(token);

			if (!tokenData || !tokenData.org_id) {
				throw new Error('No org_id inside tokenData');
			}

			publish(
				`microservice/${tokenData.org_id}/${b64EncodeUnicode(
					user.username
				)}/acceptAgreements/${userType}`,
				{
					requestId: 'acceptAgreementsId',
					data: {
						language: 'en',
						ip_address: undefined,
						agreements_ids: agreementIds,
						spinout_type: spinoutType ? spinoutType : '',
						org: tokenData.type === 'orgCreated' ? tokenData.org : null,
					},
				}
			);

			props.history.push(`/confirm-user/${token}`);
		} else if (token === 'noToken') {
			let typeUser = userType;
			if (organization[user.selectedOrganizationId]) {
				if (
					user.username === organization[user.selectedOrganizationId].contactPerson?.email
				) {
					typeUser = user.organizations[user.selectedOrganizationId].orgType;
				}
			}
			publish(
				`microservice/${user.selectedOrganizationId}/${b64EncodeUnicode(
					user.username
				)}/acceptAgreements/${typeUser}`,
				{
					requestId: 'acceptAgreementsId',
					data: {
						language: 'en',
						ip_address: undefined,
						agreements_ids: agreementIds,
						spinout_type: spinoutType ? spinoutType : '',
					},
				}
			);

			if (spinoutType === 'gobe') {
				props.history.push('/gobe');
			} else {
				props.history.push('/fleetManagement');
			}
		}
	};

	return (
		<LoginTemplate
			mainContent={
				<>
					{tokenError && tokenError.length > 0 ? (
						<ErrorModal
							isOpen
							onConfirm={() => props.history.push('/')}
							onDismiss={() => props.history.push('/')}
							type="token"
						/>
					) : (
						''
					)}
					<IonList className={classes.contentList}>
						<IonLabel>
							<FormattedMessage {...Messages.hint} />
						</IonLabel>
						<IonSegment
							className={
								tabs.length > 1 ? classes.tabContainer : classes.tabContainerAlone
							}
							mode="ios"
							onIonChange={(e: CustomEvent) => onSegmentChange(e.detail.value)}
							value={selectedSegment}
						>
							{tabs.map((tab: any, index: number) => {
								if (tab.value !== 'safetyAgreement')
									return (
										<IonSegmentButton
											key={tab.value}
											value={tab.value}
											layout="icon-start"
											className={
												tab.value === 'dataProcessing'
													? classes.longerTab
													: ''
											}
										>
											<IonLabel className={classes.wrap}>
												<FormattedMessage {...tab.message} />
											</IonLabel>
										</IonSegmentButton>
									);
								else return null;
							})}
						</IonSegment>
						<IonContent className={classes.tabContent}>
							<IonList>
								{tabs.map((tab: any, index: number) => {
									if (tab.value !== 'safetyAgreement')
										return (
											<IonItem
												id={'row-' + tab.value}
												lines="none"
												text-wrap
												key={'row' + index}
											>
												<div className={classes.descriptionContainer}>
													<IonItem
														className={classes.chkContainer}
														lines="none"
													>
														{tabs.length > 1 ? (
															<IonCheckbox
																checked={accepted[index]}
																onIonChange={e => {
																	setAccepted(prev =>
																		prev.map(
																			(
																				value: boolean,
																				i: number
																			) => {
																				if (index === i) {
																					value =
																						e.detail
																							.checked;
																				}
																				return value;
																			}
																		)
																	);
																}}
																name={'isAccepted' + index}
															/>
														) : null}
														<IonLabel>
															<FormattedMessage {...tab.message} />
														</IonLabel>
													</IonItem>
													<br />
													<AgreementsFrame
														agreement={tab}
														messageHeight={getHeight(tab)}
													/>
												</div>
											</IonItem>
										);
									else return null;
								})}
							</IonList>
						</IonContent>
					</IonList>
				</>
			}
			btnContent={
				<>
					{tabs.length > 1 ? (
						<IonItem className={classes.acceptAllContainer} lines="none">
							<IonCheckbox
								checked={acceptAll}
								onIonChange={e => onAcceptAll(e.detail.checked)}
							/>
							<IonLabel>
								<FormattedMessage {...Messages.acceptAll} />
							</IonLabel>
						</IonItem>
					) : null}
					<div className={classes.btnContainer}>
						{LanguageOptions.length > 1 && token !== 'noToken' ? (
							<IonButton
								className={classNames('transparent', classes.languageBtn)}
								shape="round"
								size="large"
								onClick={() => onLanguage()}
							>
								<IonIcon
									className={classes.icon}
									slot="icon-only"
									size="small"
									icon={chevronBackOutline}
								/>
								<FormattedMessage {...Messages.language} />
							</IonButton>
						) : null}

						<IonButton
							className="round"
							shape="round"
							size="large"
							disabled={tabs.length > 1 ? !acceptAll : false}
							onClick={() => onAcceptContinue()}
						>
							<FormattedMessage {...Messages.acceptContinue} />
						</IonButton>
					</div>
				</>
			}
		/>
	);
};

export default injectIntl(isAuthenticated(Agreements, 'Agreements'));
