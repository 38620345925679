import React, { FC, useState, Fragment, useEffect } from 'react';
import {
	IonButton,
	IonIcon,
	IonLabel,
	IonSegment,
	IonSegmentButton,
	IonGrid,
	IonRow,
	IonCol,
} from '@ionic/react';
import {
	closeCircle,
	add,
	checkmarkCircle,
	checkmarkCircleOutline,
	reader,
	headset,
	barcode,
	document,
} from 'ionicons/icons';
import { injectIntl, FormattedMessage } from 'react-intl';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { setParameter } from '../../actions/setParam';
import OrganizationForm from '../OrganizationForm/OrganizationForm';
import OrganizationContactForm from '../OrganizationForm/OrganizationContactForm';
import isAuthenticated from '../Authentication/Authenticated';

import classes from './OrganizationInfo.module.css';
import classNames from 'classnames';
import { useTypedSelector } from '../../reducers';

import Messages from './OrganizationInfo.messages';
import OrganizationAgreementForm from '../OrganizationForm/OrganizationAgreementForm';

const tabs = ['details', 'contact', 'agreements', 'goBeCare'];

interface OrganizationInfoProps {
	filterValues: any;
	orgId: string;
}

const OrganizationInfo: FC<OrganizationInfoProps> = (props: any) => {
	const { organization, orgId } = props;

	const [editable, setEditable] = useState(false);
	const [saved, setSaved] = useState(false);
	const [selectedOrg, setSelectedOrg] = useState(organization.organizations[orgId]);
	const [selectedSegment, setSelectedSegment] = useState<string>(tabs[0]);

	useEffect(() => {
		const allOrganizations = Object.values(organization.organizations); //Object.values(user.organizations);
		const org: any = allOrganizations.find((org: any) => org.orgId === orgId);
		setSelectedOrg(org);
	}, [orgId, organization.organizations]);

	const openAddOrg = async () => {
		props.setParameter('organizations', 'CHANGE_ADD_NEW_ORGANIZATION_STATE', true);
	};

	const onSegmentChange = (value: any) => {
		setSelectedSegment(value);
		setEditable(false);
	};

	const onEdit = (data: any) => {
		setEditable(true);
	};
	const onSave = () => {
		setEditable(false);
		setSaved(true);
		setTimeout(() => {
			setSaved(false);
		}, 5000);
	};
	const onCancelEdit = () => {
		setEditable(false);
		setSaved(false);
	};

	const spinoutType = useTypedSelector(state => state.versionState.spinoutType) as string;

	return (
		<Fragment>
			<div className={classes.leftSide}>
				{selectedOrg.orgType !== 'customer' ? (
					<div className={classes.newBtnContainer}>
						<IonLabel color="primary" className={classes.newLb}>
							<FormattedMessage {...Messages.new} />
						</IonLabel>
						<IonButton className={classes.newBtn} shape="round" onClick={openAddOrg}>
							<IonIcon
								className={classes.addIcon}
								slot="icon-only"
								size="small"
								icon={add}
							/>
						</IonButton>
					</div>
				) : null}
			</div>
			<div className={classes.rightSide}>
				<div className={classes.header}>
					<div className={classes.editBtnContainer}>
						<IonButton
							className={
								editable
									? classNames(classes.editBtn, classes.editable)
									: classes.editBtn
							}
							shape="round"
							onClick={onEdit}
							disabled={editable}
							hidden={
								selectedSegment === 'goBeCare' || selectedSegment === 'agreement'
							}
						>
							<IonIcon src="./assets/img/edit.svg" />
						</IonButton>
						<div
							className={
								editable
									? classes.formBtns
									: classNames(classes.formBtns, classes.hidden)
							}
							hidden={
								selectedSegment === 'goBeCare' || selectedSegment === 'agreement'
							}
						>
							<IonIcon
								slot="end"
								size="large"
								icon={checkmarkCircle}
								onClick={onSave}
							/>
							<IonIcon
								slot="end"
								size="large"
								icon={closeCircle}
								onClick={onCancelEdit}
							/>
						</div>
						{!editable && saved ? (
							<>
								<IonIcon
									slot="end"
									size="small"
									color="primary"
									icon={checkmarkCircleOutline}
								/>
								<IonLabel className={classes.savedLb}>
									<FormattedMessage {...Messages.saved} />
								</IonLabel>
							</>
						) : null}
					</div>
					<IonSegment
						className={classes.tabContainer}
						mode="ios"
						onIonChange={(e: CustomEvent) => onSegmentChange(e.detail.value)}
						value={selectedSegment}
					>
						<IonSegmentButton
							value="details"
							layout="icon-start"
							className={classes.detailsBtn}
						>
							<IonIcon className={classes.iconMargin} size="small" icon={reader} />
							<IonLabel>
								<FormattedMessage {...Messages.details} />
							</IonLabel>
						</IonSegmentButton>
						<IonSegmentButton value="contact" layout="icon-start">
							<IonIcon className={classes.iconMargin} size="small" icon={headset} />
							<IonLabel>
								<FormattedMessage {...Messages.contact} />
							</IonLabel>
						</IonSegmentButton>
						<IonSegmentButton value="agreement" layout="icon-start">
							<IonIcon className={classes.iconMargin} size="small" icon={document} />
							<IonLabel>
								<FormattedMessage {...Messages.agreement} />
							</IonLabel>
						</IonSegmentButton>
						{spinoutType === 'gobe' && (
							<IonSegmentButton
								value="goBeCare"
								layout="icon-start"
								className={classes.goBeCareBtn}
								disabled
							>
								<IonIcon
									className={classes.iconMargin}
									size="small"
									icon={barcode}
								/>
								<IonLabel>
									<FormattedMessage {...Messages.goBeCare} />
								</IonLabel>
							</IonSegmentButton>
						)}
					</IonSegment>
				</div>
				<IonGrid className={classes.segmentContent}>
					<IonRow className={selectedSegment !== 'details' ? classes.hidden : ''}>
						<OrganizationForm
							organization={selectedOrg}
							isEditable={editable}
							saved={saved}
							segment={selectedSegment}
						/>
					</IonRow>
					<IonRow className={selectedSegment !== 'contact' ? classes.hidden : ''}>
						<OrganizationContactForm
							organization={selectedOrg}
							isEditable={editable}
							saved={saved}
							segment={selectedSegment}
						/>
					</IonRow>
					<IonRow className={selectedSegment !== 'agreement' ? classes.hidden : ''}>
						<OrganizationAgreementForm
							organization={selectedOrg}
							isEditable={editable}
							saved={saved}
							segment={selectedSegment}
						/>
					</IonRow>
					<IonRow className={selectedSegment !== 'goBeCare' ? classes.hidden : ''}>
						<IonGrid>
							<IonRow className={classes.goBeHeader}>
								<IonCol>
									<IonLabel>
										<FormattedMessage {...Messages.robotName} />
									</IonLabel>
								</IonCol>
								<IonCol>
									<IonLabel>
										<FormattedMessage {...Messages.serialNr} />
									</IonLabel>
								</IonCol>
								<IonCol>
									<IonLabel>
										<FormattedMessage {...Messages.activationDate} />
									</IonLabel>
								</IonCol>
								<IonCol>
									<IonLabel>
										<FormattedMessage {...Messages.timeLeft} />
									</IonLabel>
								</IonCol>
								<IonCol>
									<IonLabel>
										<FormattedMessage {...Messages.actions} />
									</IonLabel>
								</IonCol>
							</IonRow>
						</IonGrid>
					</IonRow>
				</IonGrid>
			</div>
		</Fragment>
	);
};

const mapStateToProps = (state: any) => ({
	organization: state.organizationState,
});

const enhance = compose(connect(mapStateToProps, { setParameter }));

export default injectIntl(isAuthenticated(enhance(OrganizationInfo), 'Organizations'));
