import React from 'react';
import './index.scss';
import { PropsFromParent } from './model';

const statusConverter = (parkStatus: string) => {
	switch (parkStatus) {
		case 'DOCK DETECTING IN_PROGRESS':
			return {
				dockingTitle: 'Docking',
				dockingStatus: 'Detecting the dock…',
				dockingIcon: 'Docking-NavigatingIcon.svg',
				dockingNote: (
					<div className="dockingNote">
						Release the <span>P</span> key to stop.
					</div>
				),
			};
		case 'DOCK DETECTING SUCCESS':
			return {
				dockingTitle: 'Docking',
				dockingStatus: 'Dock detection Successful.',
				dockingIcon: 'Docking-NavigatingIcon.svg',
				dockingNote: (
					<div className="dockingNote">
						Release the <span>P</span> key to stop.
					</div>
				),
			};
		case 'DOCK DETECTING FAILED':
			return {
				dockingTitle: 'Docking',
				dockingStatus: 'Dock detection failed.',
				dockingIcon: 'Detection Failed.svg',
				dockingNote: (
					<div className="dockingNote">
						Move closer to the dock and press <span>P</span> to try again.
					</div>
				),
			};
		case 'DOCK DOCKING IN_PROGRESS':
			return {
				dockingTitle: 'Docking',
				dockingStatus: 'Navigating onto the dock…',
				dockingIcon: 'Docking-Positioning.svg',
				dockingNote: (
					<div className="dockingNote">
						Release the <span>P</span> key to stop.
					</div>
				),
			};
		case 'DOCK DOCKING FAILED':
			return {
				dockingTitle: 'Docking Failed',
				dockingStatus: 'The GoBe Robot docking failed',
				dockingIcon: 'Docking-Positioning.svg',
				dockingNote: (
					<div className="dockingNote">
						Move closer to the dock and press <span>P</span> to try again.
					</div>
				),
			};
		case 'DOCK DOCKING SUCCESS':
			return {
				dockingTitle: 'Docking Successful',
				dockingStatus: 'The GoBe Robot is now docked.',
				dockingIcon: 'Docking-Successful.svg',
				dockingNote: (
					<div className="dockingNote">
						Release the <span>P</span> key to dismiss.
					</div>
				),
			};
		default:
			return {
				dockingTitle: 'Docking',
				dockingStatus: 'Detecting the dock…',
				dockingIcon: 'Docking-NavigatingIcon.svg',
				dockingNote: (
					<div className="dockingNote">
						Move closer to the dock and press <span>P</span> to try again.
					</div>
				),
			};
	}
};

const Docking: React.FC<PropsFromParent> = ({ parkStatus }) => {
	return (
		<div className="dockingContainer">
			<div className="dockingTitle">{statusConverter(parkStatus).dockingTitle}</div>
			<div className="dockingStatus">{statusConverter(parkStatus).dockingStatus}</div>
			<div className="dockingIconWrapper">
				<img
					alt=""
					src={`../../assets/images/${statusConverter(parkStatus).dockingIcon}`}
				/>
			</div>
			{statusConverter(parkStatus).dockingNote}
		</div>
	);
};

export default Docking;
