import React, { FC, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { RouteComponentProps, useParams, withRouter } from 'react-router';

import JWTDecode from 'jwt-decode';
import { AccountCreatedConfirmationTokenData } from '../../types/types';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { setParameter } from '../../actions/setParam';
import {
	ACCOUNT_CREATE_CONFIRMED_STATUS_UPDATED,
	SIGN_OUT_USER,
	SET_MQTTCLIENT,
	ACCOUNT_CREATE_CONFIRMED_REQUEST_ID_CREATED,
} from '../../actions/types';
import { automaticLogin } from '../../providers/mqtt';

import { IonContent, IonIcon } from '@ionic/react';
import { thumbsUpOutline } from 'ionicons/icons';

import brandingImage from '../../assets/images/gobe-cover-01.png';
import { FMBrandedSplitPane, FMBrandedSplitPaneImage } from '../../ui-elements/FMBrandedSplitPane';
import { FMFooterLogo } from '../../ui-elements/FMFooterLogo';
import { FMHeaderGoBe } from '../../ui-elements/FMHeaderGoBe';

import classes from './AccountCreate.module.css';
import { b64EncodeUnicode } from '../../utils/encoding';
import { Link } from 'react-router-dom';
import { AccountCreateConfirmedStatus } from '../../reducers/accountCreateConfirmedReducer';

import { anonymous, subscribeToAccountCreateConfirmation } from '../../providers/mqtt';
import { useTypedSelector } from '../../reducers';

interface AccountCreateConfirmPageProps extends RouteComponentProps {
	client: any;
	history: any;
	intl: any;
	setParameter: any;
	accountCreateConfirmedStatus: AccountCreateConfirmedStatus;
}

enum PageStatus {
	INITIALIZING,
	AWAITING_RESPONSE,
	DONE,
	ERROR,
}

const AccountCreateConfirmPage: FC<AccountCreateConfirmPageProps> = ({
	accountCreateConfirmedStatus,
	client,
	history,
	intl,
}) => {
	const { token } = useParams<Record<string, any>>();
	const [tokenExpired, setTokenExpired] = useState<boolean>(false);
	const [pageStatus, setPageStatus] = useState<PageStatus>(PageStatus.INITIALIZING);
	const spinoutType = useTypedSelector(state => state.versionState.spinoutType);

	useEffect(() => {
		setParameter('', SIGN_OUT_USER, {});
	}, []);

	useEffect(() => {
		if (!token) {
			return;
		}

		if (client && client.connected) {
			return;
		}

		if (pageStatus !== PageStatus.INITIALIZING) {
			return;
		}

		let tokenData: AccountCreatedConfirmationTokenData;
		try {
			tokenData = JWTDecode(token);
			if (tokenData.exp < new Date().getTime() / 1000) {
				setPageStatus(PageStatus.ERROR);
				setTokenExpired(true);
				return;
			}
		} catch (error) {
			setPageStatus(PageStatus.ERROR);
			return;
		}

		const { username, temp_password: password, type, org_id: orgId } = tokenData;

		setPageStatus(PageStatus.AWAITING_RESPONSE);

		setParameter(
			'requestId',
			ACCOUNT_CREATE_CONFIRMED_REQUEST_ID_CREATED,
			'ConfirmAccountID-' + username
		);

		setParameter(
			'status',
			ACCOUNT_CREATE_CONFIRMED_STATUS_UPDATED,
			AccountCreateConfirmedStatus.PENDING
		);

		automaticLogin(
			username,
			password,
			async (response: { client: any; connected: boolean }) => {
				if (response && response.connected) {
					subscribeToAccountCreateConfirmation(response.client, username);
					response.client.mqttClient.publish(
						`microservice/${b64EncodeUnicode(username)}/accountCreateConfirmation`,
						JSON.stringify({
							requestId: 'ConfirmAccountID-' + username,
							data: { orgId, password: '', token, type, username, spinout: spinoutType },
						})
					);
					return;
				}

				setPageStatus(PageStatus.ERROR);
			}
		);
	}, [token, client, pageStatus]);

	useEffect(() => {
		if (
			accountCreateConfirmedStatus &&
			accountCreateConfirmedStatus !== AccountCreateConfirmedStatus.PENDING
		) {
			setPageStatus(PageStatus.DONE);
		}
	}, [accountCreateConfirmedStatus]);

	const renderConfirmationContent = () => {
		if (pageStatus === PageStatus.ERROR) {
			return (
				<>
					<h2>
						<FormattedMessage id="AccountCreateConfirmPage.error" />
					</h2>
					{tokenExpired && (
						<p>
							<FormattedMessage id="AccountCreateConfirmPage.tokenExpired" />
						</p>
					)}

					<p>
						<FormattedMessage
							id="AccountCreateConfirmPage.retryAction"
							values={{
								a: (msg: string) => <Link to="/account-create">{msg}</Link>,
							}}
						/>
					</p>
				</>
			);
		}

		if (accountCreateConfirmedStatus === AccountCreateConfirmedStatus.PENDING) {
			return (
				<>
					<h2>Processing...</h2>
				</>
			);
		}

		if (accountCreateConfirmedStatus === AccountCreateConfirmedStatus.SUCCESS) {
			return (
				<>
					<div>
						<IonIcon color="primary" icon={thumbsUpOutline} />
					</div>
					<h2>
						<FormattedMessage id="AccountCreateConfirmPage.thankYou" />
					</h2>
					<p>
						<FormattedMessage id="AccountCreateConfirmPage.complete" />
					</p>
				</>
			);
		}

		if (accountCreateConfirmedStatus === AccountCreateConfirmedStatus.ERROR_NOT_CREATED) {
			return (
				<>
					<h2>
						<FormattedMessage id="AccountCreateConfirmPage.errorNotCreated" />
					</h2>
				</>
			);
		}

		if (accountCreateConfirmedStatus === AccountCreateConfirmedStatus.ERROR_ALREADY_CONFIRMED) {
			return (
				<>
					<h2>
						<FormattedMessage id="AccountCreateConfirmPage.errorAlreadyConfirmed" />
					</h2>
				</>
			);
		}
	};

	return (
		<FMBrandedSplitPane contentId="accountCreatedConfirmation">
			<FMBrandedSplitPaneImage url={brandingImage} />
			<IonContent id="accountCreatedConfirmation" class={classes.confirmationContent}>
				<div className={classes.contentContainer}>
					<div className={classes.headerContainer}>
						<FMHeaderGoBe></FMHeaderGoBe>
					</div>
					<div className={classes.confirmationContainer}>
						{renderConfirmationContent()}
					</div>
					<footer className={classes.footerContainer}>
						<FMFooterLogo />
					</footer>
				</div>
			</IonContent>
		</FMBrandedSplitPane>
	);
};

const mapStateToProps = (state: any) => ({
	client: state.mqttState.client,
	accountCreateConfirmedStatus: state.accountCreateConfirmedReducer.status,
});
const enhance = connect(mapStateToProps, { setParameter });

export default injectIntl(withRouter(enhance(AccountCreateConfirmPage)));
