export const SET_DATA_CHANNEL = 'SET_DATA_CHANNEL';
export const SET_LOCAL_STREAM = 'SET_LOCAL_STREAM';
export const SET_REMOTE_STREAM = 'SET_REMOTE_STREAM';
export const SET_NAV_STREAM = 'SET_NAV_STREAM';
export const SET_REMOVE_REMOTE = 'SET_REMOVE_REMOTE';
export const SET_WIFI_STATUS = 'SET_WIFI_STATUS';
export const SET_BATTERY_STATUS = 'SET_BATTERY_STATUS';

export const SET_END_SESSION_STATUS = 'SET_END_SESSION_STATUS';
export const SET_PAUSE_SESSION_STATUS = 'SET_PAUSE_SESSION_STATUS';
export const SET_LOCAL_VOICE_STATUS = 'SET_LOCAL_VOICE_STATUS';
export const SET_REMOTE_VIDEO_STATUS = 'SET_REMOTE_VIDEO_STATUS';
export const SET_REMOTE_VOICE_STATUS = 'SET_REMOTE_VOICE_STATUS';
export const SET_REMOTE_VIDEO_ZOOM_STATUS = 'SET_REMOTE_VIDEO_ZOOM_STATUS';
export const SET_MORE_OPTIONS_STATUS = 'SET_MORE_OPTIONS_STATUS';
export const SET_NAV_OPTIONS_STATUS = 'SET_NAV_OPTIONS_STATUS';
export const SET_NAV_VIDEO_STATUS = 'SET_NAV_VIDEO_STATUS';
export const SET_SHARE_SCREEN_STATUS = 'SET_SHARE_SCREEN_STATUS';
export const SET_FULL_SCREEN_STATUS = 'SET_FULL_SCREEN_STATUS';
export const SET_HIDE_NAV_OPTIONS_STATUS = 'SET_HIDE_NAV_OPTIONS_STATUS';
export const SET_FAILED_CONNECTION_STATUS='SET_FAILED_CONNECTION_STATUS';
export const SET_INPUT_FOCUS_STATUS = 'SET_INPUT_FOCUS_STATUS';
export const SET_RELOAD_STATUS = 'SET_RELOAD_STATUS';
export const SET_DRIVING_MODE_STATUS = 'SET_DRIVING_MODE_STATUS';
export const SET_NAV_CAMERA_HEIGHT = 'SET_NAV_CAMERA_HEIGHT';
export const SET_INITIAL = 'SET_INITIAL';

export const SET_LOCAL_VOICE_VOLUME = 'SET_LOCAL_VOICE_VOLUME';
export const SET_REMOTE_VOICE_VOLUME = 'SET_REMOTE_VOICE_VOLUME';
export const SET_REMOTE_VIDEO_ZOOM = 'SET_REMOTE_VIDEO_ZOOM';
export const SET_NAV_VIDEO_ZOOM = 'SET_NAV_VIDEO_ZOOM';
export const SET_NAV_SPEED = 'SET_NAV_SPEED';
export const SET_ROBOT_ID = 'SET_ROBOT_ID';
export const SET_ROBOT_NAME = 'SET_ROBOT_NAME';
export const SET_VOICE_HEIGHT = 'SET_VOICE_HEIGHT';

export const SET_ROASTER_LARGE_ICONS = 'SET_ROASTER_LARGE_ICONS';
export const SET_ROASTER_SEARCH_NAME = 'SET_ROASTER_SEARCH_NAME';
export const SET_SORT_STATUS = 'SET_SORT_STATUS';
export const SET_ROSTER_DIMENSIONS = 'SET_ROSTER_DIMENSIONS';
export const SET_SORT_METHOD = 'SET_SORT_METHOD';
