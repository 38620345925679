// import { MqttClient } from 'mqtt';
import { RTCInboundRtpStreamStatsEmitter } from '../rtcStats';
// import { MqttClient } from '../../../utils/CustomMqttClient';
import { MqttClient } from 'mqtt';
import PeerConnectionWithSignalling from './peerConnection';

const STATS_PERIOD_MS = 2000;

/** Time (in milliseconds) after which is no bytes have been received,
 * a reconnection is to be triggered */
const MAX_TIME_SINCE_BYTES_RECEIVED_MS = 2000;

/** Once in the reconnection state, reconnect every this number of milliseconds */
const RECONNECT_INTERVAL_MS = 1000;

class MqttReconnector {
	private peerConnection: PeerConnectionWithSignalling;
	private mqttClient: MqttClient;
	private statsEmitter: RTCInboundRtpStreamStatsEmitter | null = null;
	private streamStatsListenerId: string | null = null;

	private lastBytesStats: { time: number; bytes: number } | null = null;

	private reconnectIntervalId: ReturnType<typeof setInterval> | null = null;

	private mqttConnectedEventListenerId = null;

	constructor(mqttClient: MqttClient, peerConnection: PeerConnectionWithSignalling) {
		this.mqttClient = mqttClient;
		this.peerConnection = peerConnection;
	}

	start = (receiver: RTCRtpReceiver, track: MediaStreamTrack) => {
		// TODO: FIXME: Stop any previous listeners before starting a new one.
		this.statsEmitter = new RTCInboundRtpStreamStatsEmitter(receiver, track, STATS_PERIOD_MS);
		this.streamStatsListenerId = this.statsEmitter.addEventListener(
			'bytes',
			(bytes: number) => {
				const isFirstStatistics = this.lastBytesStats === null;
				if (isFirstStatistics) {
					this.lastBytesStats = { bytes, time: performance.now() };
				} else {
					const isNoBytesReceived = bytes === 0;
					if (isNoBytesReceived) {
						const timeSinceBytesReceived_ms =
							performance.now() - this.lastBytesStats!.time;
						const isReconnectionInProgress = this.reconnectIntervalId !== null;
						if (
							timeSinceBytesReceived_ms >= MAX_TIME_SINCE_BYTES_RECEIVED_MS &&
							!isReconnectionInProgress
						) {
							this.mqttClient.addListener('connect', this.promptIceRestart);
							this.reconnectIntervalId = setInterval(() => {
								console.log('MqttReconnector::Reconnecting');
								this.mqttClient.reconnect();
							}, RECONNECT_INTERVAL_MS);
						}

						if (
							timeSinceBytesReceived_ms >= 4000 &&
							this.peerConnection.connectionState === 'connected'
						) {
							console.log('MQTTReconnector - Triggering ICE Restart');
							this.peerConnection.promptIceRestart();
						}
					} else {
						this.lastBytesStats = { bytes, time: performance.now() };
						this.cancelReconnection();
					}
				}
			}
		);
		this.statsEmitter.start();
	};

	stop = () => {
		if (this.streamStatsListenerId !== null) {
			this.statsEmitter?.removeListener('bytes', this.streamStatsListenerId);
			this.streamStatsListenerId = null;
		}
		this.statsEmitter?.stop();
		this.statsEmitter = null;

		this.cancelReconnection();
	};

	/** Cancel any ongoing reconnection */
	private cancelReconnection = () => {
		const isReconnectionInProgress = this.reconnectIntervalId !== null;
		if (isReconnectionInProgress) {
			clearInterval(this.reconnectIntervalId!);
			this.reconnectIntervalId = null;

			this.mqttClient.removeListener('connect', this.promptIceRestart);
		}
	};

	private promptIceRestart = () => {
		this.mqttClient.removeListener('connect', this.promptIceRestart);
		this.peerConnection.promptIceRestart();
	};
}

export default MqttReconnector;
