import React, { FC, useState, useCallback, useEffect } from 'react';
import ProfileSettings from './ProfileSettings';
import NotificationSettings from './NotificationSettings';

import { injectIntl } from 'react-intl';
import isAuthenticated from '../../components/Authentication/Authenticated';
import { publish } from '../../actions/publish';
import { useTypedSelector } from '../../reducers';
import { checkPassword } from '../../utils/validator';
import { AccountInfo } from '../../types/types';

interface AccountSetupProps {
	currentSubMenu: string;
	onSubMenuChange: (name: string) => void;
	disableButton?: any;
	handleProfile?: any;
}

const AccountSetup: FC<AccountSetupProps> = (props: any) => {
	const { currentSubMenu, onSubMenuChange, disableButton, handleProfile } = props;

	const [currentItem, setCurrentItem] = useState<string | undefined>(currentSubMenu);
	const [profile, setProfile] = useState<any>();

	const [updatedPhoneNumber, setUpdatedPhoneNumber] = useState<string>();

	const user: AccountInfo = useTypedSelector(state => state.accountState.user);

	const encodedUser = window.btoa(user.username || '');

	const isAdmin = (user: AccountInfo) => {
		if (!user.roles) {
			return false;
		}
		const { roleId } = user.roles[user.selectedOrganizationId];
		return ['spinoutAdmin', 'solutionProviderAdmin'].includes(roleId);
	};

	useEffect(() => {
		let selectedItem = document.getElementById('row-' + currentItem);
		let top = selectedItem ? selectedItem.offsetTop + 30 : 30;
		let arrow = document.getElementById('arrow');
		if (arrow) {
			arrow.style.top = top.toString() + 'px';
		}
	});

	const updateNotificationSettings = useCallback((checked: string[]) => {
		// TODO: Handle updated notification settings here once implemented in the backend end
	}, []);

	const updateFunction = useCallback(() => {
		switch (currentSubMenu) {
			case 'profile':
				break;
			case 'notifications':
				// TODO: Handle updated phone number once implemented in the backend end
				console.log(`Updated phone number is: ${updatedPhoneNumber}`);
				if (
					user.firstName !== profile.firstName ||
					user.lastName !== profile.lastName ||
					user.language !== profile.language ||
					user.profilePictureLink !== profile.profilePictureLink
				) {
					publish(`microservice/${encodedUser}/updateOwnInfo`, {
						data: {
							firstName: profile.firstName,
							lastName: profile.lastName,
							language: profile.language,
							profilePictureLink: profile.profilePictureLink,
						},
						requestId: 'updateOwnInfo',
					});
				}

				if (
					profile.password &&
					profile.password.length >= 8 &&
					user.password !== profile.password
				) {
					if (!checkPassword(profile.password)) return;
					publish(`microservice/${encodedUser}/updatePassword`, {
						data: { username: profile.username, password: profile.password },
						requestId: 'updatePasswordId',
					});
					window.sessionStorage.setItem('psw', window.btoa(profile.password));
				}

				break;
		}
	}, [currentSubMenu, encodedUser, profile, updatedPhoneNumber, user]);

	useEffect(() => {
		updateFunction();
		setCurrentItem(currentSubMenu);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentSubMenu]);

	const goToItem = useCallback(
		(itemName: string) => {
			setCurrentItem(itemName);
			onSubMenuChange(itemName);
		},
		[onSubMenuChange]
	);

	const handleProfileChange = useCallback(
		(label: any, value: any) => {
			let tempProfileDetail = JSON.parse(JSON.stringify(profile));

			switch (label) {
				case 'firstName':
					tempProfileDetail[label] = value[label];
					break;
				case 'lastName':
					tempProfileDetail[label] = value[label];
					break;
				case 'language':
					tempProfileDetail[label] = value;
					break;
				case 'password':
					tempProfileDetail[label] = value;
					break;
				case 'profilePictureLink':
					tempProfileDetail[label] = value;
					break;
				case 'retypedPassword':
					tempProfileDetail[label] = value;
					break;
			}
			setProfile(tempProfileDetail);
			handleProfile(tempProfileDetail);
		},
		[handleProfile, profile]
	);

	const handleButtonDisableFunction = useCallback(
		value => {
			disableButton(value);
		},
		[disableButton]
	);

	const renderContent = useCallback(() => {
		if (!profile) setProfile(user);
		switch (currentItem) {
			case 'profile':
				return (
					<ProfileSettings
						goTo={goToItem}
						monitorizeFormChange={handleProfileChange}
						updatePhoneNumber={(phoneNumber: string) => {
							setUpdatedPhoneNumber(phoneNumber);
						}}
						nextButtonDisableFunctionHandle={handleButtonDisableFunction}
					/>
				);
			case 'notifications':
				return (
					<NotificationSettings
						goTo={goToItem}
						nextButtonDisableFunctionHandle={handleButtonDisableFunction}
						initChecked={[]}
						updateChecked={updateNotificationSettings}
						showAdminSettings={isAdmin(user)}
					/>
				);
			default:
				return null;
		}
	}, [
		currentItem,
		goToItem,
		handleButtonDisableFunction,
		handleProfileChange,
		profile,
		updateNotificationSettings,
		user,
	]);

	return renderContent();
};

export default injectIntl(isAuthenticated(AccountSetup, 'AccountSetup'));
