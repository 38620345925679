import React, { FC, useState, useCallback, useEffect } from 'react';
import {
	IonLabel,
	IonList,
	IonItem,
	IonButton,
	IonGrid,
	IonRow,
	IonCol,
	IonIcon,
} from '@ionic/react';
import { chevronBack, trash } from 'ionicons/icons';
import isAuthenticated from '../../components/Authentication/Authenticated';
import MainContent from '../../components/MainContent/MainContent';
import Tabs from '../../components/Tabs/Tabs';
import AccountSetup from '../../components/AccountSetup/AccountSetup';
import Organization from '../../components/OrganizationSetup/OrganizationSetup';
import RobotsSetup from '../../components/RobotsSetup/RobotsSetup';
import UserGroupSetup from '../../components/UserGroupSetup/UserGroupSetup';
import SubMenuComponenet from '../../components/OrganizationSetupSubMenu/OrganizationSetupSubMenu';
import ErrorModal from '../../components/ErrorModal/ErrorModal';
import { injectIntl, FormattedMessage } from 'react-intl';
import { useParams } from 'react-router';
import JWTDecode from 'jwt-decode';

import classes from './OrganizationSetup.module.css';
import classNames from 'classnames';
import Messages from './OrganizationSetup.messages';
import { publish } from '../../actions/publish';
import { useTypedSelector } from '../../reducers';
import RobotGroupSetup from '../../components/RobotGroupsSetup/RobotGroupSetup';
import DeleteModal from '../../components/DeleteModal/DeleteModal';
import { equalityFnc, getDevices } from '../../utils/conformState';
import { b64EncodeUnicode } from '../../utils/encoding';

const OrganizationSetup: FC = (props: any) => {
	const { intl, history, location } = props;
	let { token } = useParams<Record<string, any>>();

	const selectedOrganization = useTypedSelector(
		state => state.selectedOrganizationState.organization,
		(left, right) => equalityFnc(left, right)
	);
	const user = useTypedSelector(
		state => state.accountState.user,
		(left, right) => equalityFnc(left, right)
	);
	const username = useTypedSelector(state => state.accountState.user.username);
	const userGroups = useTypedSelector(state => state.userGroupsState);
	const deviceGroup = useTypedSelector(state => state.deviceGroupsState);

	const devicesObject = useTypedSelector(
		state => state.deviceState.devicesByOrganizationId[selectedOrganization.orgId]
	);

	const [updateMessages, setUpdateMessages] = useState<{ [Identifier: string]: Object }>({});
	const [disableNextButtonHandle, setDisableNextButtonHandle] = useState(false);
	const [profile, setProfile] = useState<any>();
	const [deleteOpen, setDeleteOpen] = useState(false);
	const [currentSubMenuItems, setCurrentSubMenuItems] = useState<any>([]);
	const [nextTabSubMenuItems, setNextTabSubMenuItems] = useState<any>([]);
	const [prevTabSubMenuItems, setPrevTabSubMenuItems] = useState<any>([]);
	const [deleteGroupItem, setDeleteGroupItem] = useState<boolean>(false);
	const [tokenError, setTokenError] = useState('');
	const [basicInfoRequested, setBasicInfoRequested] = useState(false);

	const spinoutType = useTypedSelector(state => state.versionState.spinoutType);

	const [activePath, setActivePath] = useState(location.pathname);

	let encodedUser = window.btoa(username);

	const tabsData = [
		{
			title: Messages.account,
			path: 'account',
			disabled: false,
		},
		{
			title: Messages.organization,
			path: 'organization',
			disabled: false,
		},
		// {
		// 	title: Messages.robots,
		// 	path: 'robots',
		// 	disabled: false,
		// },
		// Hidden until stability is reached
		// {
		// 	title: Messages.robotGroups,
		// 	path: 'robotGroups',
		// 	disabled: false,
		// },
		// Hidden until stability is reached
		// {
		// 	title: Messages.userGroups,
		// 	path: 'userGroups',
		// 	disabled: false,
		// },
	];

	const [currentTab, setCurrentTab] = useState<number>(0);
	const [currentSubMenu, setCurrentSubMenu] = useState<string | undefined>('profile');

	const publishUpdateMessages = useCallback(() => {
		for (const [topic, data] of Object.entries(updateMessages)) {
			publish(topic, data);
		}
		setUpdateMessages({});
	}, [updateMessages]);

	if (!basicInfoRequested) {
		publish(
			`microservice/${b64EncodeUnicode(user.username)}/getOwnAccountInfo`,
			JSON.stringify({ requestId: 'someId' })
		);
		publish(
			`microservice/${selectedOrganization.orgId}/${b64EncodeUnicode(username)}/getOrgInfo`,
			{
				requestId: `getOrgInfoId-${selectedOrganization.orgId}`,
				data: {},
			}
		);

		setBasicInfoRequested(true);
	}

	let currentInd;

	useEffect(() => {
		if (token) {
			try {
				const obj: {
					exp: number;
				} = JWTDecode(token);

				if (obj.exp < new Date().getTime() / 1000) {
					setTokenError(intl.formatMessage({ id: 'ConfirmPage.tokenExpired' }));
				}
			} catch (error) {
				setTokenError(intl.formatMessage({ id: 'ConfirmPage.tokenExpired' }));
			}
		}
	}, [intl, token]);

	const onDeleteConfirm = () => {
		let itemIndex = currentSubMenuItems.findIndex(
			(item: { path: string; message: string }) => item.path === currentSubMenu
		);

		if (itemIndex >= 0) {
			if (tabsData[currentTab].path === 'robotGroups') {
				publish(
					`microservice/${selectedOrganization.orgId}/${encodedUser}/deleteDeviceGroup`,
					{
						data: {
							deviceGroupId: currentSubMenuItems[itemIndex].path,
						},
						requestId: 'deleteDeviceGroupId',
					}
				);
			} else if (tabsData[currentTab].path === 'userGroups') {
				publish(
					`microservice/${selectedOrganization.orgId}/${encodedUser}/deleteUserGroup`,
					{
						data: {
							userGroupId: currentSubMenuItems[itemIndex].path,
						},
						requestId: 'deleteUserGroup',
					}
				);
			}

			setCurrentSubMenu(
				itemIndex > 0
					? currentSubMenuItems[itemIndex - 1].path
					: currentSubMenuItems.length - 1 > 0
					? currentSubMenuItems[itemIndex + 1].path
					: ''
			);
		}

		setDeleteGroupItem(false);
		setDeleteOpen(false);
	};

	const navigateToPage = (path: any) => {
		window.dispatchEvent(new Event('hashchange'));
		history.push(path);
		setActivePath(path);
	};

	useEffect(() => {
		setActivePath(location.pathname);
	}, [location.pathname]);

	useEffect(() => {
		if (deleteGroupItem) {
			setDeleteOpen(true);
		}
	}, [deleteGroupItem]);

	const onNext = () => {
		publishUpdateMessages();

		let currentInd = tabsData.findIndex((tab: any) => tab.path === tabsData[currentTab].path);
		let currentSubMenuItemIndex =
			currentSubMenuItems.findIndex((x: any) => x.path === currentSubMenu) + 1;

		if (currentInd > -1 && currentInd < tabsData.length) {
			if (currentSubMenuItemIndex === currentSubMenuItems.length) {
				if (currentSubMenuItemIndex > 1) {
					setCurrentSubMenu(currentSubMenuItems[currentSubMenuItemIndex - 2].path);
				}

				if (currentInd + 1 === tabsData.length) {
					if(spinoutType === 'beam'){
						navigateToPage('/fleetmanagement')
					}
					else{
						navigateToPage('/gobe');
					}
				} else {
					if (
						tabsData[currentInd + 1].path === 'robots' &&
						nextTabSubMenuItems.length === 0 &&
						(!!tabsData[currentInd + 1] || !!tabsData[currentInd + 2])
					) {
						if(spinoutType === 'beam'){
							navigateToPage('/fleetmanagement')
						}
						else{
							navigateToPage('/gobe');
						}
					} else if (
						tabsData[currentInd + 1].path === 'robots' &&
						nextTabSubMenuItems.length === 0
					) {
						setCurrentTab(currentInd + 2);
					} else {
						setCurrentTab(currentInd + 1);
					}

					if (nextTabSubMenuItems.length > 0) {
						setCurrentSubMenu(nextTabSubMenuItems[0].path);
					}
				}
			} else {
				setCurrentSubMenu(currentSubMenuItems[currentSubMenuItemIndex].path);
			}
		}
	};

	const onBack = () => {
		publishUpdateMessages();

		currentInd = tabsData.findIndex((tab: any) => tab.path === tabsData[currentTab].path);
		let currentSubMenuItemIndex = currentSubMenuItems.findIndex(
			(x: any) => x.path === currentSubMenu
		);
		if (currentInd > 0 && currentInd <= tabsData.length) {
			if (currentSubMenuItemIndex === -1) {
				if (
					tabsData[currentInd - 1].path === 'robots' &&
					prevTabSubMenuItems.length === 0
				) {
					setCurrentTab(currentInd - 2);
					setCurrentSubMenu('billing');
				} else {
					setCurrentTab(currentInd - 1);

					if (prevTabSubMenuItems.length > 0) {
						setCurrentSubMenu(prevTabSubMenuItems[prevTabSubMenuItems.length - 1].path);
					} else {
						setCurrentSubMenu('');
					}
				}
			} else {
				setCurrentSubMenu(currentSubMenuItems[currentSubMenuItemIndex].path);

				if (currentSubMenuItemIndex === 0) {
					if (
						tabsData[currentInd - 1].path === 'robots' &&
						prevTabSubMenuItems.length === 0
					) {
						setCurrentTab(currentInd - 2);
					} else {
						setCurrentTab(currentInd - 1);
					}

					if (prevTabSubMenuItems.length > 0) {
						setCurrentSubMenu(prevTabSubMenuItems[prevTabSubMenuItems.length - 1].path);
					}
				} else {
					//if tab don't have submenus
					if (currentSubMenuItems.length === 0) {
						setCurrentTab(currentInd - 1);
						setCurrentSubMenu(prevTabSubMenuItems[prevTabSubMenuItems.length - 1].path);
					}
					//if tab have submenus
					else {
						setCurrentSubMenu(currentSubMenuItems[currentSubMenuItemIndex - 1].path);
					}
				}
			}
		} else {
			if (currentSubMenuItems > 1) {
				setCurrentSubMenu(currentSubMenuItems.subMenus[currentSubMenuItemIndex - 1].path);
			}

			if (currentInd === 0 && currentSubMenuItemIndex > 0) {
				setCurrentSubMenu(currentSubMenuItems[currentSubMenuItemIndex - 1].path);
			}
		}
	};

	const disableButtonHandle = useCallback(value => {
		setDisableNextButtonHandle(value);
	}, []);

	const handleProfileChange = useCallback((value: any) => {
		setProfile(value);
	}, []);

	const renderTabContent = useCallback(() => {
		if (!profile) setProfile(user);
		switch (tabsData[currentTab].path) {
			case 'account':
				return (
					<AccountSetup
						currentTab={tabsData[currentTab]}
						tabsData={tabsData}
						currentSubMenu={currentSubMenu}
						onSubMenuChange={(name: string) => setCurrentSubMenu(name)}
						disableButton={disableButtonHandle}
						handleProfile={handleProfileChange}
					/>
				);
			case 'organization':
				return (
					<Organization
						currentTab={tabsData[currentTab]}
						tabsData={tabsData}
						currentSubMenu={currentSubMenu}
						onSubMenuChange={(name: string) => setCurrentSubMenu(name)}
						onUpdateMessageChange={(topic: string, data: object) => {
							setUpdateMessages({ ...updateMessages, [topic]: data });
						}}
						disableButton={disableButtonHandle}
					/>
				);
			case 'robots':
				return (
					<RobotsSetup
						tabsData={tabsData}
						currentSubMenu={currentSubMenu}
						onSubMenuChange={(name: string) => setCurrentSubMenu(name)}
						subMenuCount={currentSubMenuItems.length}
					/>
				);
			case 'robotGroups':
				return (
					<RobotGroupSetup
						tabsData={tabsData}
						currentSubMenu={currentSubMenu}
						onSubMenuChange={(name: string) => setCurrentSubMenu(name)}
						subMenuCount={currentSubMenuItems.length}
						disableButton={disableButtonHandle}
						onUpdateMessageChange={(topic: string, data: object) => {
							setUpdateMessages({ ...updateMessages, [topic]: data });
						}}
					/>
				);
			case 'userGroups':
				return (
					<UserGroupSetup
						tabsData={tabsData}
						currentSubMenu={currentSubMenu}
						onSubMenuChange={(name: string) => setCurrentSubMenu(name)}
						subMenuCount={currentSubMenuItems.length}
						onUpdateMessageChange={(topic: string, data: object) => {
							setUpdateMessages({ ...updateMessages, [topic]: data });
						}}
					/>
				);
			default:
				return null;
		}
	}, [
		currentSubMenu,
		currentSubMenuItems.length,
		currentTab,
		disableButtonHandle,
		handleProfileChange,
		profile,
		tabsData,
		updateMessages,
		user,
	]);

	return (
		<>
			<MainContent
				headerChildren={
					<>
						<IonLabel className={classes.mainTitle}>
							<FormattedMessage {...Messages.title} />
						</IonLabel>
					</>
				}
				renderContent={history => {
					return (
						<div className={classes.main}>
							{tokenError && tokenError.length > 0 ? (
								<ErrorModal
									isOpen
									onConfirm={() => props.history.push('/')}
									onDismiss={() => props.history.push('/')}
									type="token"
								/>
							) : (
								''
							)}
							<Tabs
								numberOfCurrentTab={currentTab}
								data={tabsData}
								currentTab={tabsData[currentTab].path}
								page="organizationSetup"
								content={
									<IonGrid force-overscroll className={classes.tabContentGrid}>
										<IonRow>
											<IonGrid className={classes.contentGrid}>
												<IonRow>
													<IonCol className={classes.leftSide}>
														<SubMenuComponenet
															currentTabIndex={currentTab}
															changeCurrentSubMenuHandler={(
																name: string
															) => setCurrentSubMenu(name)}
															receivedCurrentSubMenu={currentSubMenu}
															setCurrentSubMenuItems={
																setCurrentSubMenuItems
															}
															setNextTabSubMenuItems={
																setNextTabSubMenuItems
															}
															setPrevTabSubMenuItems={
																setPrevTabSubMenuItems
															}
															setDeleteGroupItem={setDeleteGroupItem}
														/>
													</IonCol>
													<IonCol className={classes.rightSide}>
														{renderTabContent()}
														{currentSubMenuItems.length < 10 && (
															<div
																className={classes.triangle}
																id="arrow"
															/>
														)}
													</IonCol>
												</IonRow>
											</IonGrid>
										</IonRow>

										<IonRow>
											<IonCol className={classes.leftCol} />
											<IonCol className={classes.buttonsColRight}>
												<IonGrid className={classes.buttonsColRight}>
													<IonRow className={classes.buttonsColRight}>
														<IonCol>
															{currentSubMenu !== 'profile' ? (
																<IonButton
																	expand="block"
																	shape="round"
																	type="submit"
																	className={classNames(
																		classes.btn,
																		classes.backBtn
																	)}
																	onClick={onBack}
																>
																	<IonIcon
																		size="small"
																		icon={chevronBack}
																	/>
																	<IonLabel>
																		{intl.formatMessage({
																			id: 'back',
																		})}
																	</IonLabel>
																</IonButton>
															) : null}
														</IonCol>

														<IonCol className={classes.btnCol}>
															<IonButton
																expand="block"
																shape="round"
																type="submit"
																className={classNames(
																	classes.btn,
																	classes.nextBtn
																)}
																disabled={disableNextButtonHandle}
																onClick={onNext}
															>
																{intl.formatMessage({
																	id: 'next',
																})}
															</IonButton>
														</IonCol>
													</IonRow>
												</IonGrid>
											</IonCol>
										</IonRow>
									</IonGrid>
								}
								tabBtnClass={classes.setupTabBtn}
							/>
						</div>
					);
				}}
			/>

			<DeleteModal
				isOpen={deleteOpen}
				title={<FormattedMessage {...Messages.deleteTitle} values={{ item: 'Group' }} />}
				onConfirm={onDeleteConfirm}
				onDismiss={() => setDeleteOpen(false)}
				itemName="this Group"
			/>
		</>
	);
};

export default injectIntl(isAuthenticated(OrganizationSetup, 'OrganizationSetup'));
