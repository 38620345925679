import { useRef, useCallback, useEffect, useState, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { unsubscribe, publish, subscribe } from 'redux-mqtt';

const getTrackIdsToKMapping = <T extends string>(
	tracks: Record<T, MediaStreamTrack | 'audio' | 'video'>
) => {
	const trackIdsToK = Object.entries<MediaStreamTrack | 'audio' | 'video'>(tracks).reduce<
		Record<string, T>
	>((acc, [k, track]) => {
		return Object.assign(acc, typeof track !== 'string' ? { [track.id]: k as T } : {});
	}, {});
	return trackIdsToK;
};

const configuration = {
	// todo: iceServers config will be received as part of config
	iceServers: [
		{ urls: 'stun:34.91.216.16:3478' },
		{
			urls: 'turn:34.91.216.16:3478',
			credential: 'zaq12wsxcde3456!',
			username: 'admin',
		},
	],
	configuration: {
		offerToReceiveAudio: true,
		offerToReceiveVideo: true,
	},
};
const getKeyToMidMapping = <T extends string>(
	peerConnection: RTCPeerConnection,
	tracksIds: Record<string, T>
): Record<T, string> => {
	const mapping = peerConnection.getTransceivers().reduce<Record<T, string>>((acc, trx) => {
		if (!trx.sender || !trx.sender.track) return acc;

		const k = tracksIds[trx.sender.track.id];
		if (k === undefined) return acc;

		return {
			...acc,
			[k]: trx.mid as string,
		};
	}, {} as Record<T, string>);
	return mapping;
};

const getMqttTopics = (robotId: string, signallingUUID: string) => {
	return {
		sub: {
			incomingICE: `${robotId}/${signallingUUID}/caller/iceCandidate`,
			incomingAnswer: `${robotId}/${signallingUUID}/answer`,
			iceRestart: `${robotId}/${signallingUUID}/restartICE`,
		},
		pub: {
			outgoingICE: `${robotId}/${signallingUUID}/robot/iceCandidate`,
			secondaryOffer: `${robotId}/${signallingUUID}/RTCOffer/secondary`,
			initialOffer: `${robotId}/RTCOffer`, // FIXME: Change this to not use the word `wideCamera` as it is misleading
		},
	};
};

/** Very simple implementation of UUID generator */
const generateGuid = () => {
	let result, i, j;
	result = '';
	for (j = 0; j < 32; j++) {
		if (j === 8 || j === 12 || j === 16 || j === 20) result = result + '-';
		i = Math.floor(Math.random() * 16)
			.toString(16)
			.toUpperCase();
		result = result + i;
	}
	return result;
};

export type ConnectionState = RTCPeerConnectionState | 'cameraAccessError';
type IRemoteAnswer<T extends string> = {
	answer: RTCSessionDescriptionInit;
	transceivers: Record<T, string>;
};
const useCallerPeerConnection = <L extends string, R extends string>(
	_callerInfo: { avatar: string; name: string },
	_robotId: string,
	onRemoteTrack: (track: MediaStreamTrack, key: R, transceiver: RTCRtpTransceiver) => void,
	onDataChannel: (dataChannel: RTCDataChannel) => void,
	_getLocalTracks: () => Promise<Record<L, MediaStreamTrack | 'audio' | 'video'>>
) => {
	const callerInfo = useRef(_callerInfo);
	const signallingUUID = useRef(generateGuid());
	const mqttTopics = useRef(getMqttTopics(_robotId, signallingUUID.current));

	const peerConnection = useMemo(() => {
		console.info('caller.peerConnection.created');
		return new RTCPeerConnection(configuration);
	}, []);
	// Kill peer connection when caller component is unmounting
	useEffect(() => {
		console.info('caller.peerConnection.cleanup.registered');

		return () => {
			peerConnection.close();
			console.info('caller.peerConnection.closed');
			console.info('caller.peerConnection.cleanup.unregistered');
		};
	}, [peerConnection]);

	// listen for connection state changes on the peer connection
	const [connectionState, setConnectionState] = useState<ConnectionState>(
		peerConnection.connectionState
	);
	useEffect(() => {
		peerConnection.onconnectionstatechange = () => {
			setConnectionState(peerConnection.connectionState);
			if (peerConnection.connectionState === 'connected') setICERestarting(false);
		};

		return () => {
			peerConnection.onconnectionstatechange = null;
		};
	}, [peerConnection]);

	const dispatch = useDispatch();

	/** A map of track ids to respective L*/
	const localTracksIds = useRef({} as Record<string, L>);
	/** A map of transceiver mid to respective R*/
	const transceiversMids = useRef<Record<string, R>>();

	const getLocalTracks = useRef(_getLocalTracks);
	const [localTracks, setLocalTracks] = useState<Array<MediaStreamTrack>>();
	// clean up the tracks when caller component is unmounting
	useEffect(() => {
		if (!localTracks) return;

		console.info('callee.cleanupTracks.registered');
		return () => {
			localTracks.forEach(track => track.stop());
			console.info('callee.cleanupTracks.called');
		};
	}, [localTracks]);

	// listen for remote track events on the peer connection
	useEffect(() => {
		peerConnection.ontrack = ({ transceiver, track }: RTCTrackEvent) => {
			const key = (transceiversMids.current as Record<string, R>)[
				transceiver.mid as string // at this point, transceiver.mid is not null
			];
			onRemoteTrack(track, key, transceiver);
			console.info(
				`caller.onTrack; trackId: ${track.id} transceiver: ${transceiver.mid}; key: ${key}`
			);
		};
		return () => {
			peerConnection.ontrack = null;
		};
	}, [onRemoteTrack, peerConnection]);

	const sendICECandidatesToRemotePeer = useCallback(
		(candidates: RTCIceCandidate | RTCIceCandidate[]) => {
			const data = Array.isArray(candidates) ? candidates : [candidates];
			data.forEach(candidate => {
				dispatch(publish(mqttTopics.current.pub.outgoingICE, { iceCandidate: candidate }));
			});
		},
		[dispatch]
	);

	// listen for local ice candidates and send them to remote peer
	const gatheredLocalICECandidates = useRef<Array<RTCIceCandidate>>([]);
	useEffect(() => {
		peerConnection.onicecandidate = (e: RTCPeerConnectionIceEvent) => {
			if (e.candidate === null) return;
			console.info(`caller.onLocalICECandidate`);

			if (hasRemoteAnswer.current === true) sendICECandidatesToRemotePeer(e.candidate);
			// store the candidates, they will be sent when we have answer
			else {
				gatheredLocalICECandidates.current = [
					...gatheredLocalICECandidates.current,
					e.candidate,
				];
			}
		};
		return () => {
			peerConnection.onicecandidate = null;
		};
	}, [dispatch, peerConnection, sendICECandidatesToRemotePeer]);

	const onNegotiationNeeded = useCallback(
		(iceRestart?: boolean) => {
			console.info('caller.onNegotiationNeeded');

			const setup = async () => {
				const offer = await peerConnection.createOffer({ iceRestart });
				console.info(`caller.createOffer; iceRestart -> ${iceRestart}`);

				await peerConnection.setLocalDescription(offer);
				console.info(`caller.setLocalDescription; iceRestart -> ${iceRestart}`);

				const localKeyToMidMapping = getKeyToMidMapping<L>(
					peerConnection,
					localTracksIds.current
				);

				if (iceRestart) {
					dispatch(
						publish(mqttTopics.current.pub.secondaryOffer, {
							offer,
							transceivers: localKeyToMidMapping,
						})
					);
				} else {
					dispatch(
						publish(mqttTopics.current.pub.initialOffer, {
							offer,
							transceivers: localKeyToMidMapping,
							signallingUUID: signallingUUID.current,
							avatar: callerInfo.current.avatar,
							name: callerInfo.current.name,
						})
					);
				}
				console.info(`caller.sendOffer; iceRestart -> ${iceRestart}`, localKeyToMidMapping);
			};
			setup().catch(err =>
				console.error(
					`Error -> caller.onNegotiationNeeded; iceRestart -> ${iceRestart}`,
					err
				)
			);
		},
		[dispatch, peerConnection]
	);

	/** HI, future  me. This piece of code is commented out cos, it is gonna fail.
	For each time that a track or transceiver or data channel is added to the peer connection,
	 an onnegotiationneeded event is fired, which will trigger our event listener multiple times.
	 But this is not the effect we desire.
	A more robust solution will be to follow the 'Perfect Negotiation' guidelines outlined here by MDN
	 https://developer.mozilla.org/en-US/docs/Web/API/WebRTC_API/Perfect_negotiation
	  */

	// // listen for negotiation needed event
	// useEffect(() => {
	// 	peerConnection.onnegotiationneeded = () => onNegotiationNeeded(undefined);
	// 	console.info('caller.eventListeners.onnegotiationneeded.registered');

	// 	return () => {
	// 		peerConnection.onnegotiationneeded = null;
	// 		console.info('caller.eventListeners.onnegotiationneeded.unregistered');
	// 	};
	// }, [onNegotiationNeeded, peerConnection]);

	// listen for request to restart ICE
	const [iceRestarting, setICERestarting] = useState(false);
	useEffect(() => {
		const topics = mqttTopics.current;
		dispatch(
			subscribe(topics.sub.iceRestart, ({ message }) => {
				if (message === 'ICE_RESTART') {
					setICERestarting(true);
					onNegotiationNeeded(true);
				} else if (message === 'INITIAL_NEGOTIATION') {
					// ignore the INITIAL_NEGOTIATION which was only implemented on the robot as a workaround
					console.log('caller.onMessage.iceRestart.INITIAL_NEGOTIATION ignored');
				}
			})
		);
		console.info('caller.onICERestart.listener.registered');

		return () => {
			dispatch(unsubscribe(topics.sub.iceRestart));
			console.info('caller.onICERestart.listener.unregistered');
		};
	}, [dispatch, onNegotiationNeeded]);

	// listen for remote ICE candidates
	useEffect(() => {
		const topics = mqttTopics.current;
		dispatch(
			subscribe(topics.sub.incomingICE, ({ message }) => {
				const { iceCandidate } = message as { iceCandidate: RTCIceCandidate };
				peerConnection
					.addIceCandidate(iceCandidate)
					.then(() => console.info('caller.onRemoteICECandidate'))
					.catch(error => console.error('Error -> caller.onRemoteICECandidate', error));
			})
		);
		console.info('caller.onRemoteICECandidate.listener.registered');
		return () => {
			dispatch(unsubscribe(topics.sub.incomingICE));
			console.info('caller.onRemoteICECandidate.listener.unregistered');
		};
	}, [dispatch, peerConnection]);

	// listen for answer from remote peer
	const hasRemoteAnswer = useRef(false);
	useEffect(() => {
		const onRemoteAnswer = ({ message }: { message: unknown }) => {
			console.info('caller.onRemoteAnswer');
			const data = message as IRemoteAnswer<R>;
			if (data.answer.type !== 'answer') {
				console.error(`caller.onRemoteAnswer Invalid SDP type '${data.answer.type}'`);
				return;
			}

			transceiversMids.current = Object.fromEntries(
				Object.entries(data.transceivers).map(([key, mid]) => [mid as string, key as R])
			);
			peerConnection
				.setRemoteDescription(data.answer)
				.then(() => {
					console.info('caller.setRemoteDescription'); // we've set the remote description

					const iceCandidates = gatheredLocalICECandidates.current;
					hasRemoteAnswer.current = true;
					gatheredLocalICECandidates.current = [];
					sendICECandidatesToRemotePeer(iceCandidates);
				})
				.catch(error => console.error(`Error -> caller.setRemoteDescription`, error));
		};

		const topics = mqttTopics.current;
		dispatch(subscribe(topics.sub.incomingAnswer, onRemoteAnswer));
		console.info('caller.onRemoteAnswer.listener.registered');

		return () => {
			dispatch(unsubscribe(topics.sub.incomingAnswer));
			console.info('caller.onRemoteAnswer.listener.unregistered');
		};
	}, [dispatch, peerConnection, sendICECandidatesToRemotePeer]);

	useEffect(() => {
		// if no connection has been established within 10 seconds, try to send a new offer
		const id = setTimeout(() => {
			if (hasRemoteAnswer.current === true) return;
			onNegotiationNeeded(true);
		}, 10 * 1000);

		return () => {
			clearTimeout(id);
		};
	}, [onNegotiationNeeded]);

	// add tracks to the peer connection. This will trigger onNegotiationNeeded
	const init = useCallback(() => {
		const setup = async () => {
			const tracks = await getLocalTracks.current();
			localTracksIds.current = getTrackIdsToKMapping<L>(tracks);
			setLocalTracks(
				Object.values<MediaStreamTrack | 'video' | 'audio'>(tracks).filter(track => {
					return track !== 'audio' && track !== 'video';
				}) as Array<MediaStreamTrack>
			);
			console.info('caller.getLocalTracks');

			/** transceivers that are not bound to a track */
			const otherVideoTransceivers: Array<RTCRtpTransceiver> = [];
			const tracksArr = Object.values<MediaStreamTrack | 'video' | 'audio'>(tracks);
			tracksArr.forEach(track => {
				if (track !== 'audio' && track !== 'video') peerConnection.addTrack(track);
				else {
					const transceiver = peerConnection.addTransceiver(track, {
						direction: 'sendrecv',
					});
					if (track === 'video') otherVideoTransceivers.push(transceiver);
					console.info('caller.peerConnection.addTransceiver');
				}
			});

			const supportsSetCodecPreferences =
				window.RTCRtpTransceiver &&
				'setCodecPreferences' in window.RTCRtpTransceiver.prototype;
			if (supportsSetCodecPreferences) {
				const { codecs } = RTCRtpSender.getCapabilities('video') as RTCRtpCapabilities;
				const rearrangedCodecs = [
					...codecs.filter(codec => codec.mimeType === 'video/H264'),
					...codecs.filter(codec => codec.mimeType === 'video/VP9'),
					...codecs.filter(
						codec => !['video/H264', 'video/VP9'].includes(codec.mimeType)
					),
				];

				tracksArr.forEach(track => {
					if (typeof track !== 'string' && track.kind === 'video') {
						const transceiver = peerConnection
							?.getTransceivers()
							.find(t => t.sender && t.sender.track === track);
						transceiver?.setCodecPreferences(rearrangedCodecs);
					}
				});
				otherVideoTransceivers.forEach(transceiver =>
					transceiver.setCodecPreferences(rearrangedCodecs)
				);
			} else {
				console.log('Unfortunately, specifying preferred codec is not supported');
			}

			console.info('caller.peerConnection.addTracks');

			const dataChannels = await Promise.all([
				peerConnection.createDataChannel('nav-datachannel'),
				peerConnection.createDataChannel(
					Math.random()
						.toString()
						.substr(2)
				),
			]);

			return dataChannels;
		};

		setup()
			.then(datachannels => datachannels.forEach(datachannel => onDataChannel(datachannel)))
			.then(() => onNegotiationNeeded(undefined))
			.catch(err => {
				console.error('Error -> caller.initialSetup.failed', err);
				setConnectionState('cameraAccessError');
			});
	}, [onDataChannel, onNegotiationNeeded, peerConnection]);
	useEffect(init, []);

	return { connectionState, iceRestarting, signallingUUID: signallingUUID.current };
};

export default useCallerPeerConnection;
