import React, { useCallback, useState, useEffect } from 'react';
import Battery from '../../../components/battery';
import './index.scss';
import { PropsFromParent } from './model';
import BatteryDetails from '../batteryDetails';
import { ConnectedProps, useDispatch } from 'react-redux';
import { setParameter } from '../../../actions/setParam';
import { connect } from 'react-redux';
import { AppRootState, useTypedSelector } from '../../../../../../reducers';
import { useHistory } from 'react-router-dom';
import { SET_ROBOT_ID, SET_ROBOT_NAME } from '../../../actions/types';
import { equalityFnc, itemConverter } from '../../../utils/deviceStateConverter';
import {
	statusIconConverter,
	statusTextConverter,
	usedStatus,
} from '../../../utils/statusConverter';
import { getStateEntityByOrgId } from '../../../../../../utils/conformState';
import updatedRobotsSelector from '../../../utils/updatedRobotsSelector';
import updatedRobots from '../../../updatedRobots.json';

const GoBeListIcon: React.FC<PropsFromParent> = ({
	deviceId,
	orgId,
	changeAgreementStatus,
	agreementsStatus,
	acceptedAgreementsStatus,
}) => {
	const item: any = useTypedSelector(
		state =>
			getStateEntityByOrgId(state, 'accountState', {
				entityId: deviceId,
				orgId: orgId,
				propertyOptions: [
					'online',
					'status',
					'battery',
					'currentState',
					'name',
					'groupName',
					'battery',
					'location',
				],
			}),
		(left, right) => equalityFnc(left, right)
	);

	const dispatch = useDispatch();
	const history = useHistory();

	// let usedStatus = useCallback(() => {
	// 	if (item.currentState) {
	// 		return item.currentState;
	// 	} else {
	// 		return JSON.parse(item.status)?.currentState;
	// 	}
	// }, [item.currentState, item.status]);

	const onStartSession = useCallback(async () => {
		if (item.online) {
			if (usedStatus(item) === 'available') {
				await dispatch(setParameter('robotId', SET_ROBOT_ID, deviceId));
				await dispatch(setParameter('robotName', SET_ROBOT_NAME, item.name));
				if (acceptedAgreementsStatus === false) {
					await changeAgreementStatus(true);
				} else {
					history.push('/gobe/session');
					// if (updatedRobotsSelector(updatedRobots, deviceId)) {
					// 	history.push('/gobe/session');
					// } else {
					// 	history.push('/gobe/session-legacy');
					// }
				}
			}
		}
	}, [acceptedAgreementsStatus, changeAgreementStatus, deviceId, dispatch, history, item]);

	if (item) {
		return (
			<div
				className={
					item.online ? 'oneGoBeListContainer' : 'oneGoBeListContainer oneGoBeListOffline'
				}
			>
				<div className="oneGoBeListName">
					<div
						className={
							item.online
								? usedStatus(item) === 'available'
									? 'playGoBeListContainer'
									: 'playGoBeListContainer cursorDefault'
								: 'playGoBeListContainer playGoBeListOffline'
						}
						onClick={() => onStartSession()}
					>
						<div className="playListCircle">
							<div className="statusIconWrapper">
								<img
									src={`../../assets/images/${
										item.online
											? usedStatus(item) === 'available'
												? 'play-circle.svg'
												: `${statusIconConverter(usedStatus(item))}`
											: 'grey-offline.svg'
									}`}
								/>
							</div>
						</div>
						<div className="goBeStatusDetail">
							<div className="goBeStatusDetailText">
								{statusTextConverter(usedStatus(item))}
							</div>
							<div className="goBeStatusDetailTriangle" />
						</div>
					</div>
					{item.name}
				</div>
				<div className="goBeBatteryCharge">
					<div
						className={
							item.online
								? 'batteryListRoaster'
								: 'batteryListRoaster batteryListRoasterOff'
						}
					>
						<Battery
							batteryPercent={item.battery ? item.battery.level : 40}
							online={item.online}
							charging={item.battery ? item.battery.charging : false}
						/>{' '}
						<div className="batteryDetailsWrapper">
							<BatteryDetails
								batteryPercent={item.battery ? item.battery.level : 40}
								charging={item.battery ? item.battery.charging : false}
							/>{' '}
						</div>
					</div>
					<div className="batteryListText">{item.remainBattery}</div>
				</div>
				<div className="goBeListItem">{item.groupName}</div>
				<div className="goBeListItem">{item.location}</div>
				<div className="goBeListItem">{item.lastSeen}</div>
				<div className="goBeListItem statusPlay">
					<div className={item.online ? 'statusText' : 'statusText statusTextOffline'}>
						{item.online ? statusTextConverter(usedStatus(item)) : 'offline'}
					</div>
					<div className="playOfflineContainer">
						<div
							className={
								item.online
									? item.currentState === 'available'
										? 'greenPlayWrapper'
										: 'greenPlayWrapper cursorDefault'
									: 'greenPlayWrapper cursorDefault'
							}
							onClick={() => onStartSession()}
						>
							<img
								src={`../assets/images/${
									item.online
										? statusTextConverter(usedStatus(item)) === 'Available'
											? 'green-play.svg'
											: 'grey-play.svg'
										: 'grey-offline.svg'
								}`}
							/>
						</div>
					</div>
				</div>
			</div>
		);
	} else {
		return <div />;
	}
};

export default GoBeListIcon;
