export const usedStatus = (item: any) => {
	if (item.currentState) {
		return item.currentState;
	} else {
		if (item.status && JSON.parse(item.status)?.currentState) {
			return JSON.parse(item.status)?.currentState;
		} else {
			return 'unavailable';
		}
	}
};

export const statusIconConverter = (usedStatus: any) => {
	switch (usedStatus) {
		case 'available':
			return 'available';
		case 'reserved':
			return 'grey-calendar.svg';
		case 'onHold':
			return 'grey-pause.svg';
		case 'configuring':
			return 'grey-config.svg';
		case 'inSession':
			return 'grey-headset.svg';
		case 'unavailable':
			return 'grey-minimize.svg';
		case 'updating':
			return 'grey-cloud-download.svg';
	}
};

export const statusTextConverter = (usedStatus: any) => {
	switch (usedStatus) {
		case 'available':
			return 'Available';
		case 'reserved':
			return 'Reserved';
		case 'onHold':
			return 'On Hold';
		case 'configuring':
			return 'Configuring';
		case 'inSession':
			return 'In Session';
		case 'unavailable':
			return 'Unavailable';
		case 'updating':
			return 'Updating';
	}
};
