import React, { useState } from 'react';
import './index.scss';
import { PropsFromParent } from './model';

const FailedSessionConnection: React.FC<PropsFromParent> = ({
	isExpanded,
	showAbsoluteMenu,
	minimized,
	setMinimized,
	showStatus,
}) => {
	return minimized ? (
		<div
			className={
				showStatus
					? showAbsoluteMenu
						? isExpanded
							? 'failedSessionConnectionCircle failedShowAbsoluteMenuExpand'
							: 'failedSessionConnectionCircle failedShowAbsoluteMenu'
						: 'failedSessionConnectionCircle'
					: showAbsoluteMenu
					? isExpanded
						? 'failedSessionConnectionCircle failedShowAbsoluteMenuExpand displayNone'
						: 'failedSessionConnectionCircle failedShowAbsoluteMenu displayNone'
					: 'failedSessionConnectionCircle displayNone'
			}
			onClick={() => setMinimized(false)}
		>
			<div className="warningIconWrapper">
				<img src="../../assets/images/green-warning.svg" alt="" />
			</div>
		</div>
	) : (
		<div
			className={
				showStatus
					? showAbsoluteMenu
						? isExpanded
							? 'failedSessionConnectionContainer failedShowAbsoluteMenuExpand'
							: 'failedSessionConnectionContainer failedShowAbsoluteMenu'
						: 'failedSessionConnectionContainer'
					: showAbsoluteMenu
					? isExpanded
						? 'failedSessionConnectionContainer failedShowAbsoluteMenuExpand displayNone'
						: 'failedSessionConnectionContainer failedShowAbsoluteMenu displayNone'
					: 'failedSessionConnectionContainer displayNone'
			}
		>
			<div className="headingContainer">
				<div className="warningIconWrapper">
					<img src="../../assets/images/green-warning.svg" alt="" />
				</div>
				<div className={'impairedHeading'}>Driving Impaired</div>
				<div className="closeCircleIconWrapper" onClick={() => setMinimized(true)}>
					<img src="../../assets/images/grey-close-circle.svg" alt="" />
				</div>
			</div>
			<div className="textSessionConnectionContainer">
				The driving speed has been limited due to network problems or your device being
				under a high load.
			</div>
		</div>
	);
};

export default FailedSessionConnection;
