import React, { useRef, useEffect, useState } from 'react';
import './index.scss';
import { setParameter } from '../../../actions/setParam';
import { SET_BATTERY_STATUS, SET_REMOVE_REMOTE, SET_WIFI_STATUS } from '../../../actions/types';
import { connect } from 'react-redux';
import WifiStatus from '../wifiStatus';
import BatteryStatus from '../batteryStatus';
import Battery from '../../../components/battery';
import { ConnectedProps } from 'react-redux';
import { AppRootState } from '../../../../../../reducers';
import { unsubscribe, publish, subscribe } from 'redux-mqtt';
import { useDispatch } from 'react-redux';
import { PropsFromParent } from './model';

const reduxConnector = connect(
	(state: AppRootState) => ({
		localVideoStatus: state.goBeState.sessionState.localVideoStatus,
		wifiStatus: state.goBeState.sessionState.wifiStatus,
		batteryStatus: state.goBeState.sessionState.batteryStatus,
		robotId: state.goBeState.sessionState.robotId,
	}),
	{ setParameter, subscribe, unsubscribe }
);

type PropsFromRedux = ConnectedProps<typeof reduxConnector>;
type ComponentProps = PropsFromRedux & PropsFromParent;

const WifiBattery: React.FC<ComponentProps> = ({
	setParameter,
	localVideoStatus,
	wifiStatus,
	batteryStatus,
	robotId,
	startWideCameraStats,
	stopWideCameraStats,
	wideCameraStats,
}) => {
	const [batteryDetails, changeBatteryDetails] = useState({ level: '40', charging: false });

	const onRemoveClick = () => {
		setParameter('localVideoStatus', SET_REMOVE_REMOTE, !localVideoStatus);
	};
	const onWifiClick = () => {
		if (wifiStatus) {
			stopWideCameraStats();
		} else {
			startWideCameraStats();
		}
		setParameter('wifiStatus', SET_WIFI_STATUS, !wifiStatus);
	};
	const onBatteryClick = () => {
		setParameter('batteryStatus', SET_BATTERY_STATUS, !batteryStatus);
	};
	const dispatch = useDispatch();

	const batteryStatusTopic = `${robotId}/status/update`;

	useEffect(() => {
		dispatch(
			subscribe(batteryStatusTopic, ({ message }: any) => {
				changeBatteryDetails(message.data.battery);
			})
		);
	}, [batteryStatusTopic, dispatch]);

	useEffect(() => {
		return () => {
			dispatch(unsubscribe(batteryStatusTopic));
		};
	}, [batteryStatusTopic, dispatch]);

	return (
		<div
			className={
				localVideoStatus
					? ' wifiBatteryContainer'
					: 'miniWifiBatteryContainer wifiBatteryContainer'
			}
		>
			<div className="removeIconWrapper" onClick={onRemoveClick}>
				<img
					src={`../../assets/images/${
						localVideoStatus ? 'remove.svg ' : 'GoBe-head.svg'
					}`}
				/>
			</div>
			<div
				className="wifiIconWrapper"
				onMouseEnter={() => startWideCameraStats()}
				onMouseLeave={() => stopWideCameraStats()}
			>
				<img src="../../assets/images/wifi.svg" />
			</div>
			{/* <div className="wifiStatusWrapper">
				<WifiStatus wideCameraStats={wideCameraStats} />
			</div> */}
			<div className="batteryIconContainer">
				<Battery
					onClick={onBatteryClick}
					batteryPercent={batteryDetails.level}
					charging={batteryDetails.charging}
					online
				/>
			</div>
			{/* <div className="batteryStatusWrapper">
				<BatteryStatus
					batteryPercent={batteryDetails.level}
					charging={batteryDetails.charging}
				/>
			</div> */}
		</div>
	);
};

export default reduxConnector(WifiBattery);
