import React, { useState, useEffect } from 'react';
import { Route, Switch, Redirect, BrowserRouter, HashRouter } from 'react-router-dom';
import { IonApp, isPlatform, IonSplitPane, IonLoading, IonPage } from '@ionic/react';
import { IntlProvider } from 'react-intl';
import DirectionProvider from 'react-with-direction/dist/DirectionProvider';
import flatten from 'flat';

import Menu from './components/Menu/Menu';
import Home from './pages/Home/Home';
import LoginPage from './pages/Login/LoginPage';
import AccountCreatePage from './pages/AccountCreate/AccountCreate';
import AccountCreateConfirmPage from './pages/AccountCreate/AccountCreateConfirm';
import ForgotPasswordPage from './pages/ForgotPassword/ForgotPasswordPage';
import DashboardPage from './pages/Dashboard/DashboardPage';
import FleetManagementPage from './pages/FleetManagement/FleetManagement';
import HelpcenterPage from './pages/Helpcenter/HelpcenterPage';
import SettingPage from './pages/Settings/SettingsPage';
import ResetPassword from './pages/SetPassword/SetPassword';
import AcceptInvitePage from './pages/AcceptInvite/AcceptInviteAcceptPage';
import ConfirmAccountPage from './pages/AcceptInvite/AcceptInviteConfirmPage';
import SetLanguage from './pages/SetLanguage/SetLanguage';
import Agreements from './pages/Agreements/Agreements';
import OrganizationSetup from './pages/OrganizationSetup/OrganizationSetupPage';
import AccountSetup from './pages/AccountSetup/AccountSetup';
import locales from './translation/locales';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import './theme/app.css';

/* Platform specifics */
import GoBeApp from './SubApp/gb_operator_module/src/App';
import MainContent from './components/MainContent/MainContent';
import { useTypedSelector } from './reducers';
import { store } from './store/store';
import { APPLICATION_VERSION } from './actions/types';
import { activeFeatureFlags } from './config/FeatureFlags';

const Router: any = isPlatform('electron') ? HashRouter : BrowserRouter;
const codes: any = locales.codes;
const messages: any = {
	de: locales.de,
	en: locales.en,
	da: locales.da,
	ar: locales.ar,
	zh: locales.zh,
};
const defaultLanguage = 'english';

const App = (props: any) => {
	const userLanguage = useTypedSelector(state => state.accountState.user.language);
	const language = codes[userLanguage ? userLanguage : defaultLanguage];
	const [showLoading, setShowLoading] = useState(false);

	const fetchData = useTypedSelector(state => state.fetchDataState);

	useEffect(() => {
		fetch('/version.json').then(response => {
			response.json().then(data => {
				let initialState = {
					version: '',
					platform: '',
					spinoutType: '',
				};

				(window as any).version = data.version;

				initialState.version = data.version;
				initialState.platform = data.platform;
				initialState.spinoutType = data.spinout;

				store.dispatch({
					type: APPLICATION_VERSION,
					payload: initialState,
				});
			});
		});
	});

	const spinoutType = useTypedSelector(state => state.versionState.spinoutType) as string;

	useEffect(() => {
		setShowLoading(fetchData.showLoader);
	}, [fetchData.showLoader]);

	useEffect(() => {
		let link = document.getElementById('jsd-widget');
		if (
			link &&
			(window.location.pathname === '/' ||
				window.location.pathname.includes('/confirm-organization') ||
				window.location.pathname.includes('/confirm-user') ||
				window.location.pathname.includes('/confirm-account') ||
				window.location.pathname.includes('/accept-invite') ||
				window.location.pathname.includes('/agreements') ||
				window.location.pathname.includes('/account-create') ||
				window.location.pathname.includes('/account-created-confirmation') ||
				window.location.pathname.includes('/gobe/session') ||
				window.location.pathname.includes('/reset-password/') ||
				spinoutType !== 'gobe')
		) {
			link.style.display = 'none';
		} else if (link && link.style.display !== 'block') {
			link.style.display = 'block';
		}
	});

	return (
		<IntlProvider locale={language} messages={flatten(messages[language])}>
			<DirectionProvider direction={messages[language].Direction}>
				<IonApp>
					<IonLoading
						cssClass="my-custom-class"
						isOpen={showLoading}
						onDidDismiss={() => setShowLoading(false)}
						message="Grabbing Information..."
					/>
					<Router>
						<Switch>
							<Route path="/" component={LoginPage} exact />
							<Route path="/forgotpassword" component={ForgotPasswordPage} exact />
							<Route path="/setlanguage" component={SetLanguage} exact />
							<Route path="/confirm-account/:token" component={SetLanguage} exact />
							<Route
								path="/accept-invite/:token"
								component={AcceptInvitePage}
								exact
							/>
							<Route path="/reset-password/:token" component={ResetPassword} exact />
							<Route
								path="/agreements/:language/:token"
								component={Agreements}
								exact
							/>
							<Route
								path="/confirm-user/:token"
								component={ConfirmAccountPage}
								exact
							/>
							<Route
								path="/confirm-organization/:token"
								component={SetLanguage}
								exact
							/>
							{activeFeatureFlags.PUBLIC_ACCOUNT_CREATION && (
								<Route
									path="/account-create/:beamToken?"
									component={AccountCreatePage}
									exact
								/>
							)}
							{activeFeatureFlags.PUBLIC_ACCOUNT_CREATION && (
								<Route
									path="/account-created-confirmation/:token"
									component={AccountCreateConfirmPage}
									exact
								/>
							)}

							<Route>
								{/* <div style={{width: '100vw', height: '100vh', backgroundColor: '#f0f', position: 'absolute'}}></div> */}
								<IonSplitPane contentId="main">
									<Menu />
									<IonPage id="main">
										<Switch>
											<Route
												path="/fleetManagement"
												component={FleetManagementPage}
												exact
											/>
											{spinoutType !== 'beam' && (
												<Route path="/gobe" exact>
													<MainContent
														title=""
														renderContent={(history: any) => {
															return <GoBeApp />;
														}}
													/>
												</Route>
											)}
											<Route
												path="/organization-setup/:token?"
												component={OrganizationSetup}
												exact
											/>
											<Route
												path="/account-setup/:token?"
												component={AccountSetup}
												exact
											/>
											{/* <Route path="/home" component={Home} exact /> */}
											{/* <Route
												path="/dashboard"
												component={DashboardPage}
												exact
											/> */}

											{spinoutType !== 'beam' && (
												<Route
													path="/helpcenter"
													component={HelpcenterPage}
													exact
												/>
											)}
											<Route path="/settings" component={SettingPage} exact />
											<Route
												render={() => {
													if (
														window.location.pathname.split('/')[1] ===
														'gobe'
													)
														return <Redirect to="/gobe" />;
												}}
											/>
											<Route
												render={() => {
													return <Redirect to="/" />;
												}}
											/>
										</Switch>
									</IonPage>
								</IonSplitPane>
							</Route>
						</Switch>
					</Router>
				</IonApp>
			</DirectionProvider>
		</IntlProvider>
	);
};

export default App;
