import { MqttClient } from 'mqtt';
import { RTCRtpStreamStatsSender } from '../rtcStats';
import Signaling, {
	ICEPayload,
	SignalingErrorACKNotAllowedBusy,
	SignallingErrorACKTimeout,
} from './signalling';

export type PeerConnectionEndReasonCode =
	| 'NOT_ALLOWED_BUSY'
	| 'NO_ACK'
	| 'PEER_HANGUP'
	| 'LOCAL_HANGUP'
	| 'CLEANUP'
	| 'PERMISSION_ERROR'
	| 'ERROR';

type LocalTrackKey = 'pilotVideo' | 'pilotAudio';
export type RemoteTrackKey = 'wideCamVideo' | 'navCamVideo' | 'robotAudio';
const remoteTracksMidsMap: Record<string, RemoteTrackKey> = {
	video0: 'wideCamVideo',
	video1: 'navCamVideo',
	audio2: 'robotAudio',
};

export const NAV_DATACHANNEL_LABEL = 'nav-datachannel';
export const NON_NAV_DATACHANNEL_LABEL = 'non-nav-datachannel';
export const NON_NAV_DATACHANNEL_LABEL__LEGACY = 'other-datachannel';

/** Simple UUID generator.
 * This serves the purpose of generating a random sequence of numbers;
 * a third-party package is not necessary */
const generateGuid = () => {
	let result, i, j;
	result = '';
	for (j = 0; j < 32; j++) {
		if (j === 8 || j === 12 || j === 16 || j === 20) result = result + '-';
		i = Math.floor(Math.random() * 16)
			.toString(16)
			.toUpperCase();
		result = result + i;
	}
	return result;
};

/**
 * Removes characters that are not compliant with mqtt topics rules - plus(+) and hash (#)
 * @param {string} username
 */
const normalizeRawUsername = (username: string) => {
	return username.replace('+', '.').replace('#', '.');
};

const STATS_SAMPLING_PERIOD_MS = 200;
export default class PeerConnectionWithSignalling {
	private _uuid: string;
	// private rtpStreamStatsSender: RTCRtpStreamStatsSender<LocalTrackKey | RemoteTrackKey>;

	private signallingClient: Signaling;
	private pc: RTCPeerConnection | undefined;

	private nonNavDatachannel: RTCDataChannel | undefined;
	private remoteMediaTracks: Array<MediaStreamTrack> = [];
	private localMediaTracks: Array<MediaStreamTrack> = [];

	public onTrack:
		| ((track: MediaStreamTrack, key: RemoteTrackKey, transceiver: RTCRtpTransceiver) => void)
		| null;
	public onStarted: (() => void) | null;
	public onEnded: ((reason?: PeerConnectionEndReasonCode) => void) | null;
	public onDataChanel: ((datachannel: RTCDataChannel) => void) | null;
	public onConnectionStateChange: ((connectionState: RTCPeerConnectionState) => void) | null;

	constructor(
		_localId: string,
		peerId: string,
		localInfo: { name: string; avatar?: string },
		mqttClient: MqttClient
	) {
		this.onTrack = (tr, k, trx) => console.warn('Unhandled callback onTrack', tr, k, trx);
		this.onStarted = () => console.warn('Unhandled callback onStarted');
		this.onEnded = r => console.warn('Unhandled callback onEnded', r);
		this.onDataChanel = d => console.warn('Unhandled callback onDataChanel', d);
		this.onConnectionStateChange = s =>
			console.warn('Unhandled callback onConnectionStateChange', s);

		this._uuid = generateGuid();

		const normalizedLocalId = normalizeRawUsername(_localId);

		// this.rtpStreamStatsSender = new RTCRtpStreamStatsSender(
		// 	STATS_SAMPLING_PERIOD_MS,
		// 	mqttClient,
		// 	{
		// 		localId: normalizedLocalId,
		// 		peerId,
		// 		uuid: this.uuid,
		// 	}
		// );

		this.signallingClient = new Signaling(
			mqttClient,
			this._uuid,
			normalizedLocalId,
			peerId,
			localInfo
		);
		this.signallingClient.onRemoteSDP = this.onPeerSDP.bind(this);
		this.signallingClient.onRemoteICE = this.onPeerICE.bind(this);
		this.signallingClient.onRemoteHangUp = this.onRemoteHangUp.bind(this);

		this.start = this.start.bind(this);
		this.end = this.end.bind(this);
		this.pause = this.pause.bind(this);
		this.unpause = this.unpause.bind(this);
		this.promptIceRestart = this.promptIceRestart.bind(this);
		// this.setVolume = this.setVolume.bind(this);
		// this.setStatusMessage = this.setStatusMessage.bind(this);
	}

	/** Initialize the peer connection, add tracks, and attach callbacks  */
	private async setupPeerConnection(iceServersConfig: RTCIceServer, stream: MediaStream) {
		const peerConnection = new RTCPeerConnection({
			bundlePolicy: 'max-bundle',
			iceServers: [iceServersConfig],
			iceTransportPolicy: 'relay',
		});
		const videoSenders: Array<RTCRtpSender> = [];
		for (const track of stream.getTracks()) {
			const sender = peerConnection.addTrack(track, stream);
			if (track.kind === 'video') {
				videoSenders.push(sender);
				// this.rtpStreamStatsSender.setStatsSource('pilotVideo', track);
			} else if (track.kind === 'audio') {
				// this.rtpStreamStatsSender.setStatsSource('pilotAudio', track);
			}
		}

		// Set preferred params on RTCRtpSender for video
		for (let i = 0; i < videoSenders.length; i++) {
			const sender = videoSenders[i];
			try {
				const params = await sender.getParameters();
				const updatedParams = {
					...params,
					encodings: params.encodings.map(encoding => ({
						...encoding,
						maxBitrate: 0.5 * 10 ** 6, // in bits per second
					})),
					degradationPreference: 'maintain-resolution',
					...({ priority: 'high' } as any),
				};
				await sender.setParameters(updatedParams);
			} catch (error) {
				console.warn(`Error -> peerConnection.transceiver.sender.setParameters`, error);
			}
		}

		const supportsSetCodecPreferences =
			window.RTCRtpTransceiver && 'setCodecPreferences' in window.RTCRtpTransceiver.prototype;
		if (supportsSetCodecPreferences) {
			const { codecs } = RTCRtpSender.getCapabilities('video') as RTCRtpCapabilities;
			console.log('Supported Codecs ', codecs);
			const rearrangedCodecs = [
				...codecs.filter(codec => codec.mimeType === 'video/VP8'),
				...codecs.filter(codec => codec.mimeType === 'video/H264'),
				...codecs.filter(codec => !['video/H264', 'video/VP8'].includes(codec.mimeType)),
			];
			const transceiver = peerConnection
				.getTransceivers()
				.find(t => t.sender && t.sender.track === stream?.getVideoTracks()[0]);
			if (transceiver) {
				transceiver.setCodecPreferences(rearrangedCodecs);
				console.log('Codec preferences has been set on transceiver');
			} else {
				console.log('transceiver has not been set up on peer connection yet');
			}
		} else {
			console.warn('Unfortunately, specifying preferred codec is not supported');
		}

		peerConnection.ontrack = this._onTrack.bind(this);
		peerConnection.onicecandidate = this.onLocalICE.bind(this);
		peerConnection.onconnectionstatechange = this._onConnectionStateChange.bind(this);
		peerConnection.onicegatheringstatechange = this._onICEGatheringStateChange.bind(this);
		peerConnection.ondatachannel = this._onDataChannel.bind(this);

		this.pc = peerConnection;
	}

	public get connectionState() {
		return this.pc?.connectionState || 'new';
	}

	public get uuid() {
		return this._uuid;
	}

	public async start(stream: MediaStream, initialVolume: number) {
		try {
			const iceServers = await this.signallingClient.hello(initialVolume);
			console.log('ICE_SERVERS', iceServers);
			await this.setupPeerConnection(iceServers, stream);
			// let the peer know that we are ready for initial offer
			await this.signallingClient.sendREADY().then(this.onStarted);
			// this.rtpStreamStatsSender.start(this.pc!);
		} catch (error) {
			// the remote peer did not respond with an OK :(
			console.error('peerConnection.start', error);
			// there was an error with initial signalling stage
			let reason: PeerConnectionEndReasonCode = 'ERROR';
			if (error instanceof SignalingErrorACKNotAllowedBusy)
				// peer is busy and cannot accept call
				reason = 'NOT_ALLOWED_BUSY';
			else if (error instanceof SignallingErrorACKTimeout)
				// peer did not reply at all (within a certain timeout)
				reason = 'NO_ACK';
			this.end(reason);
		}
	}

	public end(reason: PeerConnectionEndReasonCode = 'LOCAL_HANGUP') {
		console.debug('peerConnection.end', reason);
		// this.rtpStreamStatsSender.stop();
		// notify the remote peer that we are hanging up
		this.signallingClient.hangUp().catch(console.error);
		this.pc?.close();
		this.pc = undefined;
		// we no longer expect media to be played from the remote peer
		this.remoteMediaTracks.forEach(track => track.stop());
		this.remoteMediaTracks = [];
		// NB: We don't end/stop the local tracks in this module.
		// We leave it to the creator of the tracks to end/stop when it deems fit
		this.localMediaTracks = [];
		this.onEnded && this.onEnded(reason);
	}

	private onRemoteHangUp() {
		this.end('PEER_HANGUP');
	}

	/** Pause the peer connection.
	 * The remote is notified of the pause, and no media is sent or played-from the remote peer
	 */
	public pause() {
		try {
			this.nonNavDatachannel?.send('SESSION PAUSE');
		} catch (error) {
			console.error("Unable to send 'SESSION PAUSE' message via datachannel", error);
		}
		// mute both remote and local media
		this.remoteMediaTracks.forEach(track => (track.enabled = false));
		this.localMediaTracks.forEach(track => (track.enabled = false));
	}

	/** Resume the peer connection from a prior paused state.
	 * The remote is notified of the resumption, and media sent or played-from the remote peer
	 */
	public unpause() {
		try {
			this.nonNavDatachannel?.send('SESSION UNPAUSE');
		} catch (error) {
			console.error("Unable to send 'SESSION UNPAUSE' message via datachannel", error);
		}
		// unmute both remote and local media
		this.remoteMediaTracks.forEach(track => (track.enabled = true));
		this.localMediaTracks.forEach(track => (track.enabled = true));
	}

	// NB: For now, these functions will not be exposed.
	// Rather, the caller component, will directly call datachannel.send in the appropriate places
	// The ideal implementation will be to have all of such functions exposed from this class.

	// /** Set the perceived volume of our audio on the remote peer's end  */
	// public setVolume(value: Number) {
	// 	try {
	// 		this.nonNavDatachannel?.send(`VOL ${value}`);
	// 	} catch (error) {
	// 		console.error(`Unable to send 'VOL ${value}' message via datachannel`, error);
	// 	}
	// }

	// /** Send status message to the remote peer */
	// public setStatusMessage(message: String) {
	// 	try {
	// 		this.nonNavDatachannel?.send(`MSG ${message}`);
	// 	} catch (error) {
	// 		console.error(`Unable to send 'MSG ${message}' message via datachannel`, error);
	// 	}
	// }

	private _onTrack(e: RTCTrackEvent) {
		console.debug('peerConnection.pc.onTrack', e);
		const key = remoteTracksMidsMap[e.transceiver.mid!];
		if (key === undefined) {
			console.error(
				'Invalid mid',
				`mid '${e.transceiver.mid}' does not correspond to any RemoteTrackKey`
			);
			return;
		}
		this.remoteMediaTracks.push(e.track);
		this.onTrack && this.onTrack(e.track, key, e.transceiver);

		// notify the statistics sender that a remote media track is available
		// this.rtpStreamStatsSender.setStatsSource(key, e.track);
	}

	private _onDataChannel(ev: RTCDataChannelEvent) {
		console.info('ondatachannel', ev);
		/** Labels of the other datachannel, which is not used for navigation-related stuff */
		const nonNavLabels = [NON_NAV_DATACHANNEL_LABEL, NON_NAV_DATACHANNEL_LABEL__LEGACY];
		if (nonNavLabels.includes(ev.channel.label)) {
			this.nonNavDatachannel = ev.channel;
		}
		this.onDataChanel && this.onDataChanel(ev.channel);
	}

	private _onICEGatheringStateChange(ev: Event) {
		console.info('ice-gathering-state ', this.pc?.iceGatheringState);
	}

	private _onConnectionStateChange() {
		console.debug('peerConnection._onConnectionStateChange ', this.connectionState);
		if (this.connectionState === 'disconnected' || this.connectionState === 'failed') {
			this.signallingClient
				.promptICERestart()
				.catch(error => console.error('Error prompting iceRestart', error));
		}
		this.onConnectionStateChange && this.onConnectionStateChange(this.connectionState);
	}

	/** Callback to handle ICE candidates generated from this local */
	private async onLocalICE(e: RTCPeerConnectionIceEvent) {
		if (!e.candidate) {
			console.debug('peerConnection.onLocalICE NULL');
			return;
		}

		return this.signallingClient
			.sendICE({
				sdpMLineIndex: e.candidate.sdpMLineIndex!,
				candidate: e.candidate?.candidate || null,
			})
			.catch(error => {
				console.error('peerConnection.onLocalICE', error);
			});
	}

	/** Callback to handle sdp from peer */
	private async onPeerSDP(key: string, offer: Omit<RTCSessionDescription, 'toJSON'>) {
		if (offer.type !== 'offer') {
			console.error('peerConnection.onPeerSDP Invalid remote SDP type', offer);
			return;
		} else if (this.pc === undefined) {
			console.error('this.pc is not defined. Call this.setupPeerConnection() first');
			return;
		}

		console.debug('onPeerSDP\n', offer.sdp);
		try {
			// set received offer from peer
			await this.pc.setRemoteDescription(offer);
			// set corresponding answer for the received offer
			await this.pc.setLocalDescription(await this.pc.createAnswer());
			// send answer to peer, via signalling channel
			// We use the same key as what the remote peer sent, to indicate that this answer is for that specific offer
			await this.signallingClient.sendSDPToPeer(key, this.pc.localDescription!);
		} catch (error) {
			// catch any errors and log them only.
			// We really don't want to be throwing here in this callback
			console.error('peerConnection.onPeerSDP', error);
		}
	}

	/** Callback to handle ice from peer */
	private async onPeerICE(data: ICEPayload) {
		if (this.pc === undefined) {
			console.error('this.pc is not defined. Call this.setupPeerConnection() first');
			return;
		}
		console.debug('peerConnection.onPeerICE', data.candidate, data.sdpMLineIndex);
		try {
			await this.pc.addIceCandidate({
				candidate: data.candidate!, // TODO: Check that the incoming candidate is never null
				sdpMLineIndex: data.sdpMLineIndex,
			});
		} catch (err) {
			console.error('peerConnection.onPeerICE error: ', err);
		}
	}

	public promptIceRestart() {
		this.signallingClient.promptICERestart();
	}
}
