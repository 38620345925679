import React, { useEffect, useRef, useState, useCallback } from 'react';
import './index.scss';
import { connect } from 'react-redux';
import {
	SET_ROASTER_LARGE_ICONS,
	SET_ROASTER_SEARCH_NAME,
	SET_SORT_STATUS,
	SET_LOCAL_STREAM,
	SET_ROSTER_DIMENSIONS,
	SET_SORT_METHOD,
} from '../../actions/types';
import { PropsFromParent } from './model';
import { setParameter } from '../../actions/setParam';
import { ConnectedProps } from 'react-redux';
import { AppRootState, useTypedSelector } from '../../../../../reducers';
import GoBeListIcon from './goBeListIcon';
import GoBeCardIcon from './goBeCardIcon';
import { useHistory } from 'react-router-dom';
import chunkArray from '../../utils/chunkArray';
import SafetyAgreements from './safetyAgreemnets';
import { deviceStateConverter, equalityFnc } from '../../utils/deviceStateConverter';
import { b64EncodeUnicode } from '../../../../../utils/encoding';
import { publish } from './../../../../../actions/publish';
import updatedRobotsSelector from '../../utils/updatedRobotsSelector';
import axios from 'axios';
import { State } from 'xstate';

const reduxConnector = connect(
	(state: AppRootState) => ({
		largeIcons: state.goBeState.roasterState.largeIcons,
		searchName: state.goBeState.roasterState.searchName,
		sortStatus: state.goBeState.roasterState.sortStatus,
		localStream: state.goBeState.sessionState.localStream,
		accountState: (state as any).accountState,
		dimensions: state.goBeState.roasterState.dimensions,
		sortMethod: state.goBeState.roasterState.sortMethod,
		agreements: state.accountState.user.agreements,
		acceptedAgreements: state.accountState.user.acceptedAgreements,
		robotId: state.goBeState.sessionState.robotId,
		username: state.accountState.user.username,
	}),
	{ setParameter }
);

type PropsFromRedux = ConnectedProps<typeof reduxConnector>;
type ComponentProps = PropsFromRedux & PropsFromParent;

const Roaster: React.FC<PropsFromRedux> = ({
	largeIcons,
	searchName,
	sortStatus,
	localStream,
	setParameter,
	accountState,
	agreements,
	acceptedAgreements,
	robotId,
	username,
	dimensions,
	sortMethod,
}) => {
	const items = useTypedSelector(
		state => deviceStateConverter(state),
		(left: any, right: any) => equalityFnc(left, right)
	);
	const changeLargeIcons = () => {
		setParameter('largeIcons', SET_ROASTER_LARGE_ICONS, !largeIcons);
	};

	const onChangeSearchName = (event: React.ChangeEvent<HTMLInputElement>) => {
		setParameter('searchName', SET_ROASTER_SEARCH_NAME, event.target.value);
	};

	const onSortClick = () => {
		setParameter('sortStatus', SET_SORT_STATUS, !sortStatus);
	};

	const history = useHistory();
	const goBesElement = document.getElementById('goBesWrapper');
	const roasterRef = useRef<any>(null);
	const [goBesArray, setGoBesArray] = useState<any>([]);
	const [cardLoadCounter, setCardLoadCounter] = useState<any>(2);
	const [listLoadCounter, setListLoadCounter] = useState<any>(1);
	const [listHeightCounter, setListHeightCounter] = useState<any>(0);
	const [devicesIds, setdevicesIds] = useState<any>([]);
	const [devicesIdsSorted, setDevicesIdsSorted] = useState<any>([]);
	const [devicesId, setdevicesId] = useState<any>([]);
	const [agreementsStatus, changeAgreementStatus] = useState<boolean>(false);
	const [acceptedAgreementsStatus, changeAcceptedAgreementStatus] = useState<boolean>(false);
	const [updatedRobots, setUpdatedRobots] = useState([]);

	const onLoadMoreClick = useCallback(() => {
		if (largeIcons) {
			if (cardLoadCounter + 2 <= goBesArray.length) {
				setCardLoadCounter(cardLoadCounter + 2);
			} else {
				if (cardLoadCounter + 1 <= goBesArray.length) {
					setCardLoadCounter(cardLoadCounter + 1);
				}
			}
		} else {
			if (listLoadCounter + 1 <= goBesArray.length) {
				setListLoadCounter(listLoadCounter + 1);
				setListHeightCounter(goBesArray[listLoadCounter].length);
			}
		}
	}, [cardLoadCounter, goBesArray, largeIcons, listLoadCounter]);

	const onToTopClick = () => {
		roasterRef.current.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
	};

	const cancelAgreementClick = () => {
		changeAgreementStatus(false);
	};

	const onSortMethodClick = useCallback(
		(method: string) => {
			setParameter('sortMethod', SET_SORT_METHOD, method);
			setParameter('sortStatus', SET_SORT_STATUS, false);
		},
		[setParameter]
	);

	const onAvailableSortClick = useCallback(() => {
		let newItemsAvailable: any = [];
		let newItemsUnavailable: any = [];
		Object.values(accountState.user.devices).map((item: any) => {
			if (item.currentState === 'available' && item.online) {
				newItemsAvailable.push({ deviceId: item.deviceId, orgId: item.orgId });
			} else {
				newItemsUnavailable.push({ deviceId: item.deviceId, orgId: item.orgId });
			}
		});
		let newItems = newItemsAvailable.concat(newItemsUnavailable);
		setDevicesIdsSorted(newItems);
	}, [accountState.user.devices]);

	const onNameSortClick = useCallback(() => {
		let newItems: any = [];
		let newItemsNoName: any = [];
		Object.values(accountState.user.devices).map((item: any) => {
			if (item.name) {
				newItems.push(item);
			} else {
				newItemsNoName.push(item);
			}
		});
		newItems.sort((a: any, b: any) => (a as any).name.localeCompare((b as any).name));
		setDevicesIdsSorted(newItems.concat(newItemsNoName));
	}, [accountState.user.devices]);

	const onGroupSortClick = useCallback(() => {
		setDevicesIdsSorted(devicesIds);
	}, [devicesIds]);

	const onFavoriteSortClick = useCallback(() => {
		setDevicesIdsSorted(devicesIds);
	}, [devicesIds]);

	const onRecentSortClick = useCallback(() => {
		setDevicesIdsSorted(devicesIds);
	}, [devicesIds]);

	const agreeContinueClick = async () => {
		await publish(
			`microservice/${accountState.user.selectedOrganizationId}/${b64EncodeUnicode(
				accountState.user.username
			)}/acceptAgreements/user`,
			{
				requestId: 'acceptAgreementsId',
				data: {
					language: 'en',
					ip_address: undefined,
					agreements_ids: [
						agreements.find((agreement: any) => agreement.type === 'safety-agreement')
							.id,
					],
				},
			}
		);
		history.push('/gobe/session');

		// if (updatedRobotsSelector(updatedRobots, robotId)) {
		// 	history.push('/gobe/session');
		// } else {
		// 	history.push('/gobe/session-legacy');
		// }
	};

	useEffect(() => {
		switch (sortMethod) {
			case 'Available':
				return onAvailableSortClick();
			case 'Name':
				return onNameSortClick();
			case 'Group':
				return onGroupSortClick();
			case 'Favorite':
				return onFavoriteSortClick();
			case 'Recently used':
				return onRecentSortClick();
		}
	}, [
		onAvailableSortClick,
		onFavoriteSortClick,
		onGroupSortClick,
		onNameSortClick,
		onRecentSortClick,
		onSortMethodClick,
		setParameter,
		sortMethod,
	]);

	useEffect(() => {
		if (localStream) {
			setParameter('localStream', SET_LOCAL_STREAM, null);
		}
	}, [localStream, setParameter]);

	useEffect(() => {
		const url = () => {
			if (
				history.location.pathname.includes('staging') ||
				process.env.REACT_APP_FM === 'Staging'
			) {
				return 'https://storage.googleapis.com/gobe_public_media/updatedRobots-staging.json';
			} else if (
				history.location.pathname.includes('nightly') ||
				process.env.REACT_APP_FM === 'Nightly'
			) {
				return 'https://storage.googleapis.com/gobe_public_media/updatedRobots-nightly.json';
			} else {
				return 'https://storage.googleapis.com/gobe_public_media/updatedRobots-nightly.json';
			}
		};
		axios
			.get(url(), {
				headers: {
					'Content-Type': 'text',
					Accept: 'text',
				},
			})
			.then(function(response: any) {
				setUpdatedRobots(response.data);
			});
	}, [history.location.pathname]);

	useEffect(() => {
		publish(`microservice/${b64EncodeUnicode(username)}/getAcceptedAgreements`, {
			requestId: 'getAcceptedAgreementsId',
			data: {},
		});
	}, [username]);

	useEffect(() => {
		if (!dimensions.width) {
			setParameter('dimensions', SET_ROSTER_DIMENSIONS, {
				width: goBesElement?.getBoundingClientRect().width,
				height: goBesElement?.getBoundingClientRect().height,
			});
		}
		if (
			items &&
			items.length > 0 &&
			accountState.user.devicesIds &&
			accountState.user.devicesIds.length > 0 &&
			devicesIds.length === 0
		) {
			items.map((item: any) => {
				accountState.user.devicesIds.map((idItem: any) => {
					if (idItem.includes(item.deviceId) && !devicesId.includes(item.deviceId)) {
						devicesIds.push(item);
						devicesIdsSorted.push(item);
						devicesId.push(item.deviceId);
					}
				});
			});
		}
	}, [
		accountState.user.devicesIds,
		items,
		devicesIds,
		goBesArray.length,
		devicesId,
		largeIcons,
		dimensions.width,
		goBesElement,
		setParameter,
		devicesIdsSorted,
	]);

	useEffect(() => {
		if (dimensions.width) {
			if (largeIcons) {
				let limitNumber = Math.floor(dimensions.width / 260);
				setGoBesArray(chunkArray(devicesIdsSorted, limitNumber));
			} else {
				setGoBesArray(chunkArray(devicesIdsSorted, 10));
			}
		}
	}, [dimensions.width, largeIcons, goBesArray.length, devicesIdsSorted]);

	useEffect(() => {
		let link = document.getElementById('jsd-widget');
		if (link && (link as any).style.display === 'none') {
			(link as any).style.display = 'block';
		}
	}, []);

	useEffect(() => {
		if (acceptedAgreements.find((agreement: any) => agreement.type === 'safety-agreement')) {
			changeAcceptedAgreementStatus(true);
		}
	}, [acceptedAgreements]);

	const renderGoBes = () => {
		return devicesIdsSorted.map((subItem: any, subIndex: any) => {
			if (largeIcons) {
				return (
					<GoBeCardIcon
						key={subIndex}
						deviceId={subItem.deviceId}
						orgId={subItem.orgId}
						agreementsStatus={agreementsStatus}
						acceptedAgreementsStatus={acceptedAgreementsStatus}
						changeAgreementStatus={changeAgreementStatus}
						updatedRobots={updatedRobots}
					/>
				);
			} else {
				return (
					<GoBeListIcon
						key={subIndex}
						deviceId={subItem.deviceId}
						orgId={subItem.orgId}
						agreementsStatus={agreementsStatus}
						acceptedAgreementsStatus={acceptedAgreementsStatus}
						changeAgreementStatus={changeAgreementStatus}
						updatedRobots={updatedRobots}
					/>
				);
			}
		});
	};

	return (
		<div className="roasterContainer" id="roasterContainer" ref={roasterRef}>
			<div className="goBesContainer">
				<div className="goBesShowSetting">
					<div className="goBesTitle">All GoBe Robots</div>
					<div className="searchSettingContainer">
						<div className="searchGoBes">
							<div className="searchIconWrapper">
								<img src="../assets/images/search.svg" alt="" />
							</div>
							<input
								placeholder="Search robot"
								value={searchName}
								onChange={event => onChangeSearchName(event)}
							/>
						</div>
						<div className="showModelContainer" onClick={changeLargeIcons}>
							<div className="largeIconWrapper">
								<img
									src={`../assets/images/${
										!largeIcons ? 'largeIcon.svg' : 'listIcon.svg'
									}`}
									alt=""
								/>
							</div>
							{!largeIcons ? 'Card View' : 'List View'}
						</div>
						<div className="sortByContainer">
							<div className="sortIcon">
								<div className="arrowUpWrapper">
									<img src="../assets/images/arrow-up.svg" />
								</div>
							</div>
							<div
								className={
									sortStatus ? 'sortDropDown' : 'sortDropDown sortDropDownHide'
								}
							>
								<div
									className={
										sortMethod === 'Name'
											? 'sortDropDownItemSelected'
											: 'sortDropDownItem'
									}
									onClick={() => onSortMethodClick('Name')}
								>
									Name
								</div>
								<div
									className={
										sortMethod === 'Group'
											? 'sortDropDownItemSelected'
											: 'sortDropDownItem'
									}
									onClick={() => onSortMethodClick('Group')}
								>
									Group
								</div>
								<div
									className={
										sortMethod === 'Available'
											? 'sortDropDownItemSelected'
											: 'sortDropDownItem'
									}
									onClick={() => onSortMethodClick('Available')}
								>
									Available
								</div>
								<div
									className={
										sortMethod === 'Recently used'
											? 'sortDropDownItemSelected'
											: 'sortDropDownItem'
									}
									onClick={() => onSortMethodClick('Recently used')}
								>
									Recently used
								</div>
								<div
									className={
										sortMethod === 'Favorite'
											? 'sortDropDownItemSelected'
											: 'sortDropDownItem'
									}
									onClick={() => onSortMethodClick('Favorite')}
								>
									Favorite
								</div>
							</div>
							<div className="sortText" onClick={onSortClick}>
								Sort By
								<div
									className={
										sortStatus
											? 'chevronUpWrapper chevronUpWrapperRotate'
											: 'chevronUpWrapper'
									}
								>
									<img src="../assets/images/chevron-down.svg" />
								</div>
							</div>
						</div>
					</div>
				</div>
				<div
					id="goBesWrapper"
					className={largeIcons ? 'goBesWrapper' : 'goBesWrapper goBesListWrapper'}
					style={{
						height: `${
							largeIcons ? cardLoadCounter * 343 + 35 : 670 + listHeightCounter * 60
						}px`,
					}}
				>
					<div className={!largeIcons ? 'goBesListTitles' : 'displayNone'}>
						<div className="goBeListName">GoBe</div>
						<div className="goBeListItem">Charge</div>
						<div className="goBeListItem">Group</div>
						<div className="goBeListItem">Location</div>
						<div className="goBeListItem">Last use</div>
						<div className="goBeListItem">Status</div>
					</div>
					{renderGoBes()}
				</div>
				<div className="loadMoreContainer">
					<div
						className={
							goBesArray.length !== 0
								? largeIcons
									? cardLoadCounter === goBesArray.length ||
									  goBesArray.length <= 2
										? 'displayNone'
										: 'loadMoreButton'
									: listLoadCounter === goBesArray.length ||
									  goBesArray.length <= 1
									? 'displayNone'
									: 'loadMoreButton'
								: 'displayNone'
						}
						onClick={onLoadMoreClick}
					>
						LOAD MORE ROBOTS <span>&#x2304;</span>
					</div>
				</div>
				<div
					className={
						largeIcons
							? cardLoadCounter > 2
								? 'toTopContainer'
								: 'displayNone'
							: listLoadCounter > 1
							? 'toTopContainer'
							: 'displayNone'
					}
					onClick={onToTopClick}
				>
					<div className="toTopCircle">
						<span>&#8963;</span>
					</div>
				</div>
			</div>
			<div className="roasterFooterContainer">
				<div className="borLogoLines" />
				<div className="borLogoWrapper">
					<img src="../assets/images/black-bor-logo.svg" />
				</div>
				<div className="borLogoLines" />
			</div>
			<div className={agreementsStatus ? 'agreementContainer' : 'displayNone'}>
				{agreements.length !== 0 ? (
					<SafetyAgreements
						agreement={{
							message: 'Safety Agreements',
							value: '1000',
							description: agreements.find(
								(agreement: any) => agreement.type === 'safety-agreement'
							).content,
						}}
						messageHeight={1000}
					/>
				) : null}
				<div className="safetyButtonsContainer">
					<div className="blackCancel" onClick={() => cancelAgreementClick()}>
						Cancel
					</div>
					<div className="greenAccept" onClick={() => agreeContinueClick()}>
						Agree and Continue
					</div>
				</div>
			</div>
		</div>
	);
};

export default reduxConnector(Roaster);
