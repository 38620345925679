import {
	FETCH_ORGANIZATIONS,
	FETCH_SHOWING_ORGANIZATION,
	FETCH_ORGANIZATION_CHILDREN,
	INSERT_NEW_ORGANIZATION_SUCCESS,
	UPDATE_ORGANIZATION,
	DELETE_ORGANIZATION,
	CHANGE_ADD_NEW_ORGANIZATION_STATE,
	RESET_APP,
	INSERT_NEW_CHILD_ORG,
	UNLINK_DEVICE_FROM_ORG,
} from '../actions/types';
import { Organization } from '../types/types';

export interface initialOrganizationState {
	organizations: Record<string, Organization>;
	showingOrganization: Organization;
	orgTypesAllowed: Record<string, Array<string>>;
	newOrganizationOpen: boolean;
}

const initialState: initialOrganizationState = {
	organizations: {},
	showingOrganization: {} as Organization,
	orgTypesAllowed: {
		bor: ['spinout'],
		spinout: ['solutionProvider', 'customer'],
		solutionProvider: ['customer'],
		customer: [],
	},
	newOrganizationOpen: false,
};

export default function organizationsReducer(state = initialState, action: Record<string, any>) {
	switch (action.type) {
		case FETCH_ORGANIZATIONS:
			action.payload.organizations.forEach((element: Organization) => {
				state.organizations[element.orgId] = { ...element, userTopOrganization: true };
			});
			return {
				...state,
				organizations: state.organizations,
			};
		case FETCH_ORGANIZATION_CHILDREN:
			action.payload.children.forEach((element: Organization) => {
				state.organizations[element.orgId] = { ...element, userTopOrganization: false };
			});
			return {
				...state,
				organizations: state.organizations,
			};

		case FETCH_SHOWING_ORGANIZATION:
			return {
				...state,
				showingOrganization: action.payload.organization,
			};
		case INSERT_NEW_ORGANIZATION_SUCCESS:
			const newOrganization = action.payload.newOrganization;
			return {
				...state,
				organizations: {
					...state.organizations,
					[newOrganization.orgId]: newOrganization,
				},
			};
		case UPDATE_ORGANIZATION:
			const updatedOrganization = action.payload;

			return {
				...state,
				organizations: {
					...state.organizations,
					[updatedOrganization.orgId]: updatedOrganization,
				},
			};
		case DELETE_ORGANIZATION:
			if (!action.payload) return state;

			delete state.organizations[action.payload];
			return {
				...state,
				organizations: {
					...state.organizations,
				},
			};
		case RESET_APP:
			return {
				...state,
				organizations: {},
				showingOrganization: {},
			};

		case CHANGE_ADD_NEW_ORGANIZATION_STATE:
			return {
				...state,
				newOrganizationOpen: action.payload.organizations,
			};
		case INSERT_NEW_CHILD_ORG:
			let organizationsList = state.organizations;
			if (organizationsList[action.payload.parentOrganization].childOrgs == null) {
				(organizationsList[action.payload.parentOrganization].childOrgs as any) = {
					[action.payload.newOrganization.orgId]: action.payload.newOrganization,
				};
			} else {
				organizationsList[action.payload.parentOrganization].childOrgs[
					action.payload.newOrganization.orgId
				] = action.payload.newOrganization;
			}

			return {
				...state,
				organizations: organizationsList,
			};
		case UNLINK_DEVICE_FROM_ORG:
			let deletedDevice = action.payload;
			if (
				state.organizations[deletedDevice.orgId].devices &&
				state.organizations[deletedDevice.orgId].devices[deletedDevice.deviceId]
			) {
				delete state.organizations[deletedDevice.orgId].devices[deletedDevice.deviceId];
			}

			return {
				...state,
				organizations: state.organizations,
			};

		default:
			return state;
	}
}
