import React from 'react';
import './index.scss';
import Checkbox from '../checkbox';
import { PropsFromParent } from './model';

const SessionEndPause: React.FC<PropsFromParent> = ({
	pauseSessionStatus,
	endSessionStatus,
	playClick,
	endClick,
	pauseClick,
	cancelClick,
}) => {
	return (
		<div className={pauseSessionStatus?'sessionEndPauseOpacity sessionEndPauseContainer':'sessionEndPauseContainer'}>
			<h1>{pauseSessionStatus ? 'On Hold' : `Ending the session`}</h1>
			<div className={'endPauseButtonsContainer'}>
				<div className={'circleTextContainer'}>
					<div
						className={
							pauseSessionStatus
								? 'greenCircle blackCircle'
								: 'whiteCircle blackCircle'
						}
						onClick={() => (pauseSessionStatus ? playClick() : pauseClick())}
					>
						<div className={'iconWrapper'}>
							<img
								src={`../../assets/images/${
									pauseSessionStatus ? 'play.svg' : 'black-pause.svg'
								}`}
								alt=""
							/>
						</div>
					</div>
					<div>{pauseSessionStatus ? 'Resume' : 'Pause'}</div>
				</div>
				<div className={'circleTextContainer'}>
					<div className={'redCircle blackCircle'} onClick={() => endClick()}>
						<div className={'iconWrapper'}>
							<img src={'../../assets/images/close.svg'} alt="" />
						</div>
					</div>
					<div>End</div>
				</div>
			</div>
			<div className={'warningText'}>
				{pauseSessionStatus
					? 'You will be auto-disconnected in 15 minutes.'
					: 'Are you sure that you want to end the session?'}
			</div>
			<div
				className={pauseSessionStatus ? 'displayNone' : 'cancelButtonContainer'}
				onClick={() => cancelClick()}
			>
				CANCEL
			</div>
		</div>
	);
};

export default SessionEndPause;
