import { useEffect, useCallback, useRef, useState } from 'react';
import { Machine, StateSchema, assign, actions } from 'xstate';
import { useMachine } from '@xstate/react';
import { debounce, keys, throttle } from 'lodash';
import { RTCInboundRtpStreamStatsEmitter } from '../rtcStats';

const angularMultiplier = (speed: number) => {
	if (speed > 0.8) {
		return 0.84;
	} else {
		return 1.69;
	}
};

type Keys = {
	up: 0 | 1;
	down: 0 | 1;
	left: 0 | 1;
	right: 0 | 1;
	p: 0 | 1;
};
type KEY_EVENT = { type: 'KEY_EVENT'; key: keyof Keys; value: 0 | 1 };
type SPEED_CHANGED_EVENT = { type: 'SPEED_CHANGED'; speed: number };
type DATACHANNEL_READY_EVENT = { type: 'DATACHANNEL_READY'; datachannel: RTCDataChannel };
type NavControlMachineEvent =
	| KEY_EVENT
	| SPEED_CHANGED_EVENT
	| DATACHANNEL_READY_EVENT
	| { type: 'PAUSE' | 'RESUME' | 'ABORT' | 'HAS_VIDEO' | 'NO_VIDEO' };
interface NavControlMachineContext {
	datachannel: RTCDataChannel | undefined;
	speed: number;
	keys: Keys;
}
interface NavControlMachineStateSchema extends StateSchema {
	states: {
		paused: {};
		stallingCommands: {};
		sendingCommands: {
			states: {
				commandLocked: {};
				sending: {};
			};
		};
		aborted: {};
	};
}

/** The time in milliseconds, between two successive repetitions of key input  */
const COMMAND_REPETITION_INTERVAL_MS = 200;
const NavControlMachine = Machine<
	NavControlMachineContext,
	NavControlMachineStateSchema,
	NavControlMachineEvent
>(
	{
		id: 'navPilot',
		initial: 'stallingCommands',
		context: {
			datachannel: undefined,
			speed: 0,
			keys: { up: 0, down: 0, left: 0, right: 0, p: 0 },
		},
		states: {
			paused: {
				entry: ['setZeroKeys', 'sendStopCommand'],
				on: {
					RESUME: {
						target: 'stallingCommands',
						// update the keys being tracked in the context
					},
				},
			},
			stallingCommands: {
				on: {
					KEY_EVENT: {
						target: undefined, // remain in this state
						cond: 'isDifferentKeyValue',
						actions: ['setKeys'], // update the keys being tracked in the context
					},
					HAS_VIDEO: {
						target: 'sendingCommands',
					},
				},
			},
			sendingCommands: {
				initial: 'commandLocked',
				states: {
					commandLocked: {
						after: {
							50: 'sending',
						},
						activities: ['sendSingleCommand'],
						on: {
							KEY_EVENT: {
								target: undefined, // remain in the same state
								cond: 'isDifferentKeyValue',
								actions: ['setKeys'],
							},
							SPEED_CHANGED: {
								target: undefined, // remain in the same state
								cond: 'isDifferentSpeed',
								actions: 'setSpeed',
							},
							DATACHANNEL_READY: {
								target: undefined, // remain in the same state
								actions: 'setDatachannel',
							},
							NO_VIDEO: {
								target: '#navPilot.stallingCommands',
								actions: ['sendStopCommand'],
							},
						},
					},
					sending: {
						activities: ['sendKeysRepeatedly'],
					},
				},
				on: {
					SPEED_CHANGED: {
						target: '.commandLocked',
						cond: 'isDifferentSpeed',
						actions: 'setSpeed',
					},
					KEY_EVENT: {
						target: '.commandLocked',
						cond: 'isDifferentKeyValue',
						actions: ['setKeys'],
					},
					DATACHANNEL_READY: {
						target: '.commandLocked',
						actions: 'setDatachannel',
					},
					NO_VIDEO: {
						target: 'stallingCommands',
					},
				},
			},
			aborted: {
				type: 'final',
			},
		},
		on: {
			PAUSE: {
				target: 'paused',
			},
			DATACHANNEL_READY: {
				target: undefined, // no target, stay in whatever state we are in
				actions: 'setDatachannel',
			},
			SPEED_CHANGED: {
				target: undefined, // no target, stay in whatever state we are in
				actions: 'setSpeed',
			},
			ABORT: {
				target: 'aborted',
			},
		},
	},
	{
		actions: {
			setZeroKeys: assign({
				keys: (ctx, event) => ({ up: 0, down: 0, left: 0, right: 0, p: 0 }),
			}),
			sendStopCommand: (context, event) => {
				const { datachannel } = context;
				if (datachannel === undefined) return;

				try {
					const command = `NAV 0 0 ${performance.now()}`;
					datachannel.send(command);
				} catch (error) {
					console.error('sendStopCommand datachannel.send.failed', error);
				}
			},
			setKeys: assign({
				keys: (ctx, event) => {
					const _event = event as KEY_EVENT;
					return { ...ctx.keys, [_event.key]: _event.value };
				},
			}),
			setSpeed: assign({
				speed: (context, event) => {
					const _event = event as SPEED_CHANGED_EVENT;
					return _event.speed / 100;
				},
			}),
			setDatachannel: assign({
				datachannel: (context, event) => {
					const _event = event as DATACHANNEL_READY_EVENT;
					return _event.datachannel;
				},
			}),
		},
		guards: {
			isDifferentKeyValue: (context, event) => {
				const _event = event as KEY_EVENT;
				return context.keys[_event.key] !== _event.value;
			},
			isDifferentSpeed: (context, event) => {
				const _event = event as SPEED_CHANGED_EVENT;
				return context.speed !== _event.speed;
			},
		},
		activities: {
			sendKeysRepeatedly: context => {
				const { keys, datachannel, speed } = context;
				if (datachannel === undefined) return;

				if (keys.p === 1) {
					try {
						datachannel.send(`DOCK CONTINUE ${performance.now()}`);
					} catch (error) {
						console.error('datachannel.send.leading.failed', error);
					}
					const interval = setInterval(() => {
						try {
							datachannel.send(`DOCK CONTINUE ${performance.now()}`);
						} catch (error) {
							console.error('datachannel.send.repeating.failed', error);
						}
					}, COMMAND_REPETITION_INTERVAL_MS);

					return () => {
						clearInterval(interval);
					};
				} else {
					const linear = speed * (keys.up - keys.down);
					const angular = angularMultiplier(speed) * speed * (keys.left - keys.right);
					const command = `NAV ${linear} ${angular}`;

					// send the command immediately to the robot
					try {
						datachannel.send(command + ` ${performance.now()}`);
					} catch (error) {
						console.error('datachannel.send.leading.failed', error);
					}
					if (keys.up === 0 && keys.right === 0 && keys.down === 0 && keys.left === 0) {
						// if it a stop command, we wont repeat it
						return;
					} else {
						// and also repeatedly every X milliseconds
						const interval = setInterval(() => {
							try {
								datachannel.send(command + ` ${performance.now()}`);
							} catch (error) {
								console.error('datachannel.send.repeating.failed', error);
							}
						}, COMMAND_REPETITION_INTERVAL_MS);

						return () => {
							clearInterval(interval);
						};
					}
				}
			},
			sendSingleCommand: (context, event) => {
				const { keys, datachannel, speed } = context;
				if (datachannel === undefined) return;

				if (keys.p === 1) {
					datachannel.send(`DOCK START ${performance.now()}`);
				} else {
					const linear = speed * (keys.up - keys.down);
					const angular = angularMultiplier(speed) * speed * (keys.left - keys.right);
					const command = `NAV ${linear} ${angular}`;

					try {
						datachannel.send(command + ` ${performance.now()}`);
					} catch (error) {
						console.error('sendSingleCommand datachannel.send.failed', error);
					}
				}
			},
		},
	}
);

export type NavKey =
	| 'ArrowUp'
	| 'w'
	| 'W'
	| 'ArrowDown'
	| 's'
	| 'S'
	| 'ArrowRight'
	| 'd'
	| 'D'
	| 'ArrowLeft'
	| 'a'
	| 'A'
	| 'p';

const STATS_SAMPLING_PERIOD_MS = 100;

const NavKeyMapping: Record<NavKey, keyof Keys> = {
	ArrowUp: 'up',
	w: 'up',
	W: 'up',
	ArrowDown: 'down',
	s: 'down',
	S: 'down',
	ArrowRight: 'right',
	d: 'right',
	D: 'right',
	ArrowLeft: 'left',
	a: 'left',
	A: 'left',
	p: 'p',
};

const useNavController = () => {
	const navFrames = useRef(1);
	const wideFrames = useRef(1);

	useEffect(() => {
		console.info('NavController Hook Re-rendered');
	}, []);

	const [currentState, _sendEvent] = useMachine(NavControlMachine);
	const sendEvent = useRef(_sendEvent);
	useEffect(() => {
		sendEvent.current = _sendEvent;
	}, [_sendEvent]);

	const setDataChannel = useCallback((datachannel: RTCDataChannel) => {
		(window as any).datachannel = datachannel;
		sendEvent.current({ type: 'DATACHANNEL_READY', datachannel });
	}, []);

	const setNavSpeed = useCallback((speed: number) => {
		sendEvent.current({ type: 'SPEED_CHANGED', speed });
		console.info('SPEED_CHANGED');
	}, []);

	const pause = useCallback(() => {
		sendEvent.current({ type: 'PAUSE' });
	}, []);

	const resume = useCallback(() => {
		sendEvent.current({ type: 'RESUME' });
	}, []);

	const onKeyEvent = useCallback((eventType: 'keydown' | 'keyup', key: NavKey) => {
		if (!Object.keys(NavKeyMapping).includes(key as string)) return;

		sendEvent.current({
			type: 'KEY_EVENT',
			key: NavKeyMapping[key],
			value: eventType === 'keydown' ? 1 : 0,
		});
	}, []);

	const [navCamVideoRTCRtpReceiver, setNavCamVideoRTCRtpReceiver] = useState<RTCRtpReceiver>();
	const [wideVideoRTCRtpReceiver, setWideVideoRTCRtpReceiver] = useState<RTCRtpReceiver>();

	// listen for bytes received on the wide camera's rtp receiver
	useEffect(() => {
		if (!wideVideoRTCRtpReceiver || !navCamVideoRTCRtpReceiver) return;

		let hasWideCamVideo = false;
		let hasNavCamVideo = false;

		const wideCamStatsListener = new RTCInboundRtpStreamStatsEmitter(
			wideVideoRTCRtpReceiver,
			null, // there is only one MediaStreamTrack associated with an RTCRtpReceiver
			STATS_SAMPLING_PERIOD_MS
		);
		const navCamStatsListener = new RTCInboundRtpStreamStatsEmitter(
			navCamVideoRTCRtpReceiver,
			null, // there is only one MediaStreamTrack associated with an RTCRtpReceiver
			STATS_SAMPLING_PERIOD_MS
		);

		wideCamStatsListener.start();
		navCamStatsListener.start();

		const wideBytesListenerId = wideCamStatsListener.addEventListener(
			'framesDecoded',
			framesDecoded => {
				wideFrames.current = framesDecoded;
				hasWideCamVideo = framesDecoded !== 0;
			}
		);
		const navBytesListenerId = navCamStatsListener.addEventListener(
			'framesDecoded',
			framesDecoded => {
				navFrames.current = framesDecoded;
				hasNavCamVideo = framesDecoded !== 0;
			}
		);

		const interval = setInterval(() => {
			if (hasWideCamVideo && hasNavCamVideo) {
				sendEvent.current({ type: 'HAS_VIDEO' });
			} else {
				sendEvent.current({
					type: 'NO_VIDEO',
				});
			}
		}, STATS_SAMPLING_PERIOD_MS);

		return () => {
			console.warn('bytes-tracking-useEffect unMounted');
			clearInterval(interval);

			wideCamStatsListener.stop();
			navCamStatsListener.stop();

			wideCamStatsListener.removeListener('framesDecoded', wideBytesListenerId);
			navCamStatsListener.removeListener('framesDecoded', navBytesListenerId);
		};
	}, [navCamVideoRTCRtpReceiver, wideVideoRTCRtpReceiver]);

	/** Set the receiver for a video track that needs to be tracked
	 * in order to allow/disallow navigation */
	const setTrackedRTCRtpReceiver = useCallback(
		(cam: 'wideCam' | 'navCam', rtcpReceiver: RTCRtpReceiver) => {
			if (cam === 'navCam') setNavCamVideoRTCRtpReceiver(rtcpReceiver);
			else setWideVideoRTCRtpReceiver(rtcpReceiver);
		},
		[]
	);

	const returnValue = useRef({
		onKeyEvent,
		setTrackedRTCRtpReceiver,
		setDataChannel,
		setNavSpeed,
		pause,
		resume,
		navFrames,
		wideFrames,
	});
	return returnValue.current;
};

export default useNavController;
