import { useRef, useEffect, useState, useCallback, useMemo } from 'react';
import PeerConnectionWithSignalling, { RemoteTrackKey } from './peerConnection';
import mqtt, { IClientOptions } from 'mqtt';
import MqttReconnector from './mqttReconnect';

export type ConnectionState = RTCPeerConnectionState;
const useCallerPeerConnection = (
	mqttConfig: Partial<IClientOptions>,
	callerInfo: { avatar?: string; name: string; id: string; username: string; password: string },
	robotId: string,
	onRemoteTrack: (
		track: MediaStreamTrack,
		key: RemoteTrackKey,
		transceiver: RTCRtpTransceiver
	) => void,
	onDataChannel: (dataChannel: RTCDataChannel) => void,
	onStarted: () => void
) => {
	// memoize the credentials, it wont change ever !
	const credentialsRef = useRef({
		username: callerInfo.username,
		password: callerInfo.password,
	});
	const mqttClient = useMemo(() => {
		return mqtt
			.connect({
				...mqttConfig,
				path: `/${mqttConfig.path}`,
				username: credentialsRef.current.username,
				password: credentialsRef.current.password,
				reconnectPeriod: 0, // disable auto reconnection, we handle this manually
			} as IClientOptions)
			.on('connect', () => {
				console.log('RTC mqtt CONNECT');
			});
	}, [mqttConfig]);

	const refs = useRef({
		// TODO: Use callerInfo.id (encoded) instead of devPrince
		/** a memoized peer connection to ensure referential stability */
		peerConnection: new PeerConnectionWithSignalling(
			callerInfo.id,
			robotId,
			{ name: callerInfo.name, avatar: callerInfo.avatar },
			mqttClient
		),
		/** container for media tracks added by the remote peer */
		remoteStream: new MediaStream(),
	});
	// bind PeerConnection object to window, for debugging purposes
	useEffect(() => {
		if (process.env.NODE_ENV !== 'production') {
			(window as any).peerConnection = refs.current.peerConnection;
			(window as any).sessionID = refs.current.peerConnection.uuid;
		}
		return () => {
			(window as any).peerConnection = null;
		};
	}, []);

	const mqttClientReconnectorRef = useRef(
		new MqttReconnector(mqttClient, refs.current.peerConnection)
	);

	const [wideCamMetrics, setWideCamMetrics] = useState<{
		mediaTrack: MediaStreamTrack;
		rtcRtpReceiver: RTCRtpReceiver;
	}>();
	useEffect(() => {
		if (!wideCamMetrics) return;

		const { current: mqttClientReconnector } = mqttClientReconnectorRef;

		const { rtcRtpReceiver, mediaTrack } = wideCamMetrics;
		console.log('Starting MqttClientReconnector');
		mqttClientReconnector.start(rtcRtpReceiver, mediaTrack);

		return () => {
			console.log('Stopping MqttClientReconnector');
			mqttClientReconnector.stop();
		};
	}, [wideCamMetrics]);

	// TODO: Implement this.
	const [iceRestarting] = useState(false);

	// Kill peer connection when caller component is dieing
	useEffect(() => {
		const peerConnection = refs.current.peerConnection;
		console.info('caller.peerConnection.cleanup.registered');
		return () => {
			peerConnection.end('CLEANUP');
			console.info('caller.peerConnection.closed');
			console.info('caller.peerConnection.cleanup.unregistered');
		};
	}, []);

	// close mqtt client when caller component is dieing
	useEffect(() => {
		const client = mqttClient;
		console.info('caller.mqttClient.cleanup.registered');
		return () => {
			client.unsubscribe('#');
			// client.end();
			console.info('caller.mqttClient.unsubscribed from all messages');
			console.info('caller.mqttClient.cleanup.unregistered');
		};
	}, [mqttClient]);

	// listen for connection state changes on the peer connection
	const [connectionState, setConnectionState] = useState<ConnectionState>(
		refs.current.peerConnection.connectionState
	);
	useEffect(() => {
		const peerConnection = refs.current.peerConnection;
		peerConnection.onConnectionStateChange = setConnectionState;
		peerConnection.onEnded = () => {
			setConnectionState('closed');
		};
		return () => {
			peerConnection.onConnectionStateChange = null;
			peerConnection.onEnded = null;
		};
	}, []);

	// listen for media tracks added to the peer connection by the remote peer
	useEffect(() => {
		const remoteStream = refs.current.remoteStream;

		/** internal listener for tracks added by the remote peer */
		const _onRemoteTrack = (
			track: MediaStreamTrack,
			key: RemoteTrackKey,
			transceiver: RTCRtpTransceiver
		) => {
			if (key === 'wideCamVideo') {
				setWideCamMetrics({ mediaTrack: track, rtcRtpReceiver: transceiver.receiver });
			}

			remoteStream.addTrack(track);
			onRemoteTrack(track, key, transceiver); // notify the caller component that there is a track
		};

		const peerConnection = refs.current.peerConnection;
		peerConnection.onTrack = _onRemoteTrack;

		return () => {
			peerConnection.onTrack = null;
		};
	}, [onRemoteTrack]);

	// cleanup remote tracks when the caller component is exiting
	useEffect(() => {
		const remoteStream = refs.current.remoteStream;
		return () => {
			remoteStream.getTracks().forEach(track => {
				track.stop();
				remoteStream.removeTrack(track);
			});
		};
	}, []);

	// attach external callbacks to the peerConnection
	// As much as possible, we put external callbacks in a separate useEffect.
	// 	So that their reference changes does not affect other logic here due to re-renders
	useEffect(() => {
		const peerConnection = refs.current.peerConnection;
		peerConnection.onDataChanel = onDataChannel;
		peerConnection.onStarted = onStarted;

		return () => {
			peerConnection.onDataChanel = null;
			peerConnection.onStarted = null;
		};
	}, [onDataChannel, onStarted]);

	const startPeerConnection = useCallback((stream: MediaStream, initialVolume: number) => {
		const peerConnection = refs.current.peerConnection;
		peerConnection.start(stream, initialVolume).catch(error => {
			console.error('Unable to start peer connection', error);
		});
	}, []);

	return {
		connectionState,
		iceRestarting,
		startPeerConnection,
		endPeerConnection: refs.current.peerConnection.end,
		pausePeerConnection: refs.current.peerConnection.pause,
		unpausePeerConnection: refs.current.peerConnection.unpause,
		// promptMediaResend: refs.current.peerConnection.promptMediaResend,
		promptIceRestart: refs.current.peerConnection.promptIceRestart,
	};
};

export default useCallerPeerConnection;
