import React, { useEffect } from 'react';
import './index.scss';
import { AppRootState } from '../../../../../../reducers';
import { ConnectedProps } from 'react-redux';
import { PropsFromParent } from './model';
import { connect } from 'react-redux';
import { setParameter } from '../../../actions/setParam';
import { SET_LOCAL_STREAM, SET_NAV_STREAM } from '../../../actions/types';

const reduxConnector = connect(
	(state: AppRootState) => ({
		localStream: state.goBeState.sessionState.localStream,
	}),
	{ setParameter }
);

type PropsFromRedux = ConnectedProps<typeof reduxConnector>;
type ComponentProps = PropsFromRedux & PropsFromParent;

let CAPTURE_OPTIONS = {
	audio: true,
	video: true,
};

const ShareScreen: React.FC<ComponentProps> = ({ stopShareClick, localStream }) => {
	const mediaDevices = navigator.mediaDevices as any;

	// useEffect(() => {
	// 	async function enableStream() {
	// 		let stream;
	// 		try {
	// 			stream = await mediaDevices.getDisplayMedia(CAPTURE_OPTIONS);
	// 			// setParameter('localStream', SET_LOCAL_STREAM, stream);
	// 		} catch (err) {
	// 			console.log(err);
	// 		}
	// 		console.log(stream, 'shareStream');
	// 	}

	// 	// if (!localStream) {
	// 	enableStream();
	// 	// } else {
	// 	// 	return function cleanup() {
	// 	// 		localStream.getTracks().forEach((track: any) => {
	// 	// 			track.stop();
	// 	// 		});
	// 	// 	};
	// 	// }
	// }, [mediaDevices]);

	return (
		<div className="shareScreenContainer">
			<h1>Select a screen to share</h1>
			<div className="screensShareContainer">
				<div className="oneScreenShareConatiner">
					<div className="stopShareContainer">
						<div>End Screen share</div>
						<div className="stopScreenShare" onClick={() => stopShareClick()}>
							<div className="iconStopShareWrapper">
								<img src="../../assets/images/white-ban.svg" />
							</div>
							<div>Click to stop sharing</div>
						</div>
					</div>
				</div>
			</div>
			<h1>Or select an app to share</h1>
			<div className="screensAppContainer" />
		</div>
	);
};

export default reduxConnector(ShareScreen);
