import React from 'react';
import './index.scss';
import Checkbox from '../checkbox';
import { PropsFromParent } from './model';

const FailedSessionPeerConnection: React.FC<PropsFromParent> = ({
	againClick,
	backClick,
	robotName,
}) => {
	return (
		<div className="failedConnectionContainer">
			<div className="warningIconWrapper">
				<img alt="" src="../../assets/images/cloud-offline.svg" />
			</div>
			<div className="warningText">
				Your session on {robotName} was lost due to network problems.
			</div>
			<div className="failedAgainButtonsContainer">
				<div className="circleTextContainer">
					<div className="redCircle blackCircle" onClick={() => backClick()}>
						<div className="iconWrapper rotateNinety">
							<img src="../../assets/images/close.svg" alt="" />
						</div>
					</div>
					<div>End</div>
				</div>
				{/* <div className="circleTextContainer">
					<div className="whiteCircle blackCircle" onClick={() => againClick()}>
						<div className="iconWrapper">
							<img src="../../assets/images/refresh.svg" alt="" />
						</div>
					</div>
					<div>Retry</div>
				</div> */}
			</div>
		</div>
	);
};

export default FailedSessionPeerConnection;
