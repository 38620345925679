import React, { FC, useEffect, useState } from 'react';
import {
	IonContent,
	IonPage,
	IonLabel,
	IonGrid,
	IonRow,
	IonCol,
	IonIcon,
	IonButton,
} from '@ionic/react';
import { injectIntl } from 'react-intl';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import JWTDecode from 'jwt-decode';
import { setParameter } from '../../actions/setParam';

import classNames from 'classnames';
import classes from './AcceptInvite.module.css';

import { useParams } from 'react-router';
import { automaticLogin } from '../../providers/mqtt';

import ErrorModal from '../../components/ErrorModal/ErrorModal';

const AcceptInviteAcceptPage: React.FC = (props: any) => {
	const { history, intl } = props;
	const { handleSubmit } = useForm();
	const [tokenError, setTokenError] = useState('');
	const [orgName, setOrgName] = useState('');
	const [firstName, setFirstName] = useState('');
	const [tokenObj, setTokenObj] = useState<any>();
	let { token } = useParams<Record<string, any>>();
	const [client, setClient] = useState<any>(null);

	useEffect(() => {
		if (token && !client) {
			try {
				const obj: {
					exp: number;
					first_name: string;
					org: { name: string };
					is_existing_user: string;
					username: string;
					temp_password: string;
				} = JWTDecode(token);
				console.log(obj);
				setTokenObj(obj);
				if (obj.org) setOrgName(obj.org.name);
				if (obj.first_name) setFirstName(obj.first_name);
				if (obj.exp < new Date().getTime() / 1000) {
					setTokenError(intl.formatMessage({ id: 'ConfirmPage.tokenExpired' }));
				}
				automaticLogin(
					obj.username,
					obj.temp_password,
					async (response: { client: any; connected: boolean }) => {
						if (response && response.connected) {
							setClient(response.client);
							setParameter('client', 'SET_MQTTCLIENT', response.client);
						} else {
							setParameter('client', 'SET_MQTTCLIENT', { connected: false });
						}
					}
				);
			} catch (error) {
				setTokenError('error');
			}
		}
	}, [intl, history, token]);

	const acceptInvite = handleSubmit(data => {
		props.setParameter('request', 'CONFIRM_ACCOUNT', {
			id: 'AcceptInviteID-' + tokenObj.username,
			type: 'AcceptInvite',
		});

		client.mqttClient.publish(
			`microservice/accountConfirmation`,
			JSON.stringify({
				requestId: 'ConfirmAccountID-' + tokenObj.username,
				data: { token },
				success: 'success',
			})
		);
		setTokenError('');
		props.history.push('/');
	});

	return (
		<>
			{(tokenError && tokenError.length > 0) ?
				<ErrorModal
					isOpen={true}
					onConfirm={() => props.history.push('/')}
					onDismiss={() => props.history.push('/')}
					type={'token'}
				/>
			: ''}
			<IonPage>
				<IonContent>
					<IonGrid className={classNames(classes.passwordGrid, 'ion-no-padding')}>
						<IonRow className={classes.passwordGridRow}>
							<IonCol
								className={classNames(
									classes.leftContainer,
									'ion-hide-sm-down ion-hide-md-down'
								)}
							/>
							<IonCol className={classes.rightContainer}>
								<IonRow className={classes.passwordFormContainer}>
									<IonGrid>
										<IonRow
											className={classNames(
												classes.topLogoContainer,
												'ion-justify-content-center ion-padding'
											)}
										>
											<div className={classes.logo} />
											<div>
												<IonLabel className={classes.lbInstruction}>
													{props.intl.formatMessage(
														{
															id: 'SetPassword.AcceptWelcome',
														},
														{ orgId: orgName, firstName }
													)}{' '}
												</IonLabel>
											</div>
										</IonRow>
										<form className={classes.passwordForm}>
											<IonRow className="ion-justify-content-center">
												<br />
											</IonRow>
											<IonRow className={classes.btnRow}>
												<IonCol size="12">
													<IonButton
														className="round"
														shape="round"
														size="large"
														onClick={() => acceptInvite()}
													>
														{props.intl.formatMessage({
															id: 'SetPassword.accept',
														})}
													</IonButton>
												</IonCol>
											</IonRow>
										</form>
									</IonGrid>
								</IonRow>
								<IonRow className="ion-justify-content-center">
									<div className={classes.dividerContainer}>
										<div>
											<hr className={classes.divider} />
										</div>
										<div>
											<IonIcon
												src="./assets/img/blue-ocean-logo.svg"
												className={classes.blueoceanLogo}
											/>
										</div>
										<div>
											<hr className={classes.divider} />
										</div>
									</div>
								</IonRow>
							</IonCol>
						</IonRow>
					</IonGrid>
				</IonContent>
			</IonPage>
		</>
	);
};

const mapStateToProps = (state: any) => ({
	client: state.mqttState.client,
});

export default injectIntl(connect(mapStateToProps, { setParameter })(AcceptInviteAcceptPage));
