import React, { useRef, useEffect } from 'react';
import './index.scss';
import { connect } from 'react-redux';
import { setParameter } from '../../../actions/setParam';
import { SET_REMOTE_VIDEO_STATUS } from '../../../actions/types';
import { ConnectedProps } from 'react-redux';
import { AppRootState } from '../../../../../../reducers';

const reduxConnector = connect(
	(state: AppRootState) => ({
		localStream: state.goBeState.sessionState.localStream,
		remoteStream: state.goBeState.sessionState.remoteStream,
		remoteVoiceVolume: state.goBeState.sessionState.remoteVoiceVolume,
		remoteVideoStatus: state.goBeState.sessionState.remoteVideoStatus,
	}),
	{ setParameter }
);

type PropsFromRedux = ConnectedProps<typeof reduxConnector>;

const CAPTURE_OPTIONS = {
	audio: true,
	video: { facingMode: 'user' },
};

const RemoteVideo: React.FC<PropsFromRedux> = ({
	localStream,
	remoteStream,
	setParameter,
	remoteVoiceVolume,
	remoteVideoStatus,
}) => {
	const videoRef = useRef<any>(null);

	useEffect(() => {
		if (remoteStream) {
			videoRef.current.volume = parseInt(remoteVoiceVolume) / 100;
		}
	}, [remoteVoiceVolume, remoteStream]);

	if (remoteStream && videoRef.current && !videoRef.current.srcObject) {
		videoRef.current.srcObject = remoteStream;
	}
	const handleCanPlay = () => {
		videoRef.current.play();
		setParameter('remoteVideoStatus', SET_REMOTE_VIDEO_STATUS, true);
	};

	return (
		<div>
			<video
				ref={videoRef}
				onCanPlay={handleCanPlay}
				autoPlay
				playsInline
				loop
				className={remoteVideoStatus ? 'remoteVideo' : 'displayNone'}
			/>
			<div className={remoteVideoStatus ? 'displayNone' : 'sessionStartContainer'}>
				<div className="sessionStartLoading" />
				<div className="sessionStartText">Connecting to GoBe Robot…</div>
			</div>
		</div>
	);
};

export default reduxConnector(RemoteVideo);
