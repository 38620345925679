import React, { useCallback, useEffect, useState } from 'react';
import Battery from '../../../components/battery';
import './index.scss';
import { PropsFromParent } from './model';
import BatteryDetails from '../batteryDetails';
import batteryLevelConverter from '../../../utils/batteryLevelConverter';
import { ConnectedProps, useDispatch } from 'react-redux';
import { setParameter } from '../../../actions/setParam';
import { connect } from 'react-redux';
import { AppRootState, useTypedSelector } from '../../../../../../reducers';
import { useHistory } from 'react-router-dom';
import { SET_ROBOT_ID, SET_ROBOT_NAME } from '../../../actions/types';
import { equalityFnc, itemConverter } from '../../../utils/deviceStateConverter';
import { getStateEntityByOrgId } from '../../../../../../utils/conformState';

import {
	statusIconConverter,
	statusTextConverter,
	usedStatus,
} from '../../../utils/statusConverter';
import updatedRobotsSelector from '../../../utils/updatedRobotsSelector';

const GoBeCardIcon: React.FC<PropsFromParent> = ({
	deviceId,
	orgId,
	changeAgreementStatus,
	agreementsStatus,
	acceptedAgreementsStatus,
	updatedRobots,
}) => {
	const item: any = useTypedSelector(
		state =>
			getStateEntityByOrgId(state, 'accountState', {
				entityId: deviceId,
				orgId: orgId,
				propertyOptions: [
					'online',
					'status',
					'battery',
					'currentState',
					'name',
					'groupName',
					'battery',
					'location',
				],
			}),
		(left, right) => equalityFnc(left, right)
	);

	const dispatch = useDispatch();
	const history = useHistory();

	const onStartSession = useCallback(async () => {
		if (item.online) {
			if (usedStatus(item) === 'available') {
				await dispatch(setParameter('robotId', SET_ROBOT_ID, deviceId));
				await dispatch(setParameter('robotName', SET_ROBOT_NAME, item.name));
				if (acceptedAgreementsStatus === false) {
					await changeAgreementStatus(true);
				} else {
					history.push('/gobe/session');
					// if (updatedRobotsSelector(updatedRobots, deviceId)) {
					// 	history.push('/gobe/session');
					// } else {
					// 	history.push('/gobe/session-legacy');
					// }
				}
			}
		}
	}, [acceptedAgreementsStatus, changeAgreementStatus, deviceId, dispatch, history, item]);

	if (item) {
		return (
			<div className="goBeCardIconContainer">
				<div className="goBeCardSquaresContainer">
					<div
						className={
							item.online
								? 'verticalCardSquare'
								: 'verticalCardSquare verticalCardSquareOffline'
						}
					>
						<div className="battFavoriteContainer">
							<div className="battTextContainer">
								<div
									className={
										item.online
											? 'batteryRoaster'
											: 'batteryRoaster batteryRoasterOff'
									}
								>
									<Battery
										batteryPercent={item.battery ? item.battery.level : 40}
										online={item.online}
										charging={item.battery ? item.battery.charging : false}
									/>
									{/* <div className="batteryDetailsWrapper">
										<BatteryDetails
											batteryPercent={item.battery ? item.battery.level : 40}
											charging={item.battery ? item.battery.charging : false}
										/>
									</div> */}
								</div>
								{/* <div
									className={
										item.online ? 'batteryText' : 'batteryText batteryTextGrey'
									}
								>
									{
										batteryLevelConverter(item.battery ? item.battery.level : 40, 8)
											.hour
									}
									h:
									{
										batteryLevelConverter(item.battery ? item.battery.level : 40, 8)
											.min
									}
									m
								</div> */}
							</div>
							<div className="favoriteWrapper">
								<img
									alt=""
									src={`../../assets/images/${
										item.online
											? 'grey-star-hallow.svg'
											: 'grey-star-filled.svg'
									}`}
								/>
							</div>
						</div>
						<div
							className={
								item.online
									? usedStatus(item) === 'available'
										? 'blackCardSquare'
										: 'blackCardSquare statusSquare'
									: 'blackCardSquare greyCardSquare'
							}
							onClick={() => onStartSession()}
						>
							<div className="goBeLastSeen">
								<div className="lastSeenLogoWrapper">
									<img
										alt=""
										src={`../../assets/images/${
											item.online
												? 'time-outline.svg'
												: 'time-outline-offline.svg'
										}`}
									/>
								</div>
								{item.lastUsage}
							</div>
							<div className="goBePlayContainer">
								<div className="goBePlayWrapper">
									<img alt="" src="../../assets/images/goBe-play.svg" />
									<div className="greenPlayWrapper">
										<img alt="" src="../../assets/images/green-play.svg" />
									</div>
								</div>
								<div className="greenPlayTriangle" />
							</div>
							<div className="goBeOfflineContainer">
								<div className="offlineWrapper">
									<img alt="" src="../../assets/images/grey-offline.svg" />
								</div>
								OFFLINE
							</div>
							<div className="goBeStatusContainer">
								<div className="goBeStatusIconWrapper">
									<img
										alt=""
										src={`../../assets/images/${statusIconConverter(
											usedStatus(item)
										)}`}
									/>
								</div>
								<div className="goBeStatusWrapper">
									<img alt="" src="../../assets/images/status.svg" />
								</div>
								<div className="goBeStatusText">
									{statusTextConverter(usedStatus(item))}
								</div>
							</div>
							<div className={item.location ? 'goBeLocation' : 'goBeLocationNull'}>
								{item.location}
							</div>
						</div>
					</div>
					<div
						className={
							item.online
								? 'horizontalCardSquare'
								: 'horizontalCardSquare horizontalCardSquareOffline'
						}
					>
						<div className="goBeNameContainer">{item.name}</div>
						<div className="goBeGroupNameContainer">In: {item.groupName}</div>
						{/* <div className={item.onCall?'goBeAvailable':'displayNone'}>On another call</div> */}
					</div>
				</div>
			</div>
		);
	} else {
		return <div />;
	}
};

export default GoBeCardIcon;
