import React, { FC, useEffect, useState } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { Link, useParams } from 'react-router-dom';

import { anonymous, subscribeToAccountCreate } from '../../providers/mqtt';

import { setParameter } from '../../actions/setParam';
import {
	ACCOUNT_CREATE_DETAILS_UPDATED,
	ACCOUNT_CREATE_REQUEST_ID_CREATED,
	ACCOUNT_CREATE_STATUS_UPDATED,
	BEAM_TOKEN_STATUS_UPDATED,
	SET_MQTTCLIENT,
} from '../../actions/types';
import { publish } from '../../actions/publish';
import isAuthenticated from '../../components/Authentication/Authenticated';
import { AccountCreatedStatus } from '../../reducers/accountCreateReducer';

import { IonCol, IonContent, IonGrid, IonRow } from '@ionic/react';

import gobeBrandingImage from '../../assets/images/gobe-cover-01.png';
import beamBrandingImage from '../../assets/images/beam-cover.png';
import { FMBrandedSplitPane, FMBrandedSplitPaneImage } from '../../ui-elements/FMBrandedSplitPane';
import { FMFooterLogo } from '../../ui-elements/FMFooterLogo';
import { FMHeaderGoBe } from '../../ui-elements/FMHeaderGoBe';
import { FMHeaderBeam } from '../../ui-elements/FMHeaderBeam';
import { FMButtonPrimary } from '../../ui-elements/FMButtons';

import classNames from 'classnames';
import classes from './AccountCreate.module.css';
import FormInputListItem from '../../components/FormInputListItem/FormInputListItem';
import PrivacyPolicyModal from '../../components/PrivacyPolicyModal/PrivacyPolicyModal';
import { CountrySelect } from '../../components/CustomFormComponents';
import ErrorModal from '../../components/ErrorModal/ErrorModal';
import { useTypedSelector } from '../../reducers';
import JwtDecode from 'jwt-decode';
import { BeamAccountCreateTokenData } from '../../types/types';
import { b64EncodeUnicode } from '../../utils/encoding';
import { timeUuid } from '../../actions/timeUuid';

interface AccountCreatePageProps {
	accountCreatedStatus: AccountCreatedStatus;
	client: any;
	intl: any;
	setParameter: any;
}

enum PageStatus {
	INITIALIZING,
	AWAITING_RESPONSE,
	DONE,
	ERROR,
}

const AccountCreatePage: FC<AccountCreatePageProps> = ({
	accountCreatedStatus,
	client,
	intl,
	setParameter,
}) => {
	const [publishTopic, setPublishTopic] = useState<string>('');
	const [beamTokenPublishTopic, setBeamTokenPublishTopic] = useState<string>('');
	const [privacyPolicyModalIsOpen, setPrivacyPolicyModalIsOpen] = useState<boolean>(false);

	const { control, handleSubmit, errors } = useForm({});
	const [errorModalAlreadyConfirmedIsOpen, setErrorModalAlreadyConfirmedIsOpen] = useState<boolean>(false);
	const [errorModalAccountCreationIsOpen,setErrorModalAccountCreationIsOpen] = useState<boolean>(false);
	const [successModalIsOpen, setSuccessModalIsOpen] = useState<boolean>(false);

	const spinoutType = useTypedSelector(state => state.versionState.spinoutType);
	const beamTokenFromStore = useTypedSelector(state => state.accountCreateState.tokenData);
	const [emailFieldDefaultValue, setEmailFieldDefaultValue] = useState<string>("");
	const [organizationNameDefaultValue, setOrganizationNameDefaultValue] = useState<string>("");
	const { beamToken } = useParams<Record<string, any>>();
	const [beamTokenData, setBeamTokenData] = useState<any>(null);
	const [beamTokenExpired, setBeamTokenExpired] = useState<boolean>(false);
	const [beamTokenError, setBeamTokenError] = useState<boolean>(false);
	const [pageStatus, setPageStatus] = useState<PageStatus>(spinoutType === 'beam' ? PageStatus.INITIALIZING : PageStatus.DONE);


	function onSubmit(values: any) {
		if (accountCreatedStatus !== AccountCreatedStatus.WAITING_FOR_USER_INPUT) {
			return;
		}

		values = { ...values, country: values.country.value, domainUrl: window.location.hostname };

		if(spinoutType === 'beam'){
			values = { ...values, spinoutType: 'beam', beamOrgId: beamTokenData.oid };
		}
		setParameter('details', ACCOUNT_CREATE_DETAILS_UPDATED, values);

		const requestId = window
			.btoa(unescape(encodeURIComponent(JSON.stringify(values))))
			.substring(0, 32);


		setParameter('requestId', ACCOUNT_CREATE_REQUEST_ID_CREATED, requestId);

		publish(publishTopic, {
			data: { ...values },
			requestId,
		});

		setParameter('status', ACCOUNT_CREATE_STATUS_UPDATED, AccountCreatedStatus.PENDING);
	}

	function hasErrors() {
		return Object.keys(errors).length > 0;
	}

	useEffect(() => {
		anonymous((client: any) => {
			setParameter('client', SET_MQTTCLIENT, client);
		});
	}, [setParameter]);

	useEffect(() => {
		if (client.connected === false || typeof client.listen !== 'function') {
			return;
		}
		if(spinoutType === 'gobe'){
			const { publishTopic, unsubscribeCallback } = subscribeToAccountCreate(client);
			setPublishTopic(publishTopic);
			return unsubscribeCallback;
		}
		else{
			const requestId = window
				.btoa(unescape(encodeURIComponent(JSON.stringify({ token: beamToken }))))
				.substring(0, 32);
			setParameter('requestId', ACCOUNT_CREATE_REQUEST_ID_CREATED, requestId);
			const { publishTopic, unsubscribeCallback } = subscribeToAccountCreate(client);
			setPublishTopic(publishTopic);
			return unsubscribeCallback;
		}
	}, [client]);

	/*useEffect(() => {
		if (client.connected === false || typeof client.listen !== 'function') {
			return;
		}
		if (spinoutType === 'gobe'){
			return;
		}
		if (beamTokenData != null){
			return;
		}
		console.log("setting up backend api");

		const { publishTopic, unsubscribeCallback } = subscribeToBeamTokenValidation(client);
		setBeamTokenPublishTopic(publishTopic);

		return unsubscribeCallback;
	}, [client]);*/

	useEffect(() => {
		if (client.connected === false || typeof client.listen !== 'function') {
			return;
		}
		if(beamToken == null) return;
		if(publishTopic){
			const requestId = window
				.btoa(unescape(encodeURIComponent(JSON.stringify({ token: beamToken }))))
				.substring(0, 32);
			publish(publishTopic, {
				data: { token: beamToken },
				requestId,
			});
			setParameter('status', ACCOUNT_CREATE_STATUS_UPDATED, AccountCreatedStatus.PENDING);
		}
	}, [publishTopic]);

	useEffect(() => {
		// initial Beam Token responses (not used in GoBe)
		if(accountCreatedStatus === AccountCreatedStatus.ERRORTOKEN){
			setBeamTokenError(true);
			return;
		}
		if(accountCreatedStatus === AccountCreatedStatus.SUCCESSTOKEN){
			setBeamTokenData(beamTokenFromStore);
			setEmailFieldDefaultValue(beamTokenFromStore.email);
			setOrganizationNameDefaultValue(beamTokenFromStore.ou);
			setParameter(
				'status',
				ACCOUNT_CREATE_STATUS_UPDATED,
				AccountCreatedStatus.WAITING_FOR_USER_INPUT
			);
			return;
		}

	}, [accountCreatedStatus]);

	useEffect(() => {
		if(accountCreatedStatus === AccountCreatedStatus.SUCCESS){
			setParameter(
				'status',
				ACCOUNT_CREATE_STATUS_UPDATED,
				AccountCreatedStatus.WAITING_FOR_USER_INPUT
			);
			setSuccessModalIsOpen(true);
			return;
		}
		if(accountCreatedStatus === AccountCreatedStatus.ERROR){
			setParameter(
				'status',
				ACCOUNT_CREATE_STATUS_UPDATED,
				AccountCreatedStatus.WAITING_FOR_USER_INPUT
			);
	
			setErrorModalAccountCreationIsOpen(true);
			window.location.reload();
			return;
		}
		if(accountCreatedStatus === AccountCreatedStatus.FAIL){
			setParameter(
				'status',
				ACCOUNT_CREATE_STATUS_UPDATED,
				AccountCreatedStatus.WAITING_FOR_USER_INPUT
			);
			setErrorModalAlreadyConfirmedIsOpen(true);
			return;
		}

	}, [accountCreatedStatus]);


	const pending = accountCreatedStatus === AccountCreatedStatus.PENDING;


	return (
		<>
		{ (spinoutType === 'beam' &&  beamTokenData === null) ? 
		<>
			{(beamTokenError) ?
				<ErrorModal
					isOpen={true}
					onConfirm={() => window.location.replace('https://www.gobe-robots.com/') }
					onDismiss={() => window.location.replace('https://www.gobe-robots.com/') }
					type={'token'}
				/>
			: ''}
		</> 
		:
		<>
			{(errorModalAlreadyConfirmedIsOpen) ?
				<ErrorModal
					isOpen={true}
					onConfirm={() => setErrorModalAlreadyConfirmedIsOpen(false) }
					onDismiss={() => setErrorModalAlreadyConfirmedIsOpen(false) }
					type={'accountAlreadyConfirmed'}
				/>
			: ''}
			{(errorModalAccountCreationIsOpen) ?
				<ErrorModal
					isOpen={true}
					onConfirm={() => setErrorModalAccountCreationIsOpen(false) }
					onDismiss={() => setErrorModalAccountCreationIsOpen(false) }
					type={'accountCreationFailed'}
				/>
			: ''}
			{(successModalIsOpen) ?
				<ErrorModal
					isOpen={true}
					onConfirm={() => window.location.replace('https://www.gobe-robots.com/') }
					onDismiss={() => window.location.replace('https://www.gobe-robots.com/') }
					type={'success'}
				/>
			: ''}
			<PrivacyPolicyModal
				agreementId="privacy-and-cookie-policy_160374175000_en_gobe"
				isOpen={privacyPolicyModalIsOpen}
				onAccept={() => {
					setPrivacyPolicyModalIsOpen(false);
				}}
				onDismiss={() => {
					setPrivacyPolicyModalIsOpen(false);
				}}
			/>
			<FMBrandedSplitPane contentId="accountCreateForm">
				{ spinoutType === 'gobe' ? 
					<FMBrandedSplitPaneImage url={gobeBrandingImage} />
				:
					<FMBrandedSplitPaneImage url={beamBrandingImage} />
				}
				<IonContent id="accountCreateForm">
					<div className={classes.contentContainer}>
						<div className={classes.headerContainer}>
							{spinoutType === 'gobe' ?
							<FMHeaderGoBe>
								<FormattedMessage id="AccountCreatePage.welcome" />
							</FMHeaderGoBe>
							:
							<FMHeaderBeam>
								<FormattedMessage id="AccountCreatePage.welcome" />
							</FMHeaderBeam>
							}
						</div>
						<div
							className={
								hasErrors()
									? classNames(classes.formContainer, classes.formContainerError)
									: classes.formContainer
							}
						>
							<IonGrid>
								<IonRow>
									<IonCol>
										<FormInputListItem
											onChange={() => {}}
											type="text"
											name="companyName"
											defaultValue={ organizationNameDefaultValue }
											disabled={spinoutType === 'beam' || pending}
											label="Organization name"
											placeholderText="Organization name"
											control={control}
											rules={{ required: true }}
											required
											errorMsg={
												errors.companyName &&
												intl.formatMessage({
													id: 'AccountCreateForm.companyNameError',
												})
											}
										/>
									</IonCol>
								</IonRow>
								<IonRow>
									<IonCol>
										<FormInputListItem
											onChange={() => {}}
											type="text"
											name="firstName"
											defaultValue=""
											label={intl.formatMessage({
												id: 'ContactPerson.firstName',
											})}
											placeholderText={intl.formatMessage({
												id: 'ContactPerson.firstNameHint',
											})}
											control={control}
											rules={{ required: true }}
											required
											errorMsg={
												errors.firstName &&
												intl.formatMessage({
													id: 'AccountCreateForm.firstNameError',
												})
											}
											disabled={pending}
										/>
									</IonCol>
									<IonCol>
										<FormInputListItem
											onChange={() => {}}
											type="text"
											name="lastName"
											defaultValue=""
											label={intl.formatMessage({
												id: 'ContactPerson.lastName',
											})}
											placeholderText={intl.formatMessage({
												id: 'ContactPerson.lastNameHint',
											})}
											control={control}
											rules={{ required: true }}
											required
											errorMsg={
												errors.lastName &&
												intl.formatMessage({
													id: 'AccountCreateForm.lastNameError',
												})
											}
											disabled={pending}
										/>
									</IonCol>
								</IonRow>
								<IonRow>
									<IonCol>
										<FormInputListItem
											onChange={() => {}}
											type="email"
											name="email"
											defaultValue={ emailFieldDefaultValue }
											disabled={spinoutType === 'beam' || pending}
											label={intl.formatMessage({
												id: 'ContactPerson.email',
											})}
											placeholderText={intl.formatMessage({
												id: 'ContactPerson.emailHint',
											})}
											control={control}
											rules={{
												required: true,
												pattern: {
													value: /^[A-Z-a-z0-9+_-]+(\.[A-Z-a-z0-9+_-]+)*@.*$/,
												},
											}}
											required
											errorMsg={
												errors.email &&
												intl.formatMessage({
													id: 'AccountCreateForm.emailError',
												})
											}
										/>
									</IonCol>
								</IonRow>
								<IonRow>
									<IonCol>
										<FormInputListItem
											onChange={() => {}}
											type="text"
											name="address"
											defaultValue=""
											label={intl.formatMessage({
												id: 'Address.street',
											})}
											placeholderText={intl.formatMessage({
												id: 'Address.streetHint',
											})}
											control={control}
											rules={{ required: true }}
											required
											errorMsg={
												errors.address &&
												intl.formatMessage({
													id: 'AccountCreateForm.addressError',
												})
											}
											disabled={pending}
										/>
									</IonCol>
								</IonRow>
								<IonRow>
									<IonCol>
										<FormInputListItem
											onChange={() => {}}
											type="text"
											name="address2"
											defaultValue=""
											label={intl.formatMessage({
												id: 'Address.street2',
											})}
											placeholderText={intl.formatMessage({
												id: 'Address.street2Hint',
											})}
											control={control}
											disabled={pending}
										/>
									</IonCol>
								</IonRow>
								<IonRow>
									<IonCol>
										<FormInputListItem
											onChange={() => {}}
											type="text"
											name="zip"
											defaultValue=""
											label={intl.formatMessage({
												id: 'Address.zip',
											})}
											placeholderText={intl.formatMessage({
												id: 'Address.zipHint',
											})}
											control={control}
											rules={{ required: true }}
											required
											errorMsg={
												errors.zip &&
												intl.formatMessage({
													id: 'AccountCreateForm.zipError',
												})
											}
											disabled={pending}
										/>
									</IonCol>
									<IonCol>
										<FormInputListItem
											onChange={() => {}}
											type="text"
											name="city"
											defaultValue=""
											label={intl.formatMessage({
												id: 'Address.city',
											})}
											placeholderText={intl.formatMessage({
												id: 'Address.cityHint',
											})}
											control={control}
											rules={{ required: true }}
											required
											errorMsg={
												errors.city &&
												intl.formatMessage({
													id: 'AccountCreateForm.cityError',
												})
											}
											disabled={pending}
										/>
									</IonCol>
								</IonRow>
								<IonRow>
									<IonCol>
										<CountrySelect
											control={control}
											name="country"
											errors={errors}
											menuPlacement="top"
											required
											disabled={pending}
										/>
									</IonCol>
								</IonRow>
							</IonGrid>
							{pending || (
								<>
									<div>
										<div className={classes.disclaimer}>
											<FormattedMessage
												id="AccountCreatePage.disclaimer"
												values={{
													a: (msg: string) => (
														<div
															className={classes.privacyPolicyButton}
															onClick={() =>
																setPrivacyPolicyModalIsOpen(true)
															}
														>
															{msg}
														</div>
													),
												}}
											/>
										</div>
										{spinoutType === 'gobe' ?
											<div className={classes.buttonsContainer}>
												<div>
													<FormattedMessage
														id="AccountCreatePage.loginAction"
														values={{
															a: (msg: string) => (
																<Link to="/">{msg}</Link>
															),
														}}
													/>
												</div>
												<FMButtonPrimary
													onClick={() => handleSubmit(onSubmit)()}
												>
													<FormattedMessage id="AccountCreatePage.createAction" />
												</FMButtonPrimary>
											</div>
										: 
											<div className={classes.beamButtonsContainer}>
												<FMButtonPrimary
													onClick={() => handleSubmit(onSubmit)()}
												>
													<FormattedMessage id="AccountCreatePage.continue" />
												</FMButtonPrimary>
											</div>
										}
									</div>
								</>
							)}
						</div>
						<footer className={classes.footerContainer}>
							<FMFooterLogo />
						</footer>
					</div>
				</IonContent>
			</FMBrandedSplitPane>
		</>
		}
	</>
	);
};

const mapStateToProps = (state: any) => ({
	accountCreatedStatus: state.accountCreateState.status,
	client: state.mqttState.client,
});

export default injectIntl(
	isAuthenticated(
		connect(mapStateToProps, { setParameter })(AccountCreatePage),
		'AccountCreatePage'
	)
);
