import { MqttRouter } from 'mqtt-sub-router';
import { store } from '../store/store';
import { ACCOUNT_CREATE_STATUS_UPDATED, BEAM_TOKEN_STATUS_UPDATED } from '../actions/types';
import { AccountCreatedStatus } from '../reducers/accountCreateReducer';

const AccountCreateRouter = new MqttRouter().listen(
	'accountCreate/response',
	({ data, requestId, status, params, topic, originalTopic, mqttClient }) => {
		if (requestId !== store.getState().accountCreateState.requestId) {
			return;
		}
		
		if (status === AccountCreatedStatus.SUCCESS) {
			store.dispatch({
				type: ACCOUNT_CREATE_STATUS_UPDATED,
				payload: { status: AccountCreatedStatus.SUCCESS },
			});
			return;
		}
		if (data && status === AccountCreatedStatus.SUCCESSTOKEN) {
			store.dispatch({
				type: BEAM_TOKEN_STATUS_UPDATED,
				payload: { 
					status: AccountCreatedStatus.SUCCESSTOKEN,
					tokenData: data
				},
			});
			return;
		}

		if (status === AccountCreatedStatus.ERROR) {
			store.dispatch({
				type: ACCOUNT_CREATE_STATUS_UPDATED,
				payload: { status: AccountCreatedStatus.ERROR },
			});
			return;
		}
		if (status === AccountCreatedStatus.ERRORTOKEN) {
			store.dispatch({
				type: ACCOUNT_CREATE_STATUS_UPDATED,
				payload: { status: AccountCreatedStatus.ERRORTOKEN },
			});
			return;
		}

		if (status === AccountCreatedStatus.FAIL) {
			store.dispatch({
				type: ACCOUNT_CREATE_STATUS_UPDATED,
				payload: { status: AccountCreatedStatus.FAIL },
			});
			return;
		}
	}
);
export default AccountCreateRouter;
