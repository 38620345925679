import React from 'react';
import './index.scss';
import { PropsFromParent } from './model';

const SessionMoreOptions: React.FC<PropsFromParent> = ({
	onHideNavClick,
	onShareScreenClick,
	onFullScreenClick,
	navVideoStatus,
	shareScreenStatus,
	fullScreenStatus,
}) => {
	return (
		<div className="sessionMoreOptionsContainer">
			{/* <div className="sessionMoreOptionsTextIcon" onClick={() => onShareScreenClick()}>
				<div className="sessionMoreOptionsIconWrapper">
					<img src="../../assets/images/share.svg" alt="" />
				</div>
				<div>Share your screen</div>
			</div> */}
			<div
				className="sessionMoreOptionsTextIcon exitFullScreen"
				onClick={() => onFullScreenClick()}
			>
				<div className="sessionMoreOptionsIconWrapper">
					<img
						src={`../../assets/images/${
							fullScreenStatus ? 'end-full-screen.svg' : 'full-screen.svg'
						}`}
						alt=""
					/>
				</div>
				<div>{fullScreenStatus ? 'Exit full screen' : 'Full screen'}</div>
			</div>
			<div className="sessionMoreOptionsTextIcon" onClick={() => onHideNavClick()}>
				<div className="sessionMoreOptionsIconWrapper">
					<img src="../../assets/images/compass.svg" alt="" />
				</div>
				<div>{navVideoStatus ? 'Hide Floor View' : 'Show Floor View'}</div>
			</div>
		</div>
	);
};

export default SessionMoreOptions;
