class EventEmitter<T extends string> {
	private callbacks: Record<T, Record<string, (...args: any[]) => void>>;
	constructor() {
		this.callbacks = {} as Record<T, Record<string, (...args: any[]) => void>>;
	}

	protected emit(event: T, ...data: any[]) {
		const eventCallbacks = this.callbacks[event] as
			| Record<string, (...args: any[]) => void>
			| undefined;
		if (eventCallbacks) {
			Object.values<(...args: any) => void>(eventCallbacks).forEach(cb => {
				cb(...data);
			});
		}
	}

	public addEventListener(event: T, cb: (...args: any[]) => void): string {
		const listenerId = Math.random()
			.toString(16)
			.substring(1);
		if (!this.callbacks[event]) this.callbacks[event] = {};
		const eventCallbacks = this.callbacks[event] as Record<string, (...args: any[]) => void>;
		eventCallbacks[listenerId] = cb;
		return listenerId;
	}

	public removeListener(event: T, listenerId: string) {
		const eventCallbacks = this.callbacks[event] as Record<string, (...args: any[]) => void>;
		delete eventCallbacks[listenerId];
	}
}

type RTCStatsSource = RTCPeerConnection | RTCRtpReceiver;

type ListenableStats =
	| 'averageRtcpInterval'
	| 'bytes'
	| 'totalBytes'
	| 'framesDecoded'
	| 'totalFramesDecoded';
export class RTCInboundRtpStreamStatsListener extends EventEmitter<ListenableStats> {
	private source: RTCStatsSource;
	private selector: MediaStreamTrack | null | undefined;
	private sampleRate: number;
	private intervalId: any = null;

	private prevStats: RTCInboundRtpStreamStats | null = null;

	constructor(
		source: RTCStatsSource,
		selector?: MediaStreamTrack | null,
		sampleRate: number = 500
	) {
		super();
		this.source = source;
		this.selector = selector;
		this.sampleRate = sampleRate;
	}

	private notifyListeners(stats: RTCStatsReport) {
		return new Promise((resolve, reject) => {
			stats.forEach((report: RTCInboundRtpStreamStats) => {
				if ((report.type as any) === 'inbound-rtp') {
					this.emit('averageRtcpInterval', report.averageRtcpInterval);
					this.emit('totalBytes', report.bytesReceived);
					if (this.prevStats) {
						this.emit('bytes', report.bytesReceived - this.prevStats.bytesReceived);
						this.emit(
							'framesDecoded',
							report.framesDecoded - this.prevStats.framesDecoded
						);
					} else {
						this.emit('bytes', report.bytesReceived);
						this.emit('framesDecoded', report.framesDecoded);
					}
					this.prevStats = report;
				}
			});
			resolve();
		});
	}

	public start() {
		this.source.getStats(this.selector).then(stats => this.notifyListeners(stats));
		this.intervalId = setInterval(async () => {
			this.source.getStats(this.selector).then(stats => {
				this.notifyListeners(stats);
			});
		}, this.sampleRate);
	}

	public stop() {
		if (this.intervalId) clearInterval(this.intervalId);
	}
}

interface RTCInboundRtpStreamStats extends RTCStats {
	averageRtcpInterval: number;

	/** A 64-bit integer which indicates the total number of bytes
	 * that have been received so far for this media source. */
	bytesReceived: number;

	/** A long integer value indicating the total number of frames of video
	 * which have been correctly decoded so far for this media source.
	 * This is the number of frames that would have been rendered if none were dropped.
	 * Only valid for video streams. */
	framesDecoded: number;

	/** A DOMHighResTimeStamp indicating the time at which the last packet was received for this source.
	 * The timestamp property, on the other hand,
	 * indicates the time at which the statistics object was generated. */
	lastPacketReceivedTimestamp: number;

	/** An integer specifying the number of times the receiver has notified the sender
	 * that some amount of encoded video data for one or more frames has been lost,
	 * using Picture Loss Indication (PLI) packets.
	 * This is only available for video streams. */
	pliCount: number;
}
