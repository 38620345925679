import React from 'react';
import classes from './FMHeaderBeam.module.css';

interface FMHeaderBeam {}

export const FMHeaderBeam: React.FC<FMHeaderBeam> = ({ children }) => (
	<div className={classes.outerContainer}>
		<div className={classes.innerImgContainer}>
			<img src="./assets/img/beam-logo.svg" alt="Beam" />
		</div>
		<div className={classes.innerMsgContainer}>{children}</div>
	</div>
);
