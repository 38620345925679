import React, { useRef, useEffect } from 'react';
import './index.scss';
import { connect } from 'react-redux';
import { setParameter } from '../../../actions/setParam';
import { SET_REMOTE_VIDEO_STATUS, SET_RELOAD_STATUS, SET_NAV_CAMERA_HEIGHT } from '../../../actions/types';
import { ConnectedProps } from 'react-redux';
import { AppRootState } from '../../../../../../reducers';
import { PropsFromParent } from './model';
import DynamicMedia, {
	UNCROPPED_VIDEO_FRAME_CONFIG,
} from '../../../components/dynamicMedia/DynamicMedia';

const reduxConnector = connect(
	(state: AppRootState) => ({
		localStream: state.goBeState.sessionState.localStream,
		drivingMode: state.goBeState.sessionState.drivingMode,
		remoteStream: state.goBeState.sessionState.remoteStream,
		remoteVoiceVolume: state.goBeState.sessionState.remoteVoiceVolume,
		remoteVideoStatus: state.goBeState.sessionState.remoteVideoStatus,
		host: state.mqttState.mqttConfig.host,
	}),
	{ setParameter }
);

type PropsFromRedux = ConnectedProps<typeof reduxConnector>;
type ComponentProps = PropsFromRedux & PropsFromParent;

const CAPTURE_OPTIONS = {
	audio: true,
	video: { facingMode: 'user' },
};
type CropType = {
	x1: number;
	x2: number;
};
const RemoteVideo: React.FC<ComponentProps> = ({
	localStream,
	remoteStream,
	setParameter,
	remoteVoiceVolume,
	remoteVideoStatus,
	greyVideo,
	drivingMode,
	pauseVideo,
	host,
}) => {
	const videoRef = useRef<any>(null);

	const cropConfig = null; // We should get this from the robot later
	useEffect(() => {
		if (remoteStream) {
			videoRef.current.volume = parseInt(remoteVoiceVolume) / 100;
		}
	}, [remoteVoiceVolume, remoteStream]);

	if (remoteStream && videoRef.current && !videoRef.current.srcObject) {
		videoRef.current.srcObject = remoteStream;
	}
	const handleCanPlay = () => {
		videoRef.current.play();
		setParameter('remoteVideoStatus', SET_REMOTE_VIDEO_STATUS, true);
		setParameter('reloadStatus', SET_RELOAD_STATUS, false);
	};

	const resizeNavCamera = (value: number) => {
		setParameter('navCameraHeight', SET_NAV_CAMERA_HEIGHT, value)
	};

	useEffect(() => {
		if (pauseVideo === true) {
			videoRef.current.pause();
		} else {
			videoRef.current.play();
		}
	}, [pauseVideo]);

	return (
		<div>
			<DynamicMedia
				mediaComponentProps={{
					onCanPlay: handleCanPlay,
					autoPlay: true,
					playsInline: true,
					loop: true,
				}}
				resizeNavCamera={resizeNavCamera}
				devEnvironment={host.includes("development")}
				videoRef={videoRef}
				className="dynamic-media"
				drivingMode={drivingMode}
				// devEnvironment={host.includes("development")}
				cropOptions={cropConfig || UNCROPPED_VIDEO_FRAME_CONFIG}
			/>

			{/* the default video component */}
			{/*<video
					ref={videoRef}
					onCanPlay={handleCanPlay}
					autoPlay
					playsInline
					loop
					className={
						remoteVideoStatus
							? greyVideo
								? drivingMode
									? 'remoteVideo drivingModeContainer'
									: 'remoteVideo greyVideo'
								: drivingMode
								? 'remoteVideo drivingModeContainer'
								: 'remoteVideo'
							: 'displayNone'
					}
				/>
			*/}
			<div
				className={
					remoteVideoStatus
						? 'displayNone'
						: 'sessionStartContainer'
				}
			>
				<div className="sessionStartLoading" />
				<div className="sessionStartText">Connecting to GoBe Robot…</div>
			</div>
		</div>
	);
};

export default reduxConnector(RemoteVideo);
