import { wrap } from 'mqtt-sub-router';
import mqtt from 'mqtt';
import AccountRouter from '../routers/AccountRouter';
import AccountCreateRouter from '../routers/AccountCreateRouter';
import AccountCreateConfirmationRouter from '../routers/AccountCreateConfirmationRouter';
import OrganizationRouter from '../routers/OrganizationRouter';
import DevicesRouter from '../routers/DevicesRouter';
import setPasswordRouter from '../routers/setPasswordRouter';
import { store } from '../store/store';
import { defaultMiddlewares } from './middleware';
import { b64DecodeUnicode, b64EncodeUnicode } from '../utils/encoding';

let mqttConfig = {};

const fetchConfig = callback => {
	fetch('/conf/mqttConfig.json').then(response => {
		response.json().then(data => {
			mqttConfig = data[`mqttConfig${process.env.REACT_APP_FM || ''}`];
			callback();
			store.dispatch({
				type: 'MQTT_CONFIG',
				payload: mqttConfig,
			});
		});
	});
};

export function RecreateMqttClient() {
	const paths = window.location.pathname.split('/');
	if (new Date().getTime() - localStorage.getItem('lastVisit') > 4000 && paths[1] !== 'gobe') {
		setTimeout(() => {
			store.dispatch({
				type: 'SIGN_OUT_USER',
				payload: {},
			});
		}, 200);
		return;
	}

	let user = sessionStorage.getItem('email');
	let psw = sessionStorage.getItem('psw');

	if (user !== null && psw !== null && window.location.pathname !== '/') {
		// Reauthenticate
		checkLogin(b64DecodeUnicode(user), b64DecodeUnicode(psw), response => {
			store.dispatch({
				type: 'SET_MQTTCLIENT',
				payload: {
					client: response.client,
				},
			});
		});
	}
}

export function subscribe(client, userName) {
	client.listen(`${b64EncodeUnicode(userName)}/#`, AccountRouter);
}

export function subscribeToAccountCreate(client) {
	const publishTopic = 'microservice/public/accountCreate';
	const subscribeTopic = `${b64EncodeUnicode('anonymous')}/#`;

	client.listen(subscribeTopic, AccountCreateRouter);

	const unsubscribeCallback = () => client.unlisten(subscribeTopic);

	return { publishTopic, subscribeTopic, unsubscribeCallback };
}

export function subscribeToAccountCreateConfirmation(client, userName) {
	const publishTopic = 'microservice/public/accountCreateConfirmation';
	const subscribeTopic = `${b64EncodeUnicode(userName)}/#`;

	client.listen(subscribeTopic, AccountCreateConfirmationRouter);

	const unsubscribeCallback = () => client.unlisten(subscribeTopic);

	return { publishTopic, subscribeTopic, unsubscribeCallback };
}

export function subscribeToOrganization(client, organizationId) {
	client.listen(`${organizationId}/events/#`, OrganizationRouter);
}

export function subscribeToDevice(client, serialNumber) {
	client.listen(`${serialNumber}/status/#`, DevicesRouter, {
		rh: 1,
	});
}

export function unsubscribeToAll(client, userName) {
	client.unlisten(`${b64EncodeUnicode(userName)}/#`);
}

export function confirmInvite(client, token, password) {
	client.publish(`microservice/account_confirmation`, {
		requestId: 'deleteUserRequestId',
		data: {
			token: token,
			password: password,
		},
	});
}

function getAccountInfo(mqttClient, userName) {
	mqttClient.publish(
		`microservice/${b64EncodeUnicode(userName)}/getOwnAccountInfo`,
		JSON.stringify({ requestId: 'someId' })
	);
}

export async function checkLogin(userName, password, callback) {
	fetchConfig(() => {
		let client = mqtt.connect(
			`${mqttConfig.protocol}://${mqttConfig.host}:${mqttConfig.port}/${mqttConfig.path}`,
			{
				username: userName.toLowerCase(),
				password: password,
				protocolVersion: mqttConfig.protocolVersion,
				keepalive: mqttConfig.keepalive,
			}
		);

		// wrap client
		client = wrap(client, defaultMiddlewares);
		subscribe(client, userName.toLowerCase());

		client.mqttClient.on('connect', event => {
			console.log(Date(new Date().getTime()));
			console.debug(`Client ${mqttConfig.clientID} has been CONNECTED to broker`);
			window.client = client.mqttClient;
			window.onbeforeunload = function(event) {
				this.localStorage.setItem('lastVisit', new Date().getTime());
				return;
			};

			sessionStorage.setItem('email', b64EncodeUnicode(userName.toLowerCase()));
			sessionStorage.setItem('psw', b64EncodeUnicode(password));

			getAccountInfo(client.mqttClient, userName.toLowerCase());

			callback({
				client,
				connected: true,
			});
		});

		client.mqttClient.on('error', error => {
			console.error(`Client ${mqttConfig.clientID}: Error in connection to broker`);
			if (error && error.code === 134) {
				client.mqttClient.end(true, {});
			}
			store.dispatch({
				type: 'SET_MQTTCLIENT',
				payload: {
					client,
				},
			});
			callback({
				connected: false,
				error:
					error
						.toString()
						.split('Connection refused:')
						.pop() || 'Error',
			});
		});

		client.mqttClient.on('disconnect', event => {
			console.log(event);
			store.dispatch({
				type: 'SET_MQTTCLIENT',
				payload: {
					client,
				},
			});
			console.debug(`Client ${mqttConfig.clientID} has been DISCONNECTED from broker`);
			callback({
				client,
				connected: false,
			});
		});
	});
}

export async function automaticLogin(username, password, callback) {
	fetchConfig(() => {
		let client = mqtt.connect(
			`${mqttConfig.protocol}://${mqttConfig.host}:${mqttConfig.port}/${mqttConfig.path}`,
			{
				username: username,
				password: password,
				protocolVersion: mqttConfig.protocolVersion,
				keepalive: mqttConfig.keepalive,
			}
		);

		// wrap client
		client = wrap(client, defaultMiddlewares);
		subscribe(client, username);
		client.listen(`${b64EncodeUnicode(username)}/#`, setPasswordRouter);

		client.mqttClient.on('connect', event => {
			console.log(Date(new Date().getTime()));
			console.debug(`Client ${mqttConfig.clientID} has been CONNECTED to broker`);
			window.client = client.mqttClient;
			window.onbeforeunload = function(event) {
				this.localStorage.setItem('lastVisit', new Date().getTime());
				return;
			};

			callback({
				client,
				connected: true,
			});
		});

		client.mqttClient.on('error', error => {
			console.error(`Client ${mqttConfig.clientID}: Error in connection to broker`);
			if (error && error.code === 134) {
				client.mqttClient.end(true, {});
			}
			store.dispatch({
				type: 'SET_MQTTCLIENT',
				payload: {
					client,
				},
			});
			callback({
				connected: false,
				error:
					error
						.toString()
						.split('Connection refused:')
						.pop() || 'Error',
			});
		});

		client.mqttClient.on('disconnect', event => {
			console.log(event);
			store.dispatch({
				type: 'SET_MQTTCLIENT',
				payload: {
					client,
				},
			});
			console.debug(`Client ${mqttConfig.clientID} has been DISCONNECTED from broker`);
			callback({
				client,
				connected: false,
			});
		});
	});
}

export function anonymous(callback) {
	fetchConfig(() => {
		let client = mqtt.connect(
			`${mqttConfig.protocol}://${mqttConfig.host}:${mqttConfig.port}/${mqttConfig.path}`,
			{
				username: 'anonymous',
				password: 'password',
				protocolVersion: mqttConfig.protocolVersion,
				keepalive: mqttConfig.keepalive,
			}
		);

		client.on('connect', event => {
			client = wrap(client, defaultMiddlewares);
			client.listen(`${b64EncodeUnicode('anonymous')}/#`, setPasswordRouter);
			callback(client);
		});
	});
}
