import { useEffect, useCallback, useRef, useState } from 'react';
import { Machine, StateSchema, assign } from 'xstate';
import { useMachine } from '@xstate/react';
import { RTCInboundRtpStreamStatsListener } from '../rtcStats';

type Keys = {
	up: 0 | 1;
	down: 0 | 1;
	left: 0 | 1;
	right: 0 | 1;
};
type KEY_EVENT = { type: 'KEY_EVENT'; key: keyof Keys; value: 0 | 1 };
type SPEED_CHANGED_EVENT = { type: 'SPEED_CHANGED'; speed: number };
type DATACHANNEL_READY_EVENT = { type: 'DATACHANNEL_READY'; datachannel: RTCDataChannel };
type NavControlMachineEvent =
	| KEY_EVENT
	| SPEED_CHANGED_EVENT
	| DATACHANNEL_READY_EVENT
	| { type: 'PAUSE' | 'RESUME' | 'ABORT' | 'HAS_VIDEO' | 'NO_VIDEO' };
interface NavControlMachineContext {
	datachannel: RTCDataChannel | undefined;
	speed: number;
	keys: Keys;
}
interface NavControlMachineStateSchema extends StateSchema {
	states: {
		paused: {};
		stallingCommands: {};
		sendingCommands: {
			states: {
				sending: {};
				changingParams: {};
			};
		};
		aborted: {};
	};
}

/** The time in milliseconds, between two successive repetitions of key input  */
const COMMAND_REPETITION_INTERVAL_MS = 100;
const NavControlMachine = Machine<
	NavControlMachineContext,
	NavControlMachineStateSchema,
	NavControlMachineEvent
>(
	{
		id: 'NAV-PILOT',
		initial: 'stallingCommands',
		context: {
			datachannel: undefined,
			speed: 0,
			keys: { up: 0, down: 0, left: 0, right: 0 },
		},
		states: {
			paused: {
				entry: ['setZeroKeys', 'sendStopCommand'],
				on: {
					RESUME: {
						target: 'stallingCommands',
					},
				},
			},
			stallingCommands: {
				on: {
					KEY_EVENT: {
						target: undefined, // remain in this state
						cond: 'isDifferentKeyValue',
						actions: ['setKeys'], // update the keys being tracked in the context
					},
					HAS_VIDEO: {
						target: 'sendingCommands',
					},
				},
			},
			sendingCommands: {
				initial: 'sending',
				states: {
					sending: {
						activities: ['sendKeysRepeatedly'],
					},
					// transient state that allows us to exit 'sending' state and enter into it again,
					// 	when commands-related params are changed
					changingParams: {
						on: {
							'': {
								target: 'sending',
							},
						},
					},
				},
				on: {
					SPEED_CHANGED: {
						target: '.changingParams',
						cond: 'isDifferentSpeed',
						actions: 'setSpeed',
					},
					KEY_EVENT: {
						target: '.changingParams',
						cond: 'isDifferentKeyValue',
						actions: ['setKeys'],
					},
					DATACHANNEL_READY: {
						target: '.changingParams',
						actions: 'setDatachannel',
					},
					NO_VIDEO: {
						target: 'stallingCommands',
					},
				},
			},
			aborted: {
				type: 'final',
			},
		},
		on: {
			PAUSE: {
				target: 'paused',
			},
			DATACHANNEL_READY: {
				target: undefined, // no target, stay in whatever state we are in
				actions: 'setDatachannel',
			},
			SPEED_CHANGED: {
				target: undefined, // no target, stay in whatever state we are in
				actions: 'setSpeed',
			},
			ABORT: {
				target: 'aborted',
			},
		},
	},
	{
		actions: {
			setZeroKeys: assign({ keys: (ctx, event) => ({ up: 0, down: 0, left: 0, right: 0 }) }),
			sendStopCommand: (context, event) => {
				const { datachannel } = context;
				if (datachannel === undefined) return;

				try {
					const command = `NAV 0 0 ${performance.now()}`;
					datachannel.send(command);
				} catch (error) {
					console.error('datachannel.send.failed', error);
				}
			},
			setKeys: assign({
				keys: (ctx, event) => {
					const _event = event as KEY_EVENT;
					return { ...ctx.keys, [_event.key]: _event.value };
				},
			}),
			setSpeed: assign({
				speed: (context, event) => {
					const _event = event as SPEED_CHANGED_EVENT;
					return _event.speed / 100;
				},
			}),
			setDatachannel: assign({
				datachannel: (context, event) => {
					const _event = event as DATACHANNEL_READY_EVENT;
					return _event.datachannel;
				},
			}),
		},
		guards: {
			isDifferentKeyValue: (context, event) => {
				const _event = event as KEY_EVENT;
				return context.keys[_event.key] !== _event.value;
			},
			isDifferentSpeed: (context, event) => {
				const _event = event as SPEED_CHANGED_EVENT;
				return context.speed !== _event.speed;
			},
		},
		activities: {
			sendKeysRepeatedly: context => {
				const { keys, datachannel, speed } = context;
				if (datachannel === undefined) return;

				const command = `NAV ${speed * (keys.up - keys.down)} ${speed *
					(keys.left - keys.right)}`;

				// send the command immediately to the robot
				try {
					datachannel.send(command + ` ${performance.now()}`);
				} catch (error) {
					console.error('datachannel.send.leading.failed', error);
				}
				// and also repeatedly every X milliseconds
				const interval = setInterval(() => {
					try {
						datachannel.send(command + ` ${performance.now()}`);
					} catch (error) {
						console.error('datachannel.send.repeating.failed', error);
					}
				}, COMMAND_REPETITION_INTERVAL_MS);
				return () => {
					clearInterval(interval);
				};
			},
		},
	}
);

export type NavKey = 'ArrowUp' | 'w' | 'ArrowDown' | 's' | 'ArrowRight' | 'd' | 'ArrowLeft' | 'a';

const STATS_SAMPLING_PERIOD_MS = 100;

const NavKeyMapping: Record<NavKey, keyof Keys> = {
	ArrowUp: 'up',
	w: 'up',
	ArrowDown: 'down',
	s: 'down',
	ArrowRight: 'right',
	d: 'right',
	ArrowLeft: 'left',
	a: 'left',
};
const useNavController = () => {
	useEffect(() => {
		console.info('NavController Hook Re-rendered');
	}, []);

	const [, _sendEvent] = useMachine(NavControlMachine);
	const sendEvent = useRef(_sendEvent);
	useEffect(() => {
		sendEvent.current = _sendEvent;
	}, [_sendEvent]);

	const setDataChannel = useCallback((datachannel: RTCDataChannel) => {
		sendEvent.current({ type: 'DATACHANNEL_READY', datachannel });
	}, []);

	const setNavSpeed = useCallback((speed: number) => {
		sendEvent.current({ type: 'SPEED_CHANGED', speed });
		console.info('SPEED_CHANGED');
	}, []);

	const pause = useCallback(() => {
		sendEvent.current({ type: 'PAUSE' });
	}, []);

	const resume = useCallback(() => {
		sendEvent.current({ type: 'RESUME' });
	}, []);

	const onKeyEvent = useCallback((eventType: 'keydown' | 'keyup', key: NavKey) => {
		if (!Object.keys(NavKeyMapping).includes(key as string)) return;

		sendEvent.current({
			type: 'KEY_EVENT',
			key: NavKeyMapping[key],
			value: eventType === 'keydown' ? 1 : 0,
		});
	}, []);

	const [hasWideCamVideo, setHasWideCamVideo] = useState(false);
	const [hasNavCamVideo, setHasNavCamVideo] = useState(false);
	useEffect(() => {
		if (hasWideCamVideo && hasNavCamVideo) {
			sendEvent.current({ type: 'HAS_VIDEO' });
		} else {
			sendEvent.current({
				type: 'NO_VIDEO',
			});
		}
	}, [hasNavCamVideo, hasWideCamVideo]);

	const [navCamVideoRTCRtpReceiver, setNavCamVideoRTCRtpReceiver] = useState<RTCRtpReceiver>();
	const [wideVideoRTCRtpReceiver, setWideVideoRTCRtpReceiver] = useState<RTCRtpReceiver>();

	// listen for bytes received on the wide camera's rtp receiver
	useEffect(() => {
		if (!wideVideoRTCRtpReceiver) return;

		const statsListener = new RTCInboundRtpStreamStatsListener(
			wideVideoRTCRtpReceiver,
			null, // there is only one MediaStreamTrack associated with an RTCRtpReceiver
			STATS_SAMPLING_PERIOD_MS
		);
		statsListener.start();
		const bytesListenerId = statsListener.addEventListener('bytes', bytes => {
			setHasWideCamVideo(bytes !== 0);
		});

		return () => {
			statsListener.stop();
			statsListener.removeListener('bytes', bytesListenerId);
		};
	}, [wideVideoRTCRtpReceiver]);

	// listen for bytes received on the navigation camera's rtp receiver
	useEffect(() => {
		if (!navCamVideoRTCRtpReceiver) return;

		const statsListener = new RTCInboundRtpStreamStatsListener(
			navCamVideoRTCRtpReceiver,
			null, // there is only one MediaStreamTrack associated with an RTCRtpReceiver
			STATS_SAMPLING_PERIOD_MS
		);
		statsListener.start();
		const bytesListenerId = statsListener.addEventListener('bytes', bytes => {
			setHasNavCamVideo(bytes !== 0);
		});

		return () => {
			statsListener.stop();
			statsListener.removeListener('bytes', bytesListenerId);
		};
	}, [navCamVideoRTCRtpReceiver]);

	/** Set the receiver for a video track that needs to be tracked
	 * in order to allow/disallow navigation */
	const setTrackedRTCRtpReceiver = useCallback(
		(cam: 'wideCam' | 'navCam', rtcpReceiver: RTCRtpReceiver) => {
			if (cam === 'navCam') setNavCamVideoRTCRtpReceiver(rtcpReceiver);
			else setWideVideoRTCRtpReceiver(rtcpReceiver);
		},
		[]
	);

	const returnValue = useRef({
		onKeyEvent,
		setTrackedRTCRtpReceiver,
		setDataChannel,
		setNavSpeed,
		pause,
		resume,
	});
	return returnValue.current;
};

export default useNavController;
