import React, { useRef, useState, useEffect, forwardRef, useCallback } from 'react';
import { connect } from 'react-redux';
import { setParameter } from '../../../actions/setParam';
import {
	SET_NAV_OPTIONS_STATUS,
	SET_NAV_SPEED,
	SET_NAV_VIDEO_ZOOM,
	SET_HIDE_NAV_OPTIONS_STATUS,
} from '../../../actions/types';
import Slider from '../slider';
import Indicator from '../indicator';
import { ConnectedProps } from 'react-redux';
import { AppRootState } from '../../../../../../reducers';
import { mouseConverter } from '../../../utils/eventConverter';
import { PropsFromParent } from './model';

const reduxConnector = connect(
	(state: AppRootState) => ({
		localStream: state.goBeState.sessionState.localStream,
		remoteStream: state.goBeState.sessionState.remoteStream,
		navStream: state.goBeState.sessionState.navStream,
		dataChannel1: state.goBeState.sessionState.dataChannel1,
		navOptionsStatus: state.goBeState.sessionState.navOptionsStatus,
		navVideoZoom: state.goBeState.sessionState.navVideoZoom,
		navSpeed: state.goBeState.sessionState.navSpeed,
		navVideoStatus: state.goBeState.sessionState.navVideoStatus,
		hideNavOptionsStatus: state.goBeState.sessionState.hideNavOptionsStatus,
		showMenu: state.menuState.showMenu,
		showAbsoluteMenu: state.menuState.showAbsoluteMenu,
		isExpanded: state.menuState.isExpanded,
	}),
	{ setParameter }
);

type PropsFromRedux = ConnectedProps<typeof reduxConnector>;
type ComponentProps = PropsFromRedux & PropsFromParent;

const CAPTURE_OPTIONS = {
	audio: true,
	video: { facingMode: 'user' },
};

const NavigationVideo: React.FC<PropsFromRedux> = ({
	localStream,
	navStream,
	remoteStream,
	dataChannel1,
	setParameter,
	navOptionsStatus,
	navVideoStatus,
	navSpeed,
	navVideoZoom,
	hideNavOptionsStatus,
	showMenu,
	showAbsoluteMenu,
	isExpanded,
}) => {
	const videoRef = useRef<any>(null);
	const navVideoRef = useRef<any>(null);
	const navVideoZoomRef = useRef<any>(null);
	const navVideoSpeedRef = useRef<any>(null);
	const navHideRef = useRef<any>(true);
	const navHoverRef = useRef<any>(true);

	const [navHover, changeNavHover] = useState(false);
	const [navVideoShow, changeNavVideoShow] = useState(false);
	const [keyDown, changeKeyDown] = useState<Array<string | number>>([]);

	document.onmousemove = () => {
		if (keyDown.length === 0) {
			clearTimeout(navHideRef.current);
			clearTimeout(navHoverRef.current);
			setParameter('hideNavOptionsStatus', SET_HIDE_NAV_OPTIONS_STATUS, true);
			changeNavHover(true);
			navHoverRef.current = setTimeout(() => {
				changeNavHover(false);
			}, 1000);
			navHideRef.current = setTimeout(() => {
				setParameter('hideNavOptionsStatus', SET_HIDE_NAV_OPTIONS_STATUS, false);
			}, 3000);
		}
	};
	document.onkeyup = (event: KeyboardEvent) => {
		if (keyDown.includes(event.key)) {
			let arr = keyDown.filter((item: string | number) => {
				return item !== event.key;
			});
			changeKeyDown(arr);
		}
	};

	document.onkeydown = (event: KeyboardEvent) => {
		if (!event.repeat) {
			if (!keyDown.includes(event.key)) {
				changeKeyDown([...keyDown, event.key]);
			}
		}
	};

	useEffect(() => {
		if (keyDown.length > 0) {
			clearTimeout(navHideRef.current);
			clearTimeout(navHoverRef.current);
			setParameter('hideNavOptionsStatus', SET_HIDE_NAV_OPTIONS_STATUS, true);
			changeNavHover(true);
		} else {
			navHoverRef.current = setTimeout(() => {
				changeNavHover(false);
			}, 1000);
			navHideRef.current = setTimeout(() => {
				setParameter('hideNavOptionsStatus', SET_HIDE_NAV_OPTIONS_STATUS, false);
			}, 3000);
		}
	}, [keyDown, setParameter]);

	useEffect(() => {
		return () => {
			clearTimeout(navHideRef.current);
			clearTimeout(navHoverRef.current);
		};
	}, []);

	const navVideoClickOutside = useCallback(
		(event: MouseEvent) => {
			if (navVideoRef.current && !navVideoRef.current.contains(event.target)) {
				if (!navVideoSpeedRef.current.contains(event.target)) {
					setParameter('navOptionsStatus', SET_NAV_OPTIONS_STATUS, false);
				}
			}
		},
		[setParameter]
	);

	useEffect(() => {
		document.addEventListener('click', navVideoClickOutside, true);
		return () => {
			document.removeEventListener('click', navVideoClickOutside, true);
		};
	}, [navVideoClickOutside, navVideoRef]);


	if (navStream && videoRef.current && !videoRef.current.srcObject) {
		videoRef.current.srcObject = navStream;
	}
	const handleCanPlay = () => {
		videoRef.current.play();
		changeNavVideoShow(true);
	};

	const navOptionsClick = () => {
		setParameter('navOptionsStatus', SET_NAV_OPTIONS_STATUS, !navOptionsStatus);
	};
	const onNavVideoZoomChange = (value: number) => {
		setParameter('navVideoZoom', SET_NAV_VIDEO_ZOOM, value);
	};
	const onNavSpeedChange = (value: number) => {
		setParameter('navSpeed', SET_NAV_SPEED, value);
	};

	const navElement = document.getElementById('navigationVideoContainer');

	const renderVideoLoading = () => {
		return (
			<div className="navVideoWrapper">
				<div className={navVideoShow ? 'displayNone' : ' showNavLoading '}>
					<div className="navLoading" />
				</div>
				<video
					ref={videoRef}
					onCanPlay={handleCanPlay}
					autoPlay
					playsInline
					loop
					className={navVideoShow ? 'navigationVideo' : 'displayNone'}
					muted
				/>
			</div>
		);
	};

	return (
		<div
			id="navigationVideoContainer"
			className={
				navVideoStatus
					? hideNavOptionsStatus
						? navHover
							? 'navigationVideoContainer navigationVideoContainerHover'
							: 'navigationVideoContainer '
						: 'displayNone'
					: 'displayNone'
			}
		>
			{renderVideoLoading()}
			<div className={navOptionsStatus ? 'navControlOptions' : 'displayNone'}>
				<div
					className={
						remoteStream && dataChannel1 && dataChannel1.readyState === 'open'
							? 'circleRectangleNav speedCircleRectangle'
							: 'circleRectangleNavDisabled speedCircleRectangle'
					}
					ref={navVideoSpeedRef}
				>
					<div className="iconWrapper">
						<img src="../../assets/images/white-speed.svg" alt="" />
					</div>
					<div className="navSpeedText">
						{(0.6 + (2.6 * parseInt(navSpeed)) / 100).toString().slice(0, 3)}
						<span>km/h</span>
					</div>
					<div className="rectangleNav">
						<Slider
							onChange={onNavSpeedChange}
							value={navSpeed}
							icon="speed-green.svg"
							id="navVideoSpeed"
						/>
						<div className="indicatorWrapper">
							<Indicator value={navSpeed} />
						</div>
					</div>
				</div>
			</div>
			<div
				className={
					navOptionsStatus ? 'blackCircleVideo' : 'blackCircleVideo whiteCircleVideo'
				}
				onClick={navOptionsClick}
				ref={navVideoRef}
			>
				<div className="iconWrapper">
					<img
						src={`../../assets/images/${
							navOptionsStatus ? 'white-more-options.svg' : 'more-options.svg'
						}`}
						alt=""
					/>
				</div>
			</div>
		</div>
	);
};

export default reduxConnector(NavigationVideo);
